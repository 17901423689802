import React, { useState, useEffect, useRef, useContext } from "react";

//**Import components */
import {
	JobTable,
	LoadingModal,
	ConfirmationModal,
	FreeTextSearch,
	JobFilterStatusButton,
	JobFilterDateButton,
} from "../../components/portal";

//**Import helpers */
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";
import { filterJobs } from "../../helpers/filterJobHelper";

// import filterJob from "../../helpers/filterJobHelper";
import { AuthContext } from "./../../contexts/AuthContext";

//**Import Utils services */
import {
	allStatusesArray,
	allTimesArray,
} from "../../utils/service";

//**Style css components */
import "./../../../src/assets/stylesheets/portal.css";

const JobTableView = ({ mode }) => {
	
	const authValue = useContext(AuthContext);

	//** State define */
	const [search, setSearch] = useState("");
	const [userRolesArray, setUserRolesArray] = useState([]);

	const [jobData, setJobData] = useState([]);
	const [checkedState, setCheckedState] = useState([]);
	const [jobTableData, setJobTableData] = useState([]);

	const [selectAllStatus, setSelectAllStatus] = useState(false);

	const [selectJob, setSelectJob] = useState(0);

	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [loadingModalVisible, setLoadingModalVisible] = useState(true);

	const [jobFilterTimes, setJobFilterTimes] = useState(allTimesArray);

	const [jobFilterStatusArray, setJobFilterStatusArray] = useState([
		"Confirmed",
		"Uploaded",
		"Edited",
	]);

	//** UseRef */
	const selectRef = useRef(null);
	const deselectRef = useRef(null);

	// loading modal styling
	const loading = {
		position: "absolute",
		top: "60%",
		left: "50%",
		width: "5%",
		display: loadingModalVisible ? "block" : "none",
	};

	//** Job Status Filter */
	const toggleActionJobFilterStatus = (status) => {
		setLoadingModalVisible(true);

		if (status === "SelectAll" || status === "DeselectAll") {
			setJobFilterStatusArray(() =>
				status === "SelectAll" ? allStatusesArray : []
			);
		} else {
			setSelectAllStatus(false);

			if (jobFilterStatusArray.includes(status)) {
				const newArray = jobFilterStatusArray.filter((val) => {
					return val !== status;
				});
				setJobFilterStatusArray(newArray);
			} else {
				setJobFilterStatusArray([...jobFilterStatusArray, status]);
			}
		}
	};

	//** Job Filters time */

	const toggleActionJobFilterTimes = (status) => {

		setLoadingModalVisible(true);

		if (status === "SelectAll" || status === "DeselectAll") {
			if ((jobFilterTimes.length === allTimesArray.length) && status === "SelectAll") {
				setLoadingModalVisible(false)
			}
			else {
				setJobFilterTimes(() => (status === "SelectAll" ? allTimesArray : []));
			}
		} else {
			if (jobFilterTimes === status) {
				setJobFilterTimes("");
			} else {
				setJobFilterTimes(status);
			}
		}
	};

	const handleClear = () => {
		setSearch("");
	};

	// Searching jobTable
	const handleSearchClick = (input) => {
		if(search !== input){
			setSearch(input);
			return 
		}
		setLoadingModalVisible(false)
	};



	const getJobRecordList = async () => {
		//Ensure loading modal is displayed
		setLoadingModalVisible(true);

		let jobTableArray = await postRequest("/getJobRecordListKeyAttributesOnly", {roles: userRolesArray});

		if (jobTableArray) {
		
			if(Array.isArray(userRolesArray)) {
				if(!userRolesArray.includes("Office")){
		
					let userId = localStorage.getItem('uid');
					
					jobTableArray = jobTableArray.filter((job)=>{
						return userId === job.inspectorUserID;
					})
		
				}
				
				let filteredJobTableArray1 = jobTableArray.filter((job) => {
					
					if (job.jobID) {
						return true;
					}
					return false;
				});
	
				let filteredJobTableArray2 = jobTableArray.filter((job) => {
					if (!job.jobID) {
						return true;
					}
					return false;
				});
	
	
				if (filteredJobTableArray1) {

					if(userRolesArray.includes("Office")){
						//If the user has the office role Sort by jobID 
						function compare(a, b) {
							if (
								(a.jobID) < (b.jobID)
							) {
								return 1;
							}
							if (
								(a.jobID) > (b.jobID)
							) {
								return -1;
							}
							return 0;
						}
						
		
						let sorting = filteredJobTableArray1.sort(compare);
		
						setJobData(sorting.concat(filteredJobTableArray2));
						setJobTableData(() =>
							sorting.concat(filteredJobTableArray2).filter((job) => {
								return jobFilterStatusArray.includes(job.jobStatus);
							})
						);
						
						filterJobs(
							jobData,
							setJobData,
							jobTableData,
							setJobTableData,
							jobFilterStatusArray,
							jobFilterTimes,
							search,
							setSearch,
							setLoadingModalVisible
						);
					} else {
						//If the user has the inspector role only, sort by inspection time and date

						setJobData(filteredJobTableArray1.concat(filteredJobTableArray2));
						setJobTableData(() =>
							filteredJobTableArray1.concat(filteredJobTableArray2).filter((job) => {
								return jobFilterStatusArray.includes(job.jobStatus);
							})
						);

					}
				}
			}
				

		}
		//Table generation complete, hide the loading modal
		setLoadingModalVisible(false);
	};

	useEffect(() => {
		if(userRolesArray && userRolesArray.length !==0){
			
			getJobRecordList(userRolesArray);
			
			//Had approved to the view for office role users
			if(userRolesArray.includes("Office")) {
				setJobFilterStatusArray([
					"Confirmed",
					"Uploaded",
					"Edited",
					"Approved",
				])
				
				getJobRecordList(userRolesArray);
			}
		}

	}, [userRolesArray]);


	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		//THIS IS ONLY FOR START UP
		if (jobTableData.length === 0 && jobData.length === 0) {

			const getUserRoles = async () => {
				const userRolesArrays = await postRequest("/getUserRoles");
				setUserRolesArray(userRolesArrays);
			};

			getUserRoles();

			// getJobRecordList();

		} else {
			filterJobs(
				jobData,
				setJobData,
				jobTableData,
				setJobTableData,
				jobFilterStatusArray,
				jobFilterTimes,
				search,
				setSearch,
				setLoadingModalVisible
			);
		}
	}, []);

	
	useEffect(() => {
		if (jobTableData.length === 0 && jobData.length === 0) {
			//Will already have user roles
		} else {
			filterJobs(
				jobData,
				setJobData,
				jobTableData,
				setJobTableData,
				jobFilterStatusArray,
				jobFilterTimes,
				search,
				setSearch,
				setLoadingModalVisible
			);
		}
	}, [jobFilterStatusArray, jobFilterTimes, search, jobData]);

	const bulkArchiveJobs = async (jobIDArray) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);
		

		//Update via post Request
		let response = await postRequestReturnAll("/bulkArchiveJobs", {
			jobIDArray: jobIDArray,
			"newStatus": "Archived"
		});

		if (response.status === 200 || response.status === 201 || response.status === 202) {
			setLoadingModalVisible(false);
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setTimeout(function () { setConfirmationModalVisible(false) }, 2000);

			//Refresh the job table data (via parent component)
			getJobRecordList();
		} else {
			setLoadingModalVisible(false);
			//Show error
		}
	}

	return (
		<>
			<LoadingModal visible={loadingModalVisible} />
			<ConfirmationModal visible={confirmationModalVisible} />

			<div className="d-flex font">
				<div className="p-2" style={{ display: "flex", alignItems: "stretch" }}>
					<div className="card">
						<div className="card-header d-flex justify-content-between align-items-center p-1">
							<h6 className="m-0 font-weight-bold text-dark">
								Job status filter
							</h6>
							<div className="">
								<button
									type="button"
									disabled={selectAllStatus}
									className="btn btn-dark rounded ml-2"
									onClick={() => {
										setSelectAllStatus(true);
										toggleActionJobFilterStatus("SelectAll")}}
								>
									Select all
								</button>
								<button
									type="button"
									className="btn btn-dark rounded ml-2"
									disabled={jobFilterStatusArray.length===0}
									onClick={() => {
										setSelectAllStatus(false);
										toggleActionJobFilterStatus("DeselectAll")}}
								>
									Deselect all
								</button>
							</div>
						</div>

						<div className="card-body p-2">

							<div className="d-flex flex-column p-0">
								<div className="btn-group ">
									<JobFilterStatusButton
										status={"Draft"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
									<JobFilterStatusButton
										status={"Confirmed"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
									<JobFilterStatusButton
										status={"Uploaded"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
									<JobFilterStatusButton
										status={"Edited"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
									<JobFilterStatusButton
										status={"Approved"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
									<JobFilterStatusButton
										status={"Archived"}
										jobFilterStatusArray={jobFilterStatusArray}
										toggleAction={toggleActionJobFilterStatus}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="p-2" style={{ display: "flex", alignItems: "stretch" }}>
					{/* <div className="card">
						<div className="card-header d-flex justify-content-between align-items-center p-1">
							<h6 className="m-0 font-weight-bold text-dark">Text filter</h6>

							<div className=" h-auto text-center p-0">
								<button
									type="button"
									className="btn btn-dark"
									onClick={(e) => {
										handleSearchClick("");
										setSearch("");
									}}
								>
									Search
								</button>
							</div>
						</div>
						<div className="card-body p-2"> */}
							{/*<p className="card-text">Search the table by keyword</p>*/}
							<FreeTextSearch
								handleSearchClick={handleSearchClick}
								handleClear={handleClear}
								search={search}
								setSearch={setSearch}
								setLoadingModalVisible={setLoadingModalVisible}
							/>
						{/* </div> */}
					{/* </div> */}
				</div>

				<div className="p-2" style={{ display: "flex", alignItems: "stretch" }}>
					<div className="card">
						{/* <div className="card-header d-flex justify-content-between align-items-center p-1">
					<h6 className="m-0 font-weight-bold text-dark">
						Text search filter
					</h6>

					<div className=" h-auto text-center p-0">
						<button
						type="button"
						className=" btn btn-danger"
						onClick={(e) => {
							handleSearchClick("");
							setSearch("");
						}}
						>
						Clear
						</button>
					</div>
					</div> */}
						<div className="card-header d-flex justify-content-between align-items-center p-1">
							<h6 className="m-0 font-weight-bold text-dark">
								Job timing filter
							</h6>
							<div className="">
								<button
									type="button"
									className="btn btn-dark rounded ml-2"
									onClick={() => {
										
										toggleActionJobFilterTimes("SelectAll")
									}}
								>
									Select all
								</button>
								<button
									type="button"
									className="btn btn-dark rounded ml-2"
									onClick={() => {
										
										toggleActionJobFilterTimes("DeselectAll")}
									}
								>
									Deselect all
								</button>
							</div>
						</div>
						<div className="card-body p-2">
							<div className="d-flex flex-column">
								<div className="btn-group">

									<JobFilterDateButton
										status={"8+ days ago"}
										jobFilterTimes={jobFilterTimes}
										toggleAction={toggleActionJobFilterTimes}
									/>
									<JobFilterDateButton
										status={"Within last 7 days"}
										jobFilterTimes={jobFilterTimes}
										toggleAction={toggleActionJobFilterTimes}
									/>
									<JobFilterDateButton
										status={"Today"}
										jobFilterTimes={jobFilterTimes}
										toggleAction={toggleActionJobFilterTimes}
									/>
									<JobFilterDateButton
										status={"Next 7 days"}
										jobFilterTimes={jobFilterTimes}
										toggleAction={toggleActionJobFilterTimes}
									/>
									<JobFilterDateButton
										status={"8+ days away"}
										jobFilterTimes={jobFilterTimes}
										toggleAction={toggleActionJobFilterTimes}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ display: 'flex', flexDirection: "row" }}>
				<div>
					{jobTableData.length > 0 && (
						<p className="my-1">{jobTableData.length} jobs displayed</p>
					)}
					{mode === "archive" && (
						<p className="my-1">{selectJob} jobs selected</p>
					)}
				</div>
				<div className="col d-flex align-items-center justify-content-start">
					{mode === "archive" && (
						<div>
							<button className="btn btn-sm btn-dark rounded ml-2"
								onClick={() => selectRef.current.click()}>
								Select all
							</button>
							<button className="btn btn-sm btn-dark rounded ml-2"
								onClick={() => deselectRef.current.click()}>
								Deselect all
							</button>
							<button className="btn btn-sm btn-warning rounded ml-2"
								onClick={() => bulkArchiveJobs(checkedState)}>
								Bulk archive
							</button>
						</div>
					)}
				</div>
			</div>
			<JobTable
				userRolesArray={userRolesArray}
				tableData={jobTableData}
				checkedState={checkedState}
				setCheckedState={setCheckedState}
				mode={mode}
				setSelectJob={setSelectJob}
				selectRef={selectRef}
				deselectRef={deselectRef}
				getJobRecordList={getJobRecordList}
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>
		</>
	);
};

export default JobTableView;
