import React, { useState, useEffect } from "react";

import './../../../assets/stylesheets/portal.css'

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import JobTableView from "../../../views/jobTableView";

const ViewJobs = (props) => {
    return (
        <>
            <ViewHeaderTitle title={props.headerTitle} />
            <JobTableView mode={"view"} />
        </>
    );
};

export default ViewJobs;
