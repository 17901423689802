import React, { useState, useEffect } from "react";

export const scopePagesArray = [
	<> 
        <strong>1. Service</strong><br />
		As requested by the Client the inspection provided by the Building Inspector (“Inspector”) is Special Purpose Staged Construction Inspection which a specific purpose and scope was detailed in the engagement documentation between the Client
		and the Inspector. &nbsp;The specific purpose and scope is supported by the following general scope requirements. Where the specific purpose and scope and the following general scope are in conflict, the specific scope will take precedence.
		<br />
		<br />
		<strong>2. Purpose of Inspection&nbsp;</strong><br />
		This inspection is an inspection carried out pursuant to an Australian Standard. It is an inspection to advise upon the construction of the structures on the property and any major defects or significant items found in relation to the
		construction work. The Inspection is carried out in accordance with AS 4349.0&nbsp; Inspection is carried out for the sole benefit of the client who has requested the inspection.<br />
		<br />
		<strong>2.1 Scope Limitation&nbsp;</strong><br />
		The inspection and report should not be seen as an all-encompassing report dealing with a building from every aspect. Rather it should be seen as a reasonable attempt to identify any major defects or significant items in construction works
		visible at the time of the inspection. Whether or not a defect is a major defect or significant item depends on a number of factors.&nbsp;<br />
		<br />
		<strong>2.2 Visual Inspection&nbsp;</strong><br />
		All inspections are visual inspections limited to those areas and sections of the property fully accessible and visible to the inspector on the date of the inspection. The inspection does not include an invasive inspection or breaking
		apart, dismantling, removing or moving objects including but not limited to foliage, moulding, roof insulation / sisalation, floor or wall coverings, sidings, ceilings, floors, furnishings, appliances or personal possessions. The inspector
		cannot see inside walls, between floors, inside skillion roofing, behind assorted goods in cupboards, other areas that are concealed or obstructed. The inspector will not dig and cannot, gouge, force or perform any other invasive
		procedures. Visible timbers will not and cannot be destructively probed or hit without prior written permission of the property owner.&nbsp; The inspector will only report on readily observable state of the property at the time of
		inspection. The inspection therefore cannot deal with:
		<ul>
				<li>possible concealment of defects, including but not limited to, defects concealed by lack of accessibility, obstructions such as furniture, wall linings and floor coverings, or by applied finishes such as render and paint; and</li>
				<li>
						undetectable or latent defects, including but not limited to, defects that may not be apparent at the time of inspection due to seasonal changes, recent or prevailing weather conditions, and whether or not services have been used
						some time prior to the inspection being carried out.
				</li>
		</ul>
		<strong>2.3 Not a Certificate of compliance&nbsp;</strong><br />
		A report prepared in accordance with AS4349.1 is not a certificate of compliance of the property within the requirements of any Act, regulation, ordinance, local law or by-law, and is not a warranty against problems developing with the
		building in the future. &nbsp; The Australian Standards do not include the identification of unauthorized building work or of work not compliant with building regulations. This Standards assumes that the existing use of the building will
		continue.&nbsp;<br />
		<br />
		<strong>2.4 Comparative Position and Acceptance Criteria&nbsp;</strong><br />
		The building shall be compared with a building that was constructed in accordance with the generally accepted practice at the time of construction and which has been maintained &nbsp;such that there has been no significant loss of strength
		and serviceability.&nbsp;
		
	</>,
	<> 
        <strong>3. Areas to be inspected&nbsp;</strong><br />
		<strong>3.1 General&nbsp;</strong><br />
		The inspection shall cover all accessible areas defined by the construction permit. As per the Standards, the client shall arrange right of entry, facilitate physical entry to the property and supply necessary information to enable the
		inspector to undertake the inspection and prepare a report. The inspector is not responsible for arranging entry to property or parts of property.&nbsp; The inspector shall inspect accessible parts of the building and appurtenances,
		together with relevant feature of the property within 30m of the main building and within the boundaries of the site, or as otherwise agreed in the inspection agreement. In this context, relevant features include car accommodation, detached
		laundry, ablution facilities and garden sheds, retaining walls more than 700mm high, paths and driveways, steps, fencing.&nbsp; Inspection of Strata and Company Title residential property shall be limited to the nominated residence and does
		not include common property.&nbsp;<br />
		The following area shall be inspected where applicable or accessible:&nbsp;<br />
		<ul>
			<li>The interior of the building&nbsp;</li>
			<li>The roof space&nbsp;</li>
			<li>The exterior of the building&nbsp;</li>
			<li>The sub-floor space&nbsp;</li>
			<li>The roof exterior&nbsp;</li>
			<li>The property within 30m of the building subject to inspection&nbsp;</li>
		</ul>
		<strong>3.2 Safe and reasonable access&nbsp;</strong><br />
		The extent of accessible areas shall be determined by the inspector at the time of inspection, based on the conditions encountered at the time of inspection and WA Worksafe OH&amp;S Laws. The inspector shall also determine whether
		sufficient space is available to allow safe access. As a general rule minimum crawls spaces of 600mm x 600 mm are required.&nbsp; Pursuant to regulation, the inspector is not permitted to access the roof cover where power to the roof space
		cannot be isolated at the meter box or sub board and or solar inverter.&nbsp; The inspection shall include only accessible areas and areas that are within the inspector’s line of sight and close enough to enable reasonable appraisal.&nbsp;

		The inspector shall inspect an elevated area only where –&nbsp;
		<ul>
			<li>it is at a height at which safe reasonable access is available, or where safe and reasonable access is otherwise available; or</li>
			<li>an unobstructed line of sight is present from safe use of a 3.6m ladder and the building elements present are close enough to allow appraisal.</li>
		</ul>
		NOTE: “Elevated area” includes the roof, roof space, crawl space, landing feature, and the like, generally elevated above the ground and intended for normal use by occupants. A 3.6m ladder is considered generally reasonable for safe use by
		one operator during an inspection, subject to prevailing weather conditions. Regardless of the ladder length, weight and size, safe use of ladder or safe access may mean that inspection of a roof, elevated platform or roof space is not
		possible in part, or at all, during an inspection and, in such circumstances, and inspector may recommend the use of special access equipment and that a further inspection be undertaken when a safe method of access is present.&nbsp;<br />
		
		<strong>3.3 Excluded Areas&nbsp;</strong><br />
		Areas where reasonable entry is denied to the inspector, or where reasonable access is not available, are excluded from, and do not form part of, the inspection. Excluded areas include areas where safe OH&amp;S was not available. Excluded
		areas include areas where access was specifically denied (i.e. locked rooms) or access was not provided, (manholes not opened prior to the inspectors arrival) while the inspector is on site conducting the inspection.<br />
		Where appropriate, we would recommend that consideration be given to the subsequent inspection of excluded areas, where they exist, given these areas will have been excluded from scope of our report.

	</>,
	<> 
		<strong>3.4 Exclusion of items from inspection&nbsp;</strong><br />
		The inspector need not inspect or report on the following:&nbsp;
		<ol type="a">
			<li>Items that outside of the agreed specific scope of the inspection&nbsp;</li>
			<li>Footings below ground.&nbsp;</li>
			<li>Concealed damp-proof course.&nbsp;</li>
			<li>
				All electrical, gas and plumbing items which under WA Legislation would require a Licenced Electrician, Plumber or Gas Fitter to test and inspect including but not limited to:
				<ul>
					<li>Utility conduits and services</li>
					<li>Electrical systems, security systems, access stems and components</li>
					<li>Solar and other sustainability infrastructure</li>
					<li>Lifts.</li>
					<li>Ventilation.</li>
					<li>Air conditioning systems.</li>
					<li>
						Electrical (or gas) appliances including but not limited to garbage disposal, electrical installations, light switches and fittings, dishwashers, incinerators, ovens, stoves, ducted vacuum systems.TV, sound and
						communications.
					</li>
					<li>Hot water systems.</li>
					<li>Swimming pools, spas, ponds, and pumps or filters.</li>
					<li>Water bores and water tanks.</li>
					<li>Back flow devices and pumping equipment</li>
					<li>Reticulation equipment&nbsp;</li>
					<li>Car stackers.</li>
					<li>Roof access safety equipment.</li>
					<li>Plumbing.&nbsp;</li>
					<li>Adequacy of roof drainage as installed.&nbsp;</li>
					<li>Gas fittings and fixtures.&nbsp;</li>
					<li>Automatic garage door mechanisms.&nbsp;</li>
				</ul>
			</li>
			<li>The operation of fireplaces and solid fuel heaters, including chimneys and flues.</li>
			<li>Paint coatings, except external protective coatings.&nbsp;</li>
			<li>Health hazards (eg allergies, lead content, presence of asbestos, soil toxicity)</li>
			<li>Timber and metal framing sizes and adequacy.&nbsp;</li>
			<li>Concealed tie-downs and bracing.&nbsp;</li>
			<li>Timber pest activity.&nbsp;</li>
			<li>Soil conditions.&nbsp;</li>
			<li>Control joints.&nbsp;</li>
			<li>Concealed framing-timber or any areas concealed by wall linings/sidings.&nbsp;</li>
			<li>Aluminium composite panels</li>
			<li>Furniture and accessories.&nbsp;</li>
		</ol>
		Consideration should be given to the inspection of excluded items by an appropriately qualified professional.&nbsp; The report does not include the inspection or assessment of items or matters that do not fall within the Inspectors direct
		expertise. Estimating the cost of remedying defects is not included in a Staged Inspection report.&nbsp;

	</>,
        <> 
        <strong>4. Inspection process&nbsp;</strong><br />
		The inspection shall comprise visual appraisal and limited assessment of serviceability.&nbsp; The inspector shall document their findings in the inspection report.&nbsp; Where applicable the inspector may include recommendation for further
		inspections by a specialist inspector (Electricians, Plumbers, Structural Engineers, Fire Engineers, Geotechnical Engineer, Pest Inspectors and similar).&nbsp;<br />
		<br />
		<strong>5. Extent of Reporting&nbsp;</strong><br />
		The inspection report will comment on the agreed specific inspection scope criteria for individual inspections. The following general inspection scope criteria applies if it has not been excluded:&nbsp;
		<ul>
			<li>Major Defects&nbsp;</li>
			<li>Significant Items&nbsp;</li>
			<li>Works that are not completed in accordance with the stage of construction that has been reached</li>
			<li>Safety hazards&nbsp;</li>
		</ul>
		<strong>6. Defects</strong><br />
		During an inspection the inspector may identify that a building element is defective but that the defect does not fall neatly into one of the categories of defect. In such a case the inspector will use a combination of defect properties or
		otherwise assess and describe the defect in his/her own words, based on his/her experience.&nbsp; In many cases, the actual structural elements of a building will be obscured by finishes and other non-structural building elements, and the
		inspector may be unable to assess directly the state of the structural member. In such cases, the inspector has to infer the performance of the structure by observing the effect of the structure on the non-structural building elements. For
		example, the inspector normally will be unable to inspect the footings of a building as they are buried beneath the ground; however, cracking in non-structural masonry walls above the ground may indicate that a defect exists within the
		footing system.&nbsp;<br />
		<br />
		<strong>7.Inspections for Significant Defects&nbsp;</strong><br />
		An inspection for significant defects shall comprise visual assessment of accessible areas of the property to identify significant defects relating to the construction of the building and associated structures within 30 meters to form an
		opinion regarding the general status of the construction work.&nbsp; AS4349.0 Inspection of Buildings. An item that is to be reported in accordance with the scope of the inspection which may be a feature that is present or a feature which
		is absent.<br />
		<br />
		<strong>7.1 Significant Items for Construction Purposes&nbsp;</strong><br/>
		Significant defects for construction purposes include significant defects on completed works which can be visually identified and represent a significant departure from:&nbsp;<ul><li>The requirements to the approved Construction Plans where those plans have been provided to the inspector.</li><li>The National Construction Code (Building Code of Australia).&nbsp;</li><li>Standards generally considered acceptable by a competant trades person&nbsp;</li></ul>
		<br />
		<strong>7.2 Incomplete Works</strong><br />
		Incomplete works for construction include works that would normally be expected to be completed for the stage of works that the inspector is inspecting. For Example:
		<ul>
			<li>An inspection of masonry walls to plate height where not all of the walls had been completed.</li>
			<li>An inspection of roof on where the ridge caps had not been installed.&nbsp;</li>
		</ul>

	</>,
	<> 
		<strong>7.3 Minor defects&nbsp;</strong><br />
		A defect other than a significant defect or that requires immediate intervention to avert further deterioration.&nbsp; The inspector is not required to comment on individual minor defects and imperfections.&nbsp;<br />
		<br />
		<strong>8. Safety Items and Hazards</strong><br />
		Based on visual inspection the inspector will attempt to comment on safety items which are subject to specific legislation in WA. These includes:
		<ul>
			<li>The existence and location of smoke alarms&nbsp;</li>
			<li>The existence of Residual Current Devices (RCD’s)</li>
		</ul>
		Where these items are not in situ they many constitute a Safety item. Clients should always consider engaging an Electrical Contractor to review the Compliance of Smoke Alarms and RCD’s to Legislative requirements. Based on visual
		inspection the inspector will attempt to visually identify any observed item that constitutes a present or imminent serious safety hazard.&nbsp;<br />
		<br />
		<strong>9. Categorising Conditions&nbsp;</strong><br />
		Houspect has tried to categorise our gradings of material condition as: Acceptable – consistent with industry standards of workmanship. Issue – requires Builder to review and remediate where appropriate. Wherever we describe a building
		material in this report, the client acknowledges that the material described represents a substantial component of the building material observed.
	</>
]