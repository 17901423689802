import { Formik, Form, Field, ErrorMessage } from "formik";

import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "react-datepicker/dist/react-datepicker.css";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";


const UserProfileModal = ({ 
    userRecordForModal,
    action,
    visible,
    onClose,
    setLoadingModalVisible,
    setConfirmationModalVisible,
    returnAndLoadUsers,
    userProfileModalAction }) => {

    const submitbtn = useRef(null);
    
    const isDisabled = userProfileModalAction !== 'Create';
    const [availability, setAvailability] = useState(null);
    const [isChecking, setIsChecking] = useState(false);

    const checkAvailability = async (userID) => {
      setIsChecking(true);
      // Replace with the actual check availability function call
      const available = await fakeCheckAvailability(userID);
      setAvailability(available);
      setIsChecking(false);
    };
  
    // Fake check availability function for demonstration purposes
    const fakeCheckAvailability = (userID) => {
      return new Promise((resolve) => {
        setTimeout(() => resolve(userID !== 'takenUserID'), 1000);
      });
    };
    
    const showTable = () => {
        const [form, setForm] = useState({
            initialValues: {
                userID: '',
                email: '',
                displayName: '',
                roles: [],
                inspectorRole: "No",
                officeRole: "No",
                masterRole: "No",
                inspectorDescription: ""
            },
        })
        
        useMemo(() => {
            if (userRecordForModal && Object.keys(userRecordForModal).length !== 0) {
                console.log(userRecordForModal)
                
                setForm({
                    initialValues: {
                        ...form.initialValues,
                        userID: userRecordForModal.uid,
                        email: userRecordForModal.email,
                        displayName: userRecordForModal.displayName,
                        inspectorRole: userRecordForModal.inspectorRole,
                        officeRole: userRecordForModal.officeRole,
                        masterRole: userRecordForModal.masterRole,
                        inspectorDescription: userRecordForModal.inspectorDescription
                    }
                })
            }

        }, [userRecordForModal]);

        

        const submitForm = async (values) => {
            if (availability === false) {
              alert('Please select a unique user ID');
              return;
            }

            setLoadingModalVisible(true);
            setForm(form);

            if(values.inspectorRole == 'Yes') values.roles.push('Inspector');
            if(values.officeRole == 'Yes') values.roles.push('Office');
            if(values.masterRole == 'Yes') values.roles.push('Master');

            console.log('Form values:>>>>>>>>>> ', values);

            //Handle create user
            if(userProfileModalAction == 'Create') {
                let response1 = await postRequestReturnAll("/createUser", values);
                let response2 = await postRequestReturnAll("/createInspectorDescription", values);
                if(!(response1.status === 200 || response1.status === 201 || response1.status === 202)) {
                    setLoadingModalVisible(false);
                    alert(`An error has occurred: Either the user ID or email address is already in use by another account.`);
                    
                } else if(!(response2.status === 200 || response2.status === 201 || response2.status === 202)) {
                    setLoadingModalVisible(false);
                    alert(`An error has occurred: Error when trying to set user roles.`)
                } else {
                    
                    //Job successfully created / updated
                    setConfirmationModalVisible(true);
                    setLoadingModalVisible(false);
                    
                    setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadUsers(); }, 2000);
                }

            }

            //Handle update user
            if(userProfileModalAction == 'Update') {
                let response1 = await postRequestReturnAll("/updateUser", values);
                let response2 = await postRequestReturnAll("/updateInspectorDescription", values);
                if(!(response1.status === 200 || response1.status === 201 || response1.status === 202)) {
                    setLoadingModalVisible(false);
                    alert(`An error has occurred while updating the users account`);
                    
                } else if(!(response2.status === 200 || response2.status === 201 || response2.status === 202)) {
                    setLoadingModalVisible(false);
                    alert(`An error has occurred while trying to set user roles.`)
                } else {
                    
                    //Job successfully created / updated
                    setConfirmationModalVisible(true);
                    setLoadingModalVisible(false);
                    
                    setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadUsers(); }, 2000);
                }

            }
        };


        const handleCheckboxChange = (event) => {
            console.log('Check')
            const { name, checked } = event.target;
            console.log(event)
            setForm({
                ...form,
                initialValues: {
                    ...form.initialValues,
                    roles: checked
                        ? [...form.initialValues.roles, name]
                        : form.initialValues.roles.filter(role => role !== name),
                },
            });
        };

        return (
            <>
                <Formik initialValues={form.initialValues}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        //resetForm();
                    }}>

                    {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <Form name='user' onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                            return false
                        }}>
                            <div className="row">
                                <div className="col">
                                    {userProfileModalAction == 'Create' && (                                        
                                        <>
                                        <small><strong>Please note: </strong>All new users will have a default password of 'inspectAPP000'. Send a 'reset password emai' by clicking the blue icon below once the user has been added.</small>
                                        <br/><br/>
                                        </>
                                    )}
                                    <div className="form-group">  
                                        <div className="input-group">
                                            <label className="form-label" htmlFor="userID">User ID<span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="userID"
                                                name="userID"
                                                value={values.userID}
                                                onChange={handleChange}
                                                disabled={isDisabled}
                                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
                                            />
                                            {!isDisabled && (
                                                <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={() => checkAvailability(values.userID)}
                                                    disabled={isChecking}
                                                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                >
                                                    Check Availability
                                                </button>
                                                </div>
                                            )}
                                        </div>
                                        {availability === true && (
                                            <small style={{ color: 'green' }}>User ID is available</small>
                                        )}
                                        {availability === false && (
                                            <small style={{ color: 'red' }}>User ID is not available</small>
                                        )}
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="email">Email<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}

                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="displayName">Display name<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            id="displayName"
                                            name="displayName"
                                            value={values.displayName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectorDescription">Inspector description (show in final report)<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            id="inspectorDescription"
                                            name="inspectorDescription"
                                            value={values.inspectorDescription}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-1"></div>

                                <div className="col-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectorRole">Inspector Role<span className="text-danger">*</span></label>
                                        <div>
                                            <label style={{ marginRight: '10px' }}>
                                                <input
                                                    type="radio"
                                                    name="inspectorRole"
                                                    value="Yes"
                                                    checked={values.inspectorRole === 'Yes'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="inspectorRole"
                                                    value="No"
                                                    checked={values.inspectorRole === 'No'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label" htmlFor="officeRole">Office Role<span className="text-danger">*</span></label>
                                        <div>
                                            <label style={{ marginRight: '10px' }}>
                                                <input
                                                    type="radio"
                                                    name="officeRole"
                                                    value="Yes"
                                                    checked={values.officeRole === 'Yes'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="officeRole"
                                                    value="No"
                                                    checked={values.officeRole === 'No'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label" htmlFor="masterRole">Master Role<span className="text-danger">*</span></label>
                                        <div>
                                            <label style={{ marginRight: '10px' }}>
                                                <input
                                                    type="radio"
                                                    name="masterRole"
                                                    value="Yes"
                                                    checked={values.masterRole === 'Yes'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="masterRole"
                                                    value="No"
                                                    checked={values.masterRole === 'No'}
                                                    onChange={handleChange}
                                                    style={{ marginRight: '5px' }}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col"></div>

                                <button className="d-none" type="submit" ref={submitbtn}>submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>{userProfileModalAction} user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showTable()}
            </Modal.Body>
            <Modal.Footer>
                <Button type='submit' form='user' variant="success" onClick={() => {
                    submitbtn.current.click();
                    onClose();
                }}>
                    {userProfileModalAction == 'Create' && (<span>Create</span>)}
                    {userProfileModalAction == 'Update' && (<span>Update</span>)}
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserProfileModal;
