import React, { useEffect, useRef, useState } from "react";

// import formik 
import { Formik, Form, Field, ErrorMessage } from "formik";

// date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import locale from 'date-fns/locale/en-GB'
import { momentFormate } from "../../helpers/generalHelper";

//Import components 
import InputGroupDropdown from "./jobForm/InputGroupDropdown";
import moment from "moment";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";

// import boostrap 
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { InspectGroupDropdown } from "./jobForm";





const CreateAgencyModal = ({ recordForModal, visible, getRecordList, onClose, setLoadingModalVisible }) => {

    const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    const submitbtn = useRef(null);

    // const [appointmentModalInspectorUserID, setAppointmentModalInspectorUserID] = useState(null);

    const [inspectorUserIDOptions, setInspectorUserIDOptions] = useState([{ value: '', label: 'Select' }]);
    const [inspectorObjArray, setInspectorObjArray] = useState([]);



    const showTable = () => {

        const [form, setForm] = useState({
            initialValues: {
                agencyName: "",
                agencyEmailAddress: "",
                agencyPhoneNumber: "",
                agencyType: "",
                agencyPostalAddress: ""
            },
        })

        useEffect(() => {
        }, []);

        const submitForm = async (values) => {
            onClose();
            setLoadingModalVisible(true);
            console.log('Form values: ', values)
            setForm(form)


            const response = await postRequestReturnAll("/createAgency", {record: values});

            if (response.status === 200 || response.status === 201 || response.status === 202) {
                getRecordList();
                setLoadingModalVisible(false);

            } else {
                //Show error
                console.log('An error has occurred when submitting an appointment to the server')
                setLoadingModalVisible(false);
            }
        };

        useEffect(() => {
            if (recordForModal && Object.entries(recordForModal).length !== 0) {
                setForm(recordForModal);
            }
        }, [recordForModal])

        


        return (
            <>

                <Formik initialValues={recordForModal && Object.entries(recordForModal).length !== 0 ? recordForModal : form.initialValues}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm()
                    }}>

                    {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <Form name='appointment' onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                            return false
                        }}>

                            <div className="row">

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="agencyName">Agency full name<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            id="agencyName"
                                            name="agencyName"
                                            value={values.agencyName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>                         
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="agencyEmailAddress">Agency email address<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            name="agencyEmailAddress"
                                            value={values.agencyEmailAddress}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="agencyPhoneNumber">Agency phone number
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            type="text"
                                            name="agencyPhoneNumber"
                                            value={values.agencyPhoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="agencyType">Agency type<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            name="agencyType"
                                            value={values.agencyType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="agencyPostalAddress">Agency postal address
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            type="text"
                                            name="agencyPostalAddress"
                                            value={values.agencyPostalAddress}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <button className="d-none" type="submit" ref={submitbtn}>submit</button>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>Edit agency</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showTable()}
            </Modal.Body>
            <Modal.Footer>

                <Button type='submit' form='appointment' variant="success" onClick={() => {
                    submitbtn.current.click();
                    onClose();
                }}>
                    <span>Create</span>
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateAgencyModal;
