import { getAuth } from "firebase/auth";
import React, { createContext, useState, useEffect } from "react";
export const AuthContext = createContext({ userPresent: false, user: null });

const AuthContextProvider = (props) => {
  let [state, changeState] = useState({
    userDataPresent: false,
    user: null,
    listener: null,
  });
  const auth = getAuth();
  useEffect(() => {
    if (state.listener === null) {
      changeState({
        ...state,
        listener: auth.onAuthStateChanged((user) => {
          console.log('auth.onAuthStateChanged(): ' + JSON.stringify(user));
          if (user) {
            console.log('already authenticated');
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: user,
            }));
          } else {
            console.log('no longer authenticated');
            console.log('auth not authenticated: ' + JSON.stringify(state));
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: null,
            }));
          }
        }),
      });
    }
    return () => {
      if (state.listener) state.listener();
    };
  }, []);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
};
export default AuthContextProvider;