import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0, 
	maximumFractionDigits: 0
});

const MaintenancePlan = ({
    organisationConfigRecord,
    inspectionJobRecord,
	inspectionObj,
    sectionHeaderArray,
    sectionHeaderIndex,
	maintenanceIssuesArray,
	pageIndex,
	rowsPerPage
}) => {
	
    const [totalCost, setTotalCost] = useState(0);
    const [totalCostNow, setTotalCostNow] = useState(0);
    const [totalCostWithin1Year, setTotalCostWithin1Year] = useState(0);
    const [totalCostWithin2Year, setTotalCostWithin2Year] = useState(0);
    const [totalCostWithin3Year, setTotalCostWithin3Year] = useState(0);
    const [totalCostAnnualMaintenance, setTotalCostAnnualMaintenance] = useState(0);


    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
		console.log(maintenanceIssuesArray);

		let tempTotalCost = 0;
		let tempTotalCostNow = 0;
		let tempTotalCostWithin1Year = 0;
		let tempTotalCostWithin2Year = 0;
		let tempTotalCostWithin3Year = 0;
		let tempTotalCostAnnualMaintenance = 0;
		
		for (const maintenanceItem of maintenanceIssuesArray) {
			tempTotalCost += maintenanceItem.issueMaintenanceCost
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Now') { tempTotalCostNow += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 1 year') { tempTotalCostWithin1Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 2 years') { tempTotalCostWithin2Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 3 years') { tempTotalCostWithin3Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == "Annual cost") { tempTotalCostAnnualMaintenance += maintenanceItem.issueMaintenanceCost }
		}
		setTotalCost(tempTotalCost);
		setTotalCostNow(tempTotalCostNow);
		setTotalCostWithin1Year(tempTotalCostWithin1Year);
		setTotalCostWithin2Year(tempTotalCostWithin2Year);
		setTotalCostWithin3Year(tempTotalCostWithin3Year);
		setTotalCostAnnualMaintenance(tempTotalCostAnnualMaintenance);
    }, [maintenanceIssuesArray]);

	const getMaintenancePlanTableRows = () => {
		let rowsJSX = [];
        let startRowIndex = pageIndex*rowsPerPage;
        let endRowIndex = (pageIndex+1)*rowsPerPage;
        let currentIndex = 0;
		for (var i = 0; i < maintenanceIssuesArray.length; i++) {
			let maintenanceItem = maintenanceIssuesArray[i];
			currentIndex++;//And then count each additional issue row
			
			if((currentIndex >= startRowIndex) && (currentIndex < endRowIndex)) {
				rowsJSX.push(
					<tr>
						<td>{maintenanceItem.itemIndex}.{maintenanceItem.issueIndex} {maintenanceItem.issueName}</td>
						<td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td>
						{maintenanceItem.issueMaintenanceCostTimeline == 'Now' ? <td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td> : <td></td>}
						{maintenanceItem.issueMaintenanceCostTimeline == 'Within 1 year' ? <td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td> : <td></td>}
						{maintenanceItem.issueMaintenanceCostTimeline == 'Within 2 years' ? <td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td> : <td></td>}
						{maintenanceItem.issueMaintenanceCostTimeline == 'Within 3 years' ? <td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td> : <td></td>}
						{maintenanceItem.issueMaintenanceCostTimeline == "Annual cost" ? <td>{formatter.format(maintenanceItem.issueMaintenanceCost)}</td> : <td></td>}
					</tr>
				)
			}
		}
		return rowsJSX;
	}

	return (
		<>			
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar activeSection={"Inspection details"} sectionArray={["Summary", "Inspection issues", "Inspection details", "Appendix"]} />
				<div className="p-4">
					<div className="sectionHeader">
						<span>Maintenance plan</span>
					</div>
					<table className="maintenancePlanTable">
						<tr>
							<th>Issue</th>
							<th>Est. cost</th>
							<th>Now</th>
							<th>Within 1 year</th>
							<th>Within 2 years</th>
							<th>Within 3 years</th>
							<th>Annual maintenance</th>
						</tr>
						{getMaintenancePlanTableRows()}
						<tr>
							<td></td>
							<td><span><strong>{formatter.format(totalCost)}</strong></span></td>
							<td><span><strong>{formatter.format(totalCostNow)}</strong></span></td>
							<td><span><strong>{formatter.format(totalCostWithin1Year)}</strong></span></td>
							<td><span><strong>{formatter.format(totalCostWithin2Year)}</strong></span></td>
							<td><span><strong>{formatter.format(totalCostWithin3Year)}</strong></span></td>
							<td><span><strong>{formatter.format(totalCostAnnualMaintenance)}</strong></span></td>
						</tr>
					</table>
					<br/>
					<br/>
				</div>

				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default MaintenancePlan;