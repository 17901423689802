import React, { useContext, useEffect, useState } from "react";
import Label from "react-bootstrap/Modal";

//Import helpers
import { shortenAddress } from "../../../helpers/generalHelper";
import { postRequest, postRequestReturnAll } from "../../../helpers/apiHelper";
import { AuthContext } from "./../../../contexts/AuthContext";

//Import components
import JobTableButton from "./JobTableButton";
import { JobTableLink } from "./jobTableLink";

// Style components
import "./index.css";
import JobTablePDF from "./jobTablePDF";

const JobTable = ({
  userRolesArray,
  tableData,
  mode,
  checkedState,
  setCheckedState,
  setSelectJob,
  selectRef,
  deselectRef,
  getJobRecordList,
  setLoadingModalVisible,
  setConfirmationModalVisible,
}) => {
	
	const authValue = useContext(AuthContext);

    const [userRoles, setUserRoles] = useState([]);

	const handleCheckbox = (e) => {
		if (checkedState.includes(Number(e.target.value))) {
		const updatedState = checkedState.filter((val) => {
			return Number(e.target.value) === val ? false : true;
		});

		setCheckedState(updatedState);
		} else {
		setCheckedState([...checkedState, Number(e.target.value)]);
		}
	};

	const selectAll = () => {
		const selectAllJob = tableData.map((job) => {
		return job.jobID;
		});

		selectAllJob && setCheckedState(selectAllJob);
	};

	const DeselectAll = () => {
		setCheckedState([]);

		var boxes = document.getElementsByTagName("input");
		for (var x = 0; x < boxes.length; x++) {
		var obj = boxes[x];
		if (obj.type === "checkbox") {
			obj.checked = false;
		}
		}
	};
	
	useEffect(() => {
		setSelectJob(checkedState.length);
	}, [checkedState]);

	useEffect(() => {
		getUserRoles();
	}, []);

	const getUserRoles = async () => {		
		const userRoles = await postRequest("/getUserRoles", {});
		setUserRoles(userRoles)
	}
	
	const userHasOfficeRole = () => {
		if(userRoles.includes("Office")) {
			return true
		} else {
			return false
		}
	};


	const getPhotoCounts = async (jobID, inspectorUserID) => {
		setLoadingModalVisible(true);
		let shouldBePhotoCount = await postRequest("/getInspectionObjPhotoCount", { jobID: jobID, inspectorUserID: inspectorUserID });
		let currentPhotoCount = await postRequest("/getCloudinaryPhotoCount", { jobID: jobID, inspectorUserID: inspectorUserID });

		setTimeout(function(){
			alert(`${currentPhotoCount} of ${shouldBePhotoCount} photos have been successfully uploaded`)
	
			setLoadingModalVisible(false);
		}, 3000);

	}

	const downloadJobSheet = async (jobID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);
		let reportToken = await postRequest("/getReportToken", { jobID: jobID });

		console.log(reportToken);
		let base_url = window.location.origin;
		let jobSheetURL = `${base_url}/jobSheet?reportToken=${reportToken}`;
		console.log(jobSheetURL)
		let restpackURLAccessToken =
		"zG9Pia4xDZdqA1uK4OWibncCs2yA6XQAYK7j8u9aE1GGGI2E";
		let restpackURL = `https://restpack.io/api/html2pdf/v7/convert?access_token=${restpackURLAccessToken}&url=${jobSheetURL}&pdf_page=A4&delay=3000`;

		//Directly download the file
		fetch(restpackURL)
			.then(resp => resp.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = `Job sheet - ${String(jobID)}.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setLoadingModalVisible(false);
			})
			.catch(() => {
				alert('An error has occurred while trying to download job sheet PDF');
				setLoadingModalVisible(false)
			});
	};

		
	const navigateToEditReport = async (jobID, inspectionClass) => {
		let reportToken = await postRequest("/getReportToken", { jobID: jobID });
			
		window.location = `/reportEditor?reportToken=${reportToken}`;
	};

	const approveJob = async (jobID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);

		//Update via post Request
		await postRequest("/updateJobStatus", {
		jobID: jobID,
		newStatus: "Approved",
		});

		//Refresh the job table data (via parent component)
		getJobRecordList();
	};

	const unapproveJob = async (jobID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);

		//Update via post Request
		await postRequest("/updateJobStatus", {
			jobID: jobID,
			newStatus: "Uploaded",
		});

		//Refresh the job table data (via parent component)
		getJobRecordList();
	};

	const downloadPDFReport = async (jobID, inspectionClass) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);
		let reportToken = await postRequest("/getReportToken", { jobID: jobID });

		console.log(reportToken);

		let reportRoute = '';
		if(inspectionClass === 'Dilapidation inspections') {reportRoute='dilapidation';}
		if(inspectionClass === 'Construction inspections') {reportRoute='construction';}
		if(inspectionClass === 'Building inspections') {reportRoute='building';}
		if(inspectionClass === 'General building inspections') {reportRoute='generalBuilding';}
		if(inspectionClass === 'Post construction inspections') {reportRoute='postConstruction';}
		if(inspectionClass === 'Pre settlement inspections') {reportRoute='preSettlement';}

		let base_url = window.location.origin;

		let jobSheetURL = `${base_url}/PDFReport/${reportRoute}?reportToken=${reportToken}`;
		let restpackURLAccessToken = "zG9Pia4xDZdqA1uK4OWibncCs2yA6XQAYK7j8u9aE1GGGI2E";
		let restpackURL = `https://restpack.io/api/html2pdf/v5/convert?access_token=${restpackURLAccessToken}&url=${jobSheetURL}&pdf_page=A4&delay=20000`;

		//Directly download the file
		fetch(restpackURL)
			.then(resp => resp.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = `${String(jobID)}.pdf`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setLoadingModalVisible(false);
			})
			.catch(() => {
				alert('An error has occurred while trying to download PDF report');
				setLoadingModalVisible(false)
			});

	};

	const downloadPhotoZip = async (jobID, inspectorUserID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);

		//Update via post Request
		let downloadURL = await postRequest("/getCloudinaryPhotoZipDownloadURL", {
			jobID: jobID,
			inspectorUserID: inspectorUserID,
		});

		console.log('Download URL: ' + downloadURL)

		var link = document.createElement("a");
		//link.download = "xxxx.zip";
		link.href = downloadURL;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		setLoadingModalVisible(false);
	};


	const archiveJob = async (jobID, inspectorUserID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);

		//Update via post Request
		let response = await postRequestReturnAll("/archiveJob", {
			jobID: jobID,
			inspectorUserID: inspectorUserID
		});
		console.log('Archiving job response: ' + response)

		if(response.status === 200 || response.status === 201 || response.status === 202) {
			setLoadingModalVisible(false);
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setTimeout(function() {setConfirmationModalVisible(false)}, 2000);
			
			//Refresh the job table data (via parent component)
			getJobRecordList();
		} else {
			setLoadingModalVisible(false);
			//Show error
		}
	};


	const deleteJob = async (jobID, inspectorUserID) => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);

		//Update via post Request
		let response = await postRequestReturnAll("/deleteJob", {
			jobID: jobID,
			inspectorUserID: inspectorUserID
		});
		console.log('Deleting job response: ' + response)

		if(response.status === 200 || response.status === 201 || response.status === 202) {
			setLoadingModalVisible(false);
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setTimeout(function() {setConfirmationModalVisible(false)}, 2000);
			
			//Refresh the job table data (via parent component)
			getJobRecordList();
		} else {
			setLoadingModalVisible(false);
			//Show error
		}
	};
	

	const showTable = () => {
		if (tableData === null || tableData === []) {
		return (
			<div className="text-center">
			<Label tag="h5">An error has occurred</Label>
			</div>
		);
		} else {
		return (
			<>
			<div ref={selectRef} onClick={selectAll} style={{ display: "none" }}>
				click
			</div>

			<div
				ref={deselectRef}
				onClick={DeselectAll}
				style={{ display: "none" }}
			>
				click
			</div>
			<table role="table" className="portalTable">

			<thead>
				<tr role="row">
				{mode === "archive" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				<th role="columnheader" style={{ width: "80px" }}>
					Time/date
				</th>
				<th role="columnheader">Inspector</th>
				<th role="columnheader">Job ID</th>
				<th role="columnheader">Type</th>
				<th role="columnheader">Status</th>
				<th role="columnheader">Client</th>
				<th role="columnheader">Address</th>
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "archive" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "delete" && (
					<th role="columnheader" className="buttonColumn"></th>
				)}
				{mode === "view" && <th role="columnheader" className="buttonColumn"></th>}
				{mode === "view" && <th role="columnheader" className="buttonColumn"></th>}
				</tr>
			</thead>
			<tbody role="rowgroup">
				{Array.isArray(tableData) &&
				tableData.map((value, index) => {
					return (
					<tr role="row" key={index}>
						{mode === "archive" && (
						<td id={"check"} className="cb">
							<input
							type="checkbox"
							checked={checkedState.includes(value.jobID) && true}
							value={value.jobID}
							onChange={(e) => handleCheckbox(e)}
							className="form-control d-inline-block cb-select-checkbox"
							style={{ height: "1em", width: "1em" }}
							name="cb_select[]"
							/>
						</td>
						)}
						<td role="cell" style={{ width: "150px" }}>
							{value.inspectionStartTime && value.inspectionEndTime && (
								<span>
									{`${value.inspectionStartTime} to ${value.inspectionEndTime}`}
								</span>
							)}
							{!(value.inspectionStartTime && value.inspectionEndTime) && (
								<span>
									TBD
								</span>
							)}
							<br />
							{value.inspectionDate && (
								<span>
									<strong>
										{`${value.inspectionDate}`}
									</strong>
								</span>
							)}
						</td>
						<td role="cell">{value.inspectorUserID}</td>
						<td role="cell">{value.jobID}</td>
						<td role="cell">
							{!(value.originalInspectionJobID) > 0 && (
								<span>{value.inspectionType}</span>
							)} 
							{(value.originalInspectionJobID) > 0 && (
								<span>{value.inspectionType} (reinspection)</span>
							)} 
						</td>
						<td role="cell">
							{value.jobStatus !=='Approved' && value.jobStatus !=='Uploaded' && value.jobStatus !=='Edited' && (
								<div style={{padding:2, paddingHorizontal: 10, color: 'white', textAlign: 'center', justifyContent: 'center', backgroundColor: '#212529', borderRadius: 5}}>
									{value.jobStatus}
								</div>
							)}
							{(value.jobStatus =='Uploaded' || value.jobStatus =='Edited') && (
								<div style={{padding:2, paddingHorizontal: 10, color: 'white', textAlign: 'center', justifyContent: 'center', backgroundColor: '#ffc107', borderRadius: 5}}>
									{value.jobStatus}
								</div>
							)}
							{value.jobStatus =='Approved' && (
								<div style={{padding:2, paddingHorizontal: 10, color: 'white', textAlign: 'center', justifyContent: 'center', backgroundColor: 'rgb(28,200,138)', borderRadius: 5}}>
									{value.jobStatus}
								</div>
							)}

						</td>
						<td role="cell">
						{value.corporateOrganisation !== "" &&
							value.corporateOrganisation !== null &&
							(
								`${value.clientFullName} (${value.corporateOrganisation})`
							)}
						{(value.corporateOrganisation === "" ||
							value.corporateOrganisation === null) &&
							value.clientFullName}
						</td>
						<td role="cell">{shortenAddress(value.propertyAddress)}</td>
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{userHasOfficeRole() && (
							<JobTableButton
								iconName={"file"}
								backgroundColour={"info"}
								tooltipText={"Job sheet"}
								onClick={() => {
								downloadJobSheet(value.jobID);
								}}
							/>
							)}
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{userHasOfficeRole() && (
							<JobTableLink
								path={`/copyJob?copyJobID=${value.jobID}`}
								icon="copy"
								className="dark"
								tooltipText={"Copy job"}
							/>
							)}
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{userHasOfficeRole() && (
							<JobTableLink
								path={`/editJob?jobID=${value.jobID}`}
								icon="edit"
								className="danger"
								tooltipText={"Edit job"}
							/>
							)}
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							<JobTableButton
								iconName={"keyboard"}
								backgroundColour={"warning"}
								tooltipText={"Edit report"}
								onClick={() => {
									navigateToEditReport(value.jobID, value.inspectionClass);
								}}
							/>
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{(value.jobStatus === "Uploaded" ||
							value.jobStatus === "Edited") && (
							<JobTableButton
								iconName={"check-circle"}
								backgroundColour={"success"}
								tooltipText={"Approve"}
								onClick={() => {
								approveJob(value.jobID);
								}}
							/>
							)}
							{value.jobStatus === "Approved" &&
							userHasOfficeRole() && (
								<JobTableButton
								iconName={"times-circle"}
								backgroundColour={"danger"}
								tooltipText={"Unapprove"}
								onClick={() => {
									unapproveJob(value.jobID);
								}}
								/>
							)}
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{userHasOfficeRole() && (
							<JobTableButton
								iconName={"calculator"}
								backgroundColour={"dark"}
								tooltipText={"Uploaded photos"}
								onClick={() => {
								getPhotoCounts(value.jobID, value.inspectorUserID);
								}}
							/>
							)}
						</td>
						)}
						{mode === "archive" && (
						<td role="cell" style={{ width: "40px" }}>
							<JobTableButton
							iconName={"archive"}
							backgroundColour={"warning"}
							tooltipText={"Archive job"}
							onClick={() => {
								archiveJob(value.jobID, value.inspectorUserID);
							}}
							/>
						</td>
						)}
						{mode === "delete" && (
						<td role="cell" style={{ width: "40px" }}>
							<JobTableButton
							iconName={"trash"}
							backgroundColour={"danger"}
							tooltipText={"Delete job"}
							onClick={() => {
								deleteJob(value.jobID, value.inspectorUserID);
							}}
							/>
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{value.jobStatus === "Approved" && userHasOfficeRole() &&(
								<JobTableButton
									iconName={"file-pdf"}
									backgroundColour={"dark"}
									tooltipText={"PDF report"}
									onClick={() => {
										downloadPDFReport(value.jobID, value.inspectionClass);
									}}
								/>)
							}
						</td>
						)}
						{mode === "view" && (
						<td role="cell" style={{ width: "40px" }}>
							{value.jobStatus === "Approved" && userHasOfficeRole() &&(
								<JobTableButton
									iconName={"folder"}
									backgroundColour={"dark"}
									tooltipText={"Photo zip"}
									onClick={() => {
										downloadPhotoZip(value.jobID, value.inspectorUserID);
									}}
								/>)
							}
						</td>
						)}
					</tr>
					);
				})}
			</tbody>
			</table>
			</>
		);
		
	};
  };

  return <>{showTable()}</>;
};

export default JobTable;
