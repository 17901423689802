import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA882ZOQshxE8c-NQIZ1MtxuYiGJcSK5IY",
  authDomain: "inspectapp-341106.firebaseapp.com",
  projectId: "inspectapp-341106",
  storageBucket: "inspectapp-341106.appspot.com",
  messagingSenderId: "953956865793",
  appId: "1:953956865793:web:ccc456892f149c9886ed00",
  measurementId: "G-YV63J3JD83",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
