import React, { useState, useEffect, useRef, useContext } from "react";

//**Import components */
import {
	LoadingModal,
	ConfirmationModal,
	FreeTextSearch
} from "../../components/portal";

import AgentTable from "../../components/portal/agentTable"
import CreateAgentModal from "../../components/portal/CreateAgentModal";

//**Import helpers */
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";

// import filterJob from "../../helpers/filterJobHelper";
import { AuthContext } from "../../contexts/AuthContext";

//**Style css components */
import "./../../../src/assets/stylesheets/portal.css";

const AgentTableView = ({ mode }) => {
	
	const authValue = useContext(AuthContext);

	
	const [CreateAgentModalVisible, setCreateAgentModalVisible] = useState(false);

	//** State define */
	const [search, setSearch] = useState("");
	const [userRolesArray, setUserRolesArray] = useState([]);

	const [checkedState, setCheckedState] = useState([]);
	const [agentTableData, setAgentTableData] = useState([]);

	const [selectJob, setSelectJob] = useState(0);

	const [recordForModal, setRecordForModal] = useState({});

	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [loadingModalVisible, setLoadingModalVisible] = useState(true);

	//** UseRef */
	const selectRef = useRef(null);
	const deselectRef = useRef(null);

	// loading modal styling
	const loading = {
		position: "absolute",
		top: "60%",
		left: "50%",
		width: "5%",
		display: loadingModalVisible ? "block" : "none",
	};


	const handleClear = () => {
		setSearch("");
	};

	// Searching agentTable
	const handleSearchClick = (input) => {
		console.log('Clicked: ' + input)
		setSearch(input);
		getRecordList(input);
	};



	const getRecordList = async (input="") => {
		//Ensure loading modal is displayed
		setLoadingModalVisible(true);

		//Uses the same 
		let agentTableArray = await postRequest("/getAgentsFromSearch", {searchInput: input});

		if (agentTableArray) {			
			setAgentTableData(agentTableArray);
		}

		//Table generation complete, hide the loading modal
		setLoadingModalVisible(false);
	};

	useEffect(() => {
		if(userRolesArray && userRolesArray.length !==0){
			getRecordList(userRolesArray);
		}
	}, [userRolesArray]);


	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {

		if (agentTableData.length === 0) {
			getRecordList();
		}
	}, []);
	


	return (
		<>
			<LoadingModal visible={loadingModalVisible} />
			<ConfirmationModal visible={confirmationModalVisible} />
			<CreateAgentModal visible={CreateAgentModalVisible} recordForModal={recordForModal} onClose={() => { setCreateAgentModalVisible(false) }} getRecordList={getRecordList} setLoadingModalVisible={setLoadingModalVisible} />
			
			
			<div class="d-flex justify-content-between">
				
				<div className="d-flex align-items-start justify-content-end flex-column mb-">
					<button className="btn btn-success btn-rounded btn-sm m-0" type="button" onClick={() => { setCreateAgentModalVisible(true); }}>
						<i className="fa fa-plus-circle"></i>
						<span> Create agent</span>
					</button>
					
					<div>
						{agentTableData.length > 0 && (
							<p>{agentTableData.length} agents displayed</p>
						)}
					</div>
				</div>

				<div className="d-flex mb-3">
					
					<FreeTextSearch
						handleSearchClick={handleSearchClick}
						handleClear={handleClear}
						search={search}
						setSearch={setSearch}
						setLoadingModalVisible={setLoadingModalVisible}
					/>
								
				</div>
			</div>		

			<div style={{ display: 'flex', flexDirection: "row" }}>
			</div>
			<AgentTable
				userRolesArray={userRolesArray}
				tableData={agentTableData}
				checkedState={checkedState}
				setCheckedState={setCheckedState}
				mode={mode}
				setSelectJob={setSelectJob}
				setRecordForModal={setRecordForModal}
				selectRef={selectRef}
				deselectRef={deselectRef}
				getRecordList={getRecordList}
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
				setCreateAgentModalVisible={setCreateAgentModalVisible}
			/>
		</>
	);
};

export default AgentTableView;
