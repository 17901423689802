import React, { useEffect, useState } from "react";

const SelectTimeButton = ({ inputName, timeValue, selectTime, setFieldValue}) => {
  return (
    <>
      <a
          className="btn btn-info btn-sm timeBtn"
          onClick={() => {
            selectTime(timeValue);
            setFieldValue(inputName, timeValue);
          }}
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample">
          <span style={{color : 'white'}}>
            {timeValue}
          </span>
      </a>
    </>
  );
};

export default SelectTimeButton;
