import React, { useState, useEffect } from "react";
import moment from "moment";

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import JobForm from "../../../views/jobForm";

//Import helpers
import { postRequest } from "../../../helpers/apiHelper";
import { useSearchParams } from "react-router-dom";

const CreateJob = (props) => {
  const [jobRecord, setJobRecord] = useState([]);
  const [params] = useSearchParams();

  const [loadingModalVisible, setLoadingModalVisibility] = useState(false);

  //If there is a copy job URL parameter, identify that here
  //...
  //The following post request will get the relevant job fields from the server

  useEffect(() => {
    const getJobRecordToBeCopied = async () => {
    setLoadingModalVisibility(true)
      const jobRecord = await postRequest("/getJobRecord", {
        jobID: params.get("copyJobID"),
      });
      
      //Copy job needs to override the following variables
      jobRecord && setJobRecord(()=>jobRecord.map((job)=>{
        return {...job, jobID:"To be generated", 
        inspectionObjString: "{}",
        inspectionDate: "",
        inspectorUserID: "",
        officeUserID: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).displayName : "",
        testJobFlag: "",
        pestFlag: "",
        clientIsOwnerFlag: "",
        inspectionClass: "",
        inspectionType: "",
        inspectionStartTime: "",
        inspectionEndTime: "",
        inspectionPriceTotal: 0,
        inspectionPriceInspectorFee: 0,
        inspectionPriceTravelFee: 0,
        inspectionPriceFranchiseFee: 0,
        inspectionPricePestFee: 0}
      }));
    setLoadingModalVisibility(false)

    };
    getJobRecordToBeCopied();
  }, []);

  return (
    <>
      <ViewHeaderTitle title={"Create job"} />
      <JobForm action={"Create"} jobRecord={jobRecord} loadingModalVisible={loadingModalVisible} setLoadingModalVisibility={setLoadingModalVisibility} />
    </>
  );
};

export default CreateJob;
