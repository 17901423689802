import moment from "moment/moment";


export const shortenAddress = (address) => {
    try {
        if (address.indexOf(', Australia') > -1) {
            return address.replace(', Australia', '');
        } else {
            return address;
        }
    } catch (e) { }
}

export const returnFormattedDateString = (dateVal) => {
    let dateObj = new Date(dateVal);
    var dd = String(dateObj.getDate()).padStart(2, '0');
    var mm = String(dateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = dateObj.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
}

export const momentFormate = (date) => {
    return moment(date).format("DD-MM-YYYY");
};