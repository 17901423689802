import React, {useEffect, useState} from "react";

import axios from "axios";

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import ConfigurationTile from "../../../components/portal/configuration/ConfigurationTile";

import { LoadingModal } from "./../../../components/portal/index"
import { ConfirmationModal } from "./../../../components/portal/index";


function configurationPage() {
	// const [loadingModalVisible, setLoadingModalVisibility] = useState(false);
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [loadingModalVisible, setLoadingModalVisible] = useState(false);
	

	return (
		<>
			<LoadingModal visible={loadingModalVisible} />
			<ConfirmationModal visible={confirmationModalVisible} setConfirmationModalVisible={setConfirmationModalVisible} />

			<ViewHeaderTitle title={"Configuration"} />

			<ConfigurationTile 
				title={"Post Construction - Common issues"} 
				configKey={"CommonIssuesPostConstruction"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

			<ConfigurationTile 
				title={"Construction - Common issues"} 
				configKey={"CommonIssuesConstruction"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

			<ConfigurationTile 
				title={"Dilapidation - Common issues"} 
				configKey={"CommonIssuesDilapidation"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

			<ConfigurationTile 
				title={"Building - Common issues"} 
				configKey={"CommonIssuesBuilding"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

			<ConfigurationTile 
				title={"Building - Comment builder"} 
				configKey={"CommentBuilderBuilding"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

			<ConfigurationTile 
				title={"Pre settlement - Items"} 
				configKey={"ItemsPreSettlement"} 				
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>

		</>
	);
};

export default configurationPage;
