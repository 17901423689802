import React, { useEffect, useState } from "react";
import {FormFeedback} from "reactstrap";

const InputGroupFinancial = ({ inputName, inputLabelName, required=false, touched, errors, values, handleBlur, handleChange }) => {
    let ariaVarName=`aria-${inputName}`
  return (
      <div className="form-group">
        <label className="form-label" htmlFor={inputName}>
            {inputLabelName}
            {required &&
                <span className="text-danger">*</span>
            }            
        </label>
        
        <div className="input-group">
            <div className="input-group-prepend">
                <div id={ariaVarName} className="input-group-text">
                    <span>$</span>
                </div>
            </div>
            <input
                invalid={touched[inputName] && errors[inputName]}
                className="form-control"
                type="number" onChange={handleChange} onBlur={handleBlur}
                placeholder={inputLabelName} 
                name={inputName}
                value={values[inputName]}
                aria-describedby={ariaVarName}
            />
        </div>
        {
            touched[inputName] && errors[inputName] && (
                <FormFeedback>
                    {errors[inputName]}
                </FormFeedback>
            )
        }
      </div>
  );
};

export default InputGroupFinancial;
