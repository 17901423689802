import React, { useContext } from "react";

export const logout = () => {
	//Remove items in local storage
	localStorage.removeItem("refreshToken");
	localStorage.removeItem("uid");
	localStorage.removeItem("email");

	//Redirect to signin
	window.location.href = "/signin";
}
