
import React, {useEffect, useState} from "react";
import axios from "axios";
//Import helpers
import { postRequestFileUpload, authenticatedGetExcelDownload } from "../../../helpers/apiHelper";

const ConfigurationTile = ({
	title,
	configKey,
	setLoadingModalVisible,
	setConfirmationModalVisible,
  }) => {
    
	const [file, setFile] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);
  
	const toggleCard = () => {
	  setIsExpanded(!isExpanded);
	};

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		setFile(selectedFile);
	};
	
	const DownloadButton = (props) => {
		return(
			<button className="btn btn-dark btn-rounded btn-sm m-0" type="button" onClick={props.onClick}>
				<i className="fa fa-download"></i> 
				<span> Download</span>
			</button>
		)
	}

	const downloadFile = async () => {			
        setLoadingModalVisible(true);		
		let downloadResponse = await authenticatedGetExcelDownload(`/downloadConfigFile?configKey=${configKey}`, `Config File - ${title}.xlsx`);;
		
		if(downloadResponse.status == 200) {				
			setLoadingModalVisible(false);		
		} else {
			setLoadingModalVisible(false);		
			alert('An error has occurred while downloading the configuration file. Please try again.')
		}
	}

	const uploadFile = async (formData, formEvent) => {
		let uploadResponse = await postRequestFileUpload('uploadConfigFile', formData);
		
		if(uploadResponse.status == 200) {				
            //Reset the form
            formEvent.target.reset();

			setLoadingModalVisible(false);		
			setConfirmationModalVisible(true);
			//Auto hide after two seconds
			setTimeout(function () { setConfirmationModalVisible(false); }, 2000);
		} else {
			setLoadingModalVisible(false);		
			alert('An error has occurred while uploading the configuration file. Please try again.')
		}
	}
    return (
        <div className="row">
			<div className="col-12">
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							{title}
						</h6>
						<button className="btn btn-dark btn-rounded btn-sm m-0" type="button" onClick={toggleCard}>
							{isExpanded ? 
								<><i className="fa fa-minus"></i><span> Collapse</span> </> : 
								<><i className="fa fa-plus"></i><span> Expand</span> </> 
							}
						</button>
					</div>
					
					<div className="card-body p-2 d-flex flex-row">
						{isExpanded && (
							<>
								<div className="card-body p-2 block-inline">
									<p>
										<span className="m-0 font-weight-bold text-dark">Step 1: </span>Download existing configuration file. The latest configuration file will automatically download as an excel workbook. 				
									</p>
									<DownloadButton onClick={downloadFile}/>
								</div>
								<div className="card-body p-2 block-inline">
									<p>
										<span className="m-0 font-weight-bold text-dark">Step 2: </span> Upload updated configuration file. Once you have made changes, select the saved excel workbook under 'Choose File' and click 'Upload'	
									</p>							
									<form onSubmit={(formEvent) => {	
										//Prevent page reload			
										formEvent.preventDefault();
										if (file) {	
											setLoadingModalVisible(true);
											
											let inputFileName = file.name;
											//Check if it is the same name as the download file
											if(inputFileName.includes(title)) {
												//File name matches, proceed with upload
												const formData = new FormData();
												formData.append("file", file);
												formData.append("configKey", configKey);	
												uploadFile(formData, formEvent);
											} else {
												//Warn user of that the file to be uploaded doesn't match the name
												if (window.confirm("The upload file name doesn't match the downloaded configuration file! If you upload the wrong file, it will overwrite your existing configuration. Please make sure you are certain this is the correct file.") == true) {
													//File name matches, proceed with upload
													const formData = new FormData();
													formData.append("file", file);
													formData.append("configKey", configKey);	
													uploadFile(formData, formEvent);
												} else {
													//Upload does not happen
													setLoadingModalVisible(false);
												}
											}
										} else {
											alert('Please select a file to upload first')
										}
									}}>
										{/* File input element with accept attribute for Excel files  */}
										<input
											type="file"
											id="file"
											name="file"
											accept=".xlsx"
											onChange={handleFileChange}/>
											<button className="btn btn-success btn-rounded btn-sm m-0">
												<i className="fa fa-upload"></i> 
												<span> Upload</span>
											</button>
									</form>
								</div>	
							</>	
						
						)}				
					</div>
				</div>
			</div>
		</div>
    );
};

export default ConfigurationTile;
