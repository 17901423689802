import React, { useEffect } from "react";

import { Routes, Route, Outlet } from "react-router-dom";

//Import components
import SideBar from "../../views/navigation/SideBar";
import TopBar from "../../views/navigation/TopBar";

const AuthLayout = () => {
  return (
    <>
      <div id="wrapper">
        <SideBar />
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <TopBar />
            <div className="container-fluid">
              <div className="">
                <div className="col-12 mb-4"></div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
