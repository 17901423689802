import React, { useState, useEffect } from "react";
import { postRequest } from "../../helpers/apiHelper";
import { logout } from "../../helpers/authHelper";

const TopBar = () => {
	const [userRoles, setUserRoles] = useState([]);
	const [org, setOrg] = useState("");
	const [displayName, setDisplayName] = useState("");


	useEffect(() => {
		getCurrentUserAttributes();
	}, []);

	const getCurrentUserAttributes = async () => {		
		const currentUserAttributes = await postRequest("/getCurrentUserAttributes", {});

		setUserRoles(currentUserAttributes.roles);
		setOrg(currentUserAttributes.org);
    	setDisplayName(currentUserAttributes.name);
	}
  
  
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <ul className="navbar-nav ml-auto">
        <div className="hide" id="alertsDropdownNav">
          <li className="nav-item dropdown no-arrow mx-1">
            <a
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell fa-fw"></i>
            </a>
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 className="dropdown-header">Alerts Center</h6>
              <a className="dropdown-item d-flex align-items-center">
                <div className="mr-3">
                  <div className="icon-circle bg-dark">
                    <i className="fas fa-file-alt text-white"></i>
                  </div>
                </div>
                <div>
                  <span
                    className="small text-gray-500"
                    id="notification1Time"
                  ></span>
                  <br />
                  <span id="notification1Message"></span>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center">
                <div className="mr-3">
                  <div className="icon-circle bg-dark">
                    <i className="fas fa-file-alt text-white"></i>
                  </div>
                </div>
                <div>
                  <span
                    className="small text-gray-500"
                    id="notification2Time"
                  ></span>
                  <br />
                  <span id="notification2Message"></span>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center">
                <div className="mr-3">
                  <div className="icon-circle bg-dark">
                    <i className="fas fa-file-alt text-white"></i>
                  </div>
                </div>
                <div>
                  <span
                    className="small text-gray-500"
                    id="notification3Time"
                  ></span>
                  <br />
                  <span id="notification3Message"></span>
                </div>
              </a>
              <a className="dropdown-item text-center small text-gray-500">
                Show All Notifications
              </a>
            </div>
          </li>
        </div>
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              id="userDropdown"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                className="mr-2 d-none d-lg-inline text-gray-600 small"
                id="menuUserID"
              ></span>
              <i className="fas fa-fw fa-2x fa-user-circle"></i>
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
              style={{ cursor: "pointer" }}
            >
              <div className="dropdown-item">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                <span id="userID">{displayName} </span>
              </div>
              <div className="dropdown-item">
                <i className="fas fa-building fa-sm fa-fw mr-2 text-gray-400"></i>
                <span id="organisation">
                  {org}
                </span>
              </div>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" onClick={logout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
      </ul>
    </nav>
  );
};

export default TopBar;
