import React from "react";
import { Link } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const JobTableLink = ({ path, className, icon, tooltipText }) => {
  return (
    <div className="d-flex gap-3">
      <div className="view-job">
        <OverlayTrigger
          overlay={(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
          placement="bottom"
        >
          <Link to={path} className={`btn btn-sm btn-${className}`}>
            <i className={`fas fa-fw fa-${icon}`} />
          </Link>
        </OverlayTrigger>
      </div>
    </div>
  );
};
