import React, { useState, useEffect } from "react";

import "./../../../../src/assets/stylesheets/report/generalReport.css";
import { useSearchParams } from "react-router-dom";

//Import components
// import Header from "./../Header";
// import Footer from "./../Footer";
import CoverPage from "../1CoverPage";
import PropertySummary from "../2PropertySummary";
import PropertyOverviewPhotos from "../3PropertyOverviewPhotos";
import ReportSummary from "../4ReportSummary";
// import IssueFlags from "./../5IssueFlags";
import ItemSummary from "../6ItemSummary";
import ItemSummaryDilapidation from "../6ItemSummaryDilapidation";
import ItemPage from "../7ItemPage";
import IssuePageDilapidation from "../7IssuePageDilapidation";
import IssuePageDilapidationReinspection from "../7IssuePageDilapidationReinspection";
import NonIssueItems from "../8NonIssueItems";
import AdditionalIssuePhotos from "../9AdditionalIssuePhotos";
import Scope from "../10Scope";
import Conditions from "../10Conditions";

//Import helpers
import {
  // postRequest,
  postRequestWithoutToken,
} from "../../../helpers/apiHelper";

//Import scope sections
import { scopePagesArray } from "./scope";
import { conditionsPagesArray } from "./conditions";

const sectionHeaderArray = [
	"Summary",
	"External areas",
	"Internal areas",
	"Appendix",
]

const Report = (props) => {
	const editMode = props.editMode;
	const [loading, setLoading] = useState(false);
	const [organisationConfigRecord, setOrganisationConfigRecord] = useState({});
	const [inspectionJobRecord, setInspectionJobRecord] = useState({});
	const [inspectionObj, setInspectionObj] = useState({});
	const [externalItemsArray, setExternalItemsArray] = useState([]);
	const [internalItemsArray, setInternalItemsArray] = useState([]);
	const [signedURLArray, setSignedURLArray] = useState([]);

	const [params] = useSearchParams();

	//Get organisation config record
	const getOrganisationConfigRecord = async () => {
		const organisationConfigRecord = await postRequestWithoutToken(
			"/getOrganisationConfigRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
		console.log(organisationConfigRecord)
		organisationConfigRecord &&
			setOrganisationConfigRecord(organisationConfigRecord[0]);
	};

	//Get the signed URL array
	const getSignedURLArray = async () => {
		const signedURLArrayResponse = await postRequestWithoutToken(
			"/getSignedURLForImageBulk",
			{ reportToken: params.get("reportToken") }
		);
		console.log(signedURLArrayResponse)
		
		signedURLArrayResponse &&
			setSignedURLArray(signedURLArrayResponse);			
	};

	//Get the inspection job record and index inspection items
	const indexInspectionItems = (inspectionJobRecordRaw) => {
		let inspectionObjRaw = JSON.parse(inspectionJobRecordRaw.inspectionObjString)
		let itemArray = inspectionObjRaw.items;
		
		//For dilapidation inspections, sort external and then internal
		let externalItems = itemArray.filter(function(item){
			return item.itemLocation === 'External';
		});
		let internalItems = itemArray.filter(function(item){
			return item.itemLocation === 'Internal';
		});
		itemArray = [...externalItems, ...internalItems];
		
		setExternalItemsArray(externalItems);
		setInternalItemsArray(internalItems);

		for (var i = 0; i < itemArray.length; i++) {
			itemArray.index = i + 1;
		}

		//Need to ensure that reordering of dilapidation items is the latest order for when comments are edited and update the inspection obj
		let newInspectionObj = {...inspectionObjRaw}
		newInspectionObj.items = itemArray;
		let newInspectionJobRecord = {...inspectionJobRecordRaw}
		newInspectionJobRecord.inspectionObjString = JSON.stringify(newInspectionObj);

		setInspectionObj(newInspectionObj);		
		setInspectionJobRecord(newInspectionJobRecord);
        localStorage.setItem(`reportEditor${newInspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
	};

	const getInspectionJobRecord = async () => {
		const inspectionJobRecordResponse = await postRequestWithoutToken(
			"/getInspectionJobRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
	
		try {
			try {
				let inspectionJobRecordRaw = inspectionJobRecordResponse[0];
				//Index the items in order before setting the variable
				indexInspectionItems(inspectionJobRecordRaw);
			} catch (e) {console.log(e)}
		} catch (e) {console.log(e)}
	};
		
  	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		console.log('Edit mode: ' + editMode)

		getOrganisationConfigRecord();
		getInspectionJobRecord();
		getSignedURLArray();

		setTimeout(function(){
			populatePageNumbers();
		}, 3000);
		
  	}, []);

	const populatePageNumbers = () => {
		var pageNumberElementArray = document.querySelectorAll(".pageNumber");
		let totalPages = pageNumberElementArray.length + 1; //Account for cover page
		pageNumberElementArray.forEach(function (element, index) { 
			element.innerHTML = `Page ${index+2} of ${totalPages}`	//Start with page 1 and cover page doesn't have a page number class
		})
	}

  const itemSummaryPages = () => {
    if (Array.isArray(inspectionObj.items)) {
		let inspectionItems = inspectionObj.items;
		let externalCount = 0;
		let internalCount = 0;
		for (var i = 0; i < inspectionItems.length; i++) {
			if(inspectionItems[i].itemLocation == 'External') { externalCount++ };
			if(inspectionItems[i].itemLocation == 'Internal') { internalCount++ };
		}
		let rowsPerPage = 28;
		let noOfItems = Math.max(externalCount, internalCount);
		let pagesRequired = Math.ceil(noOfItems / rowsPerPage);
		console.log('Pages required: ' + pagesRequired)
		let itemSummaryPagesJSX = [];
		for (let page = 0; page < pagesRequired; page++) {
			itemSummaryPagesJSX.push(
			<ItemSummaryDilapidation
				sectionHeaderArray={sectionHeaderArray}
				sectionHeaderIndex={0}
				inspectionItems={inspectionItems}
				pageIndex={page}
				rowsPerPage={rowsPerPage}
				organisationConfigRecord={organisationConfigRecord}
				inspectionJobRecord={inspectionJobRecord}
				inspectionObj={inspectionObj}
				externalItemsArray={externalItemsArray}
				internalItemsArray={internalItemsArray}
				editMode={editMode}
				showLocation={true}
				signedURLArray={signedURLArray}
			/>
			);
		}
		return itemSummaryPagesJSX;
    }
  };

  const itemAndIssuePages = (location) => {
		let issuePagesJSX = [];
		if (Array.isArray(inspectionObj.items)) {	
			let inspectionItems = inspectionObj.items;	
			for (var i = 0; i < inspectionItems.length; i++) {
				if(inspectionItems[i].itemLocation ==location) {
					issuePagesJSX.push(
						<ItemPage
							sectionHeaderArray={sectionHeaderArray}
							sectionHeaderIndex={location == 'External' ? 1 : 2}
							editMode={editMode}
							itemIndex={i + 1}
							inspectionItem={inspectionItems[i]}
							organisationConfigRecord={organisationConfigRecord}
							inspectionJobRecord={inspectionJobRecord}
							inspectionObj={inspectionObj}
							externalItemsArray={externalItemsArray}
							internalItemsArray={internalItemsArray}
							signedURLArray={signedURLArray}
						/>
					);
					let inspectionItemIssues = inspectionItems[i].itemIssues;
					if (Array.isArray(inspectionItemIssues)) {
						if (inspectionItemIssues.length > 0) {
							for (var j = 0; j < inspectionItemIssues.length; j++) {
								//Separate if standard inspection or reinspection			
								if(inspectionJobRecord.originalInspectionJobID > 0) {
									issuePagesJSX.push(
										<IssuePageDilapidationReinspection
											sectionHeaderArray={sectionHeaderArray}
											sectionHeaderIndex={location == 'External' ? 1 : 2}
											editMode={editMode}
											itemIndex={i + 1}
											issueIndex={j + 1}
											inspectionItem={inspectionItems[i]}
											issue={inspectionItemIssues[j]}
											organisationConfigRecord={organisationConfigRecord}
											inspectionJobRecord={inspectionJobRecord}
											inspectionObj={inspectionObj}
											externalItemsArray={externalItemsArray}
											internalItemsArray={internalItemsArray}
											signedURLArray={signedURLArray}
										/>
									);
								} else {
									issuePagesJSX.push(
										<IssuePageDilapidation
											sectionHeaderArray={sectionHeaderArray}
											sectionHeaderIndex={location == 'External' ? 1 : 2}
											editMode={editMode}
											itemIndex={i + 1}
											issueIndex={j + 1}
											inspectionItem={inspectionItems[i]}
											issue={inspectionItemIssues[j]}
											organisationConfigRecord={organisationConfigRecord}
											inspectionJobRecord={inspectionJobRecord}
											inspectionObj={inspectionObj}
											externalItemsArray={externalItemsArray}
											internalItemsArray={internalItemsArray}
											signedURLArray={signedURLArray}
										/>
									);
								}
							}
						}
					}
				}
			}
			return issuePagesJSX;
		}
 	};

	const scopePages = () => {
		let scopePagesJSX = []
		for (var i = 0; i < scopePagesArray.length; i++) {
			scopePagesJSX.push(				
				<Scope
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={scopePagesArray[i]}
					/>
			)
		};

		return scopePagesJSX;
	}

	const conditionsPages = () => {
		let conditionsPagesJSX = []
		for (var i = 0; i < conditionsPagesArray.length; i++) {
			conditionsPagesJSX.push(				
				<Conditions
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={conditionsPagesArray[i]}
					/>
			)
		};

		return conditionsPagesJSX;
	}



	if(loading) {
		return <></>
	} else {
		//Need to make sure signed URL array is ready 
		if(signedURLArray.length > 0 ) {
			return (
				<>
					{/*Cover page*/}
					<CoverPage
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
					/>

					{/*Property summary photo and property description*/}
					<PropertySummary
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						editMode={editMode}
						signedURLArray={signedURLArray}
					/>

					{/*Report summary*/}
					{inspectionJobRecord.inspectionType == "Dilapidation inspection" && (
						<ReportSummary
							sectionHeaderArray={sectionHeaderArray}
							sectionHeaderIndex={0}
							organisationConfigRecord={organisationConfigRecord}
							inspectionJobRecord={inspectionJobRecord}
							inspectionObj={inspectionObj}
							reportPurpose={"The purpose of this report is to identify and record the location of cracking and signs of movement in the structure of the building, in so far as a licensed builder can reasonably identify those defects or faults. This report is the result of a visual examination only. Consistent with our agreement the following areas of the property are inspected: 1. External elevations (Walls and ground coverings), 2. Fences, Footpaths, Driveways and Retaining Walls, 3. Carports and or Garages, 4. Internal Walls, Floors and Ceilings"}
							/>
					)}
					
					{inspectionJobRecord.inspectionType == "Dilapidation general condition inspection" && (
						<ReportSummary
							sectionHeaderArray={sectionHeaderArray}
							sectionHeaderIndex={0}
							organisationConfigRecord={organisationConfigRecord}
							inspectionJobRecord={inspectionJobRecord}
							inspectionObj={inspectionObj}
							reportPurpose={"The purpose of this report is to identify and record the general condition of the building and its surrounds, in so far as a Registered builder can reasonably identify any defects or issues. This report is the result of a visual examination only. Consistent with our agreement the following areas of the property are inspected: 1. External elevations (Walls and ground coverings), 2. Fences, Footpaths, Driveways and Retaining Walls, 3. Carports and or Garages, 4. Internal Walls, Floors and Ceilings"}
							/>
					)}

					{/*Major alerts - Building only*/}

					{/*Item summary*/}
					{itemSummaryPages()}

					{/*External items*/}
					{itemAndIssuePages('External')}

					{/*Internal items*/}
					{itemAndIssuePages('Internal')}

					{/*Scope*/}
					{scopePages()}

					{/*Condtitions*/}
					{conditionsPages()}
					
					
					
				</>
			);	
		}
	}
};

export default Report;