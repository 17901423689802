import React, { useState, useEffect, useRef } from "react";

//**Import components */
import {
  LoadingModal,
  ConfirmationModal,
} from "../../components/portal";
import UserTable from './../../components/portal/userTable'

//**Import helpers */
import { postRequest } from "../../helpers/apiHelper";
import { postRequestWithoutToken } from "../../helpers/apiHelper";

//**Style css components */
import "./../../../src/assets/stylesheets/portal.css";

const UserTableView = ({ mode }) => {
	const [userObjArray, setUserObjArray] = useState([]);  
	const [loadingModalVisible, setLoadingModalVisible] = useState(true);
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

	const getUserRecordList = async () => {
		//Ensure loading modal is displayed
		setLoadingModalVisible(true);

		//Step 1 - Get the user list (Firebase authentication data via node.js express)
		let userList = await postRequest("/getUserList");
				
		//Step 2 - Get the inspector description (SQL databse data via node.js express)
		let getAllInspectorDescriptions = await postRequest("/getAllInspectorDescriptions");
		
		//Step 3 - Add the inspector description to each object
		userList.forEach((user) => {
			// Find the corresponding inspector description by matching the uid
			const inspectorInfo = getAllInspectorDescriptions.find(inspector => inspector.inspectorUserID === user.uid);
			
			// Add the inspectorDescription to the user object, default to an empty string if not found
			user.inspectorDescription = inspectorInfo ? inspectorInfo.inspectorDescription : "";
		});

		setUserObjArray(userList);
		
		//Table generation complete, hide the loading modal
		setLoadingModalVisible(false);
	};


	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		
		getUserRecordList();
		
	}, []);

	return (
		<>
			<LoadingModal visible={loadingModalVisible} />
			<ConfirmationModal visible={confirmationModalVisible} />
			<UserTable
				getUserRecordList={getUserRecordList}
				tableData={userObjArray}
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
			/>
		</>
	);
};

export default UserTableView;
