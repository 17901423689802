import React, { useEffect, useRef, useState } from 'react'
import { FormFeedback } from 'reactstrap';

const apiKey = process.env.REACT_APP_API_KEY;
const mapApi = 'https://maps.googleapis.com/maps/api/js';

function loadAsyncScript(src) {

    return new Promise(resolve => {
        const script = document.createElement("script");

        Object.assign(script, {
            type: 'text/javascript',
            async: true,
            src
        })
        script.addEventListener('load', () => resolve(script));
        document.head.appendChild(script)

    })
}

// Get suburb, postal code, state and country 
const extractAddress = (place) => {
    const address = {
        suburb: "",
        state: "",
        zip: ""
    }

    if (!Array.isArray(place.address_components)) {
        return address
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("locality") || types.includes("administrative_area_level_3")) {
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = value;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

    });

    return address;

}


const InputGroupAddress = ({ inputName, inputLabelName, required = false, touched, errors, form, setForm, values, handleChange, setFieldValue }) => {

    const searcInput = useRef(null)
    const propertyAddress = useRef("");

    // init map script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }

        const src = `${mapApi}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src)
    }

    // Get and set address 
    const onChangeAddress = (autocomplete) => {
        const location = autocomplete.getPlace();
        const address = extractAddress(location)
        setFieldValue('propertyAddress', propertyAddress.current)

        setFieldValue('propertySuburb', address.city)

        setFieldValue('propertyPostCode', address.zip)
        setFieldValue('propertyState', address.state)
        // setForm({ ...form, propertyAddress: propertyAddress.current, propertySuburb: address.city, propertyPostCode: address.zip, propertyState: address.state })
    }


    // init autoComplete
    const initAutoComplete = () => {

        if (!searcInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searcInput.current, { componentRestrictions: { country: "aus" } });
        autocomplete.setFields(["address_component", "geometry"])

        if (inputName === 'propertyAddress') {
            autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete))
        }

    }

    // load map script after mouted
    useEffect(() => {
        initMapScript().then(() => initAutoComplete())
    }, [])

    const handleBlur = (e) => {
        propertyAddress.current = e.target.value;
        // setForm({ ...form, [inputName]: e.target.value })
        setFieldValue(inputName, e.target.value)

    }


    return (
        <div className="form-group">
            <label className="form-label" htmlFor={inputName}>
                {inputLabelName}
                {required &&
                    <span className="text-danger">*</span>
                }
            </label>

            <div className='search'>
                <input invalid={touched[inputName] && errors[inputName]} type="text" className="form-control" id={inputName} name={inputName} value={values[inputName]} onChange={handleChange} ref={searcInput} onBlur={handleBlur} placeholder="" />

                {
                    touched[inputName] && errors[inputName] && (
                        <FormFeedback>
                            {errors[inputName]}
                        </FormFeedback>
                    )
                }
            </div>

        </div>
    )
}

export default InputGroupAddress