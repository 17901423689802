import React, {useEffect, useState} from "react";

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";

//Import helpers
import { postRequest, authenticatedGet } from "../../../helpers/apiHelper";



const DownloadButton = (props) => {
	return(
		<button className="btn btn-success btn-rounded btn-sm m-0" type="button" onClick={props.onClick}>
			<i className="fa fa-download"></i> 
			<span> Download</span>
		</button>
	)
}

const ExtractData = (props) => {
	console.log('render')
	
    const [singleJobSheetExtractJobID, setSingleJobSheetExtractJobID] = useState('');
    const [singleJobMaintenanceTableExtractJobID, setSingleJobMaintenanceTableExtractJobID] = useState('');
	
	const downloadExtract = async (URL, fileName) => {		
		let data = await authenticatedGet(URL);
		const url = window.URL.createObjectURL(new Blob([data])) 
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `${fileName}.csv`)
		document.body.appendChild(link)
		link.click()
	}
	
	const downloadExtractSingleJob = async (URL) => {		
		let data = await authenticatedGet(`${URL}?jobID=${singleJobSheetExtractJobID}`);
		const url = window.URL.createObjectURL(new Blob([data])) 
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `${singleJobSheetExtractJobID}-extract.csv`)
		document.body.appendChild(link)
		link.click()
	}
	
	const downloadExtractSingleJobMaintenanceTable = async (URL) => {		
		let data = await authenticatedGet(`${URL}?jobID=${singleJobMaintenanceTableExtractJobID}`);
		const url = window.URL.createObjectURL(new Blob([data])) 
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `${singleJobMaintenanceTableExtractJobID}-maintenance-table-extract.csv`)
		document.body.appendChild(link)
		link.click()
	}

	

	return (
		<>
		<ViewHeaderTitle title={props.headerTitle} />
		<div className="row">
			<div className="col-6">
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							Single job maintenance table extract
						</h6>
						<DownloadButton onClick={() => {downloadExtractSingleJobMaintenanceTable('/getSingleJobMaintenanceData')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							Provide the jobID to generate an extract of the maintenance items table
						</span>
						<br />
						<br />
						<label className="form-label">
							<span>JobID</span><span className="text-danger">*</span>
						</label>
						<input
							className="form-control"
							style={{width:'140px'}}
							type="text" 
							onChange={e => setSingleJobMaintenanceTableExtractJobID(e.target.value)}
							placeholder={""}
							value={singleJobMaintenanceTableExtractJobID}
						/>
					</div>
				</div>
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							Active job records
						</h6>
						<DownloadButton onClick={() => {downloadExtract('/getExtractActiveJobRecords', 'activeJobRecords')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							Extract of all <b>active</b> job records, excludes draft and archived jobs.
						</span>
						<br />
					</div>
				</div>
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							All job records
						</h6>
						<DownloadButton onClick={() => {downloadExtract('/getExtractAllJobRecords', 'allJobRecords')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							Includes all job records, including draft and archived jobs. 
						</span>
						<br />
					</div>
				</div>
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							Single job sheet extract
						</h6>
						<DownloadButton onClick={() => {downloadExtractSingleJob('/getSingleJobSheetExtract')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							Provide a JobID to get an extract of all data related to a single job
						</span>
						<br />
						<br />
						<label className="form-label">
							<span>JobID</span><span className="text-danger">*</span>
						</label>
						<input
							className="form-control"
							style={{width:'140px'}}
							type="text" 
							onChange={e => setSingleJobSheetExtractJobID(e.target.value)}
							placeholder={""}
							value={singleJobSheetExtractJobID}
						/>
					</div>
				</div>
			</div>
			<div className="col-3">
				<div className="row">
					<div className="card w-100 shadow mb-2">
						<div className="card-header p-2 d-flex justify-content-between align-items-center">
							<h6 className="m-0 font-weight-bold text-dark">
								Pest inspection extract
							</h6>
							<DownloadButton onClick={() => {downloadExtract('/getExtractAllPestJobRecords', 'allPestJobRecords')}}/>
						</div>
						<div className="card-body p-2">
							<span>
								All job records where the pest inspection flag = Yes
							</span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="card w-100 shadow mb-2">
						<div className="card-header p-2 d-flex justify-content-between align-items-center">
							<h6 className="m-0 font-weight-bold text-dark">
								SWMS extract
							</h6>
							<DownloadButton onClick={() => {downloadExtract('/getExtractSWMS', 'SWMSExtract')}}/>
						</div>
						<div className="card-body p-2">
							<span>
								All job records with SWMS data
							</span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="card w-100 shadow mb-2">
						<div className="card-header p-2 d-flex justify-content-between align-items-center">
							<h6 className="m-0 font-weight-bold text-dark">
								Referral extract
							</h6>
							<DownloadButton onClick={() => {downloadExtract('/getExtractReferrals', 'ReferralsExtract')}}/>
						</div>
						<div className="card-body p-2">
							<span>
								An extract containing all referral sources across all jobs. 
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="col-3">
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							Agents extract
						</h6>
						<DownloadButton onClick={() => {downloadExtract('/getExtractAgents', 'AgentsExtract')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							All agent records stored within the platform
						</span>
					</div>
				</div>
				<div className="card w-100 shadow mb-2">
					<div className="card-header p-2 d-flex justify-content-between align-items-center">
						<h6 className="m-0 font-weight-bold text-dark">
							Agencies extract
						</h6>
						<DownloadButton onClick={() => {downloadExtract('/getExtractAgencies', 'AgenciesExtract')}}/>
					</div>
					<div className="card-body p-2">
						<span>
							All agency records stored within the platform
						</span>
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default ExtractData;
