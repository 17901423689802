import React from "react";

const ViewHeaderTitle = (props) => {
  return (
    <h2 className="text-center mb-3">
      <b>{props.title}</b>
    </h2>
  );
};

export default ViewHeaderTitle;
