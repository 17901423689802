import React, { useEffect, useState } from "react";

// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Tooltip from 'react-bootstrap/Tooltip';

const JobFiltetDateButton = ({ status, toggleAction, jobFilterTimes }) => {
  if (jobFilterTimes.includes(status)) {
    return (
      <button
        type="button"
        className="btn btn-dark"
        onClick={() => {
          toggleAction(status);
        }}
      >
        {status}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className="btn btn-outline-dark"
        onClick={() => {
          toggleAction(status);
        }}
      >
        {status}
      </button>
    );
  }
};

export default JobFiltetDateButton;
