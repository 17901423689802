import React, {useState, useEffect} from "react";
//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const IssuePageDilapidation = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    editMode,
    itemIndex,
    issueIndex,
    inspectionItem,
    issue,
    sectionHeaderArray,
    sectionHeaderIndex,
	signedURLArray
}) => {


    const [liveIssueComment, setLiveIssueComments] = useState("");
    const [liveCrackLength, setLiveCrackLength] = useState("");
    const [liveCrackWidth, setLiveCrackWidth] = useState("");
    
    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        setLiveIssueComments(issue.issueComments)
        setLiveCrackLength(issue.crackLength)
        setLiveCrackWidth(issue.crackWidth)
    }, [issue]);

    const handleUpdate = (value) => {
        //console.log(value);
        setLiveIssueComments(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
		//Need to reduce index by 1 to be the array index, index in props is for the report numbering
		newInspectionObj.items[itemIndex-1].itemIssues[issueIndex-1].issueComments = value; 

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

    const handleCrackLengthUpdate = (value) => {
        //console.log(value);
        setLiveCrackLength(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
		//Need to reduce index by 1 to be the array index, index in props is for the report numbering
		newInspectionObj.items[itemIndex-1].itemIssues[issueIndex-1].crackLength = value; 

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

    const handleCrackWidthUpdate = (value) => {
        //console.log(value);
        setLiveCrackWidth(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
		//Need to reduce index by 1 to be the array index, index in props is for the report numbering
		newInspectionObj.items[itemIndex-1].itemIssues[issueIndex-1].crackWidth = value; 

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

	

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar 
                    activeSection={sectionHeaderArray[sectionHeaderIndex]}
                    sectionArray={sectionHeaderArray} />
				<div className="p-4">
					<div className="itemSummaryDivs">
						<div className="itemSummaryBiDivLeft">
							<table className="issueSummaryTable">
								<tbody>
									<tr>
										<th><i className="fa fa-list"></i> Item and issue name</th>
									</tr>
									<tr>
										<td>
											<span><strong>Item: </strong></span>{itemIndex}. {inspectionItem.itemName} <br/>
											<span><strong>Issue: </strong></span>{itemIndex}.{issueIndex} {issue.issueName}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="itemSummaryBiDivRight">
							<table className="issueSummaryTableCrackReinspection">
								<tbody>
									<tr>
										<th><i className="fa fa-warning"></i> Crack dimensions</th>
									</tr>
									<tr>
										<td>
											<span>
												Crack length: 
												{editMode && (
													<>
														<input
															className="crackEditor"
															name="liveIssueCrackLength"
															onChange={(e) => {
																handleCrackLengthUpdate(e.target.value);
															}}
															value={liveCrackLength}
														>
														</input><span> mm</span>
													</>
												)}
												{!editMode && issue.crackLength && (
													<span>{issue.crackLength} mm</span>
												)}
											</span>
											<br/>
											<span>
												Crack width: 
												{editMode && (
													<>
														<input
															className="crackEditor"
															name="liveIssueCrackWidth"
															onChange={(e) => {
																handleCrackWidthUpdate(e.target.value);
															}}
															value={liveCrackWidth}
														>
														</input><span> mm</span>
													</>
												)}
												{!editMode && issue.crackWidth && (
													<span>{issue.crackWidth} mm</span>
												)}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<table className="issueCommentsTable">
						<tbody>
							<tr>
								<th><i className="fa fa-comments"></i> Inspector issue comments</th>
							</tr>
							<tr>
								<td>

									{(inspectionJobRecord.originalInspectionJobID) > 0 && (
										<><span>
											<strong>Crack status: </strong>{issue.crackStatus}
										</span><br/></>
									)} 
									{editMode && (
										<textarea
											name="liveIssueComment"
											rows="4"
											onChange={(e) => {
												handleUpdate(e.target.value);
											}}
											value={liveIssueComment}
										>
										</textarea>
									)}

									{!editMode && (
										<p>
											{issue.issueComments}
										</p>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					{issue.issueReferencePhotos.length>0 && 
						<div>
							<table className="reportIssuePhotoTable">
								<tbody>
									<tr>
										<th colSpan="2"><i className="fa fa-images"></i> Reference photos</th>
									</tr>
									<tr>
										<td style={{ textAlign: 'center' }}>
											<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issueReferencePhotos[0]} classString={"cellImageIssue landscapePhoto"} />       
										</td>
										<td style={{ textAlign: 'center' }}>
											<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issueReferencePhotos[1]} classString={"cellImageIssue landscapePhoto"} />       
										</td>
									</tr>
									{issue.issueReferencePhotos.length>2 && 
										<tr>
											<td style={{ textAlign: 'center' }}>
												<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issueReferencePhotos[2]} classString={"cellImageIssue landscapePhoto"} />       
											</td>
											<td style={{ textAlign: 'center' }}>
												<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issueReferencePhotos[3]} classString={"cellImageIssue landscapePhoto"} />       
											</td>
										</tr>
									}
								</tbody>
							</table>
						</div>
					}
					<div>
						<table className="reportIssuePhotoTable">
							<tbody>
								<tr>
									<th colSpan="2"><i className="fa fa-images"></i> Issue photos</th>
								</tr>
								<tr>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issuePhotos[0]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issuePhotos[1]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
								</tr>
								{issue.issuePhotos.length>2 && 
									<tr>
										<td style={{ textAlign: 'center' }}>
											<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issuePhotos[2]} classString={"cellImageIssue landscapePhoto"} />       
										</td>
										<td style={{ textAlign: 'center' }}>
											<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={issue.issuePhotos[3]} classString={"cellImageIssue landscapePhoto"} />       
										</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>

				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default IssuePageDilapidation;