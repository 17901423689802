import React, {useState, useEffect} from "react";

	//Import components
	import Header from "./Header";
	import NavBar from "./NavBar";
	import Footer from "./Footer";

	const IssueFlags = ({organisationConfigRecord, inspectionJobRecord, inspectionObj, sectionHeaderArray, sectionHeaderIndex, inspectionItems}) => {

		const [itemsWithIssueFlags, setItemsWithIssueFlags] = useState([]);
    
		// Wait for the inspectionObj to update, if it does, update the propertyDescription
		useEffect(() => {
			let tempArray = [];
			if(inspectionObj.hasOwnProperty('items')) {		
				let inspectionItems = inspectionObj.items;		
				console.log(inspectionItems)
				inspectionItems.forEach(function(item, itemIndex) {
					let itemIssues = item.itemIssues;
					console.log(itemIssues)
					if (Array.isArray(itemIssues) && itemIssues.length > 0) {
						itemIssues .forEach(function(issue, issueIndex){
							let itemIssueFlags = issue.issueFlags;
							if(Array.isArray(itemIssueFlags)) {
								if(itemIssueFlags.length>0) {
									tempArray.push({
										itemNameString: `${itemIndex+1}. ${item.itemName}`,
										issueNameString: `${itemIndex+1}.${issueIndex+1} ${issue.issueName}`,
										itemIssueFlags: itemIssueFlags
									});
								};
							};
						});
					};
				});
			};
			setItemsWithIssueFlags(tempArray);
		}, [inspectionObj]);


		const getRows = (filteredArray) => {
			let componentJSX = [];
			filteredArray.forEach(function(obj) {
				componentJSX.push(
					<tr>
						<td>{obj.itemNameString}</td>
						<td>{obj.issueNameString}</td>
					</tr>
				)
			});
			return componentJSX;
		}

		const getItemsWithIssueFlag = (flag) => {
			if(itemsWithIssueFlags) {
				let filteredArray = itemsWithIssueFlags.filter(function(flagObj){
					return flagObj.itemIssueFlags.includes(flag);
				});

				if(filteredArray.length === 0) {
					return <span>No defects were identified of this nature</span>
				} else {
					return(
						<table className="issueSummaryTable">
							<tbody>
								<tr>
									<th>Inspection item</th>
									<th>Inspection issue</th>
								</tr>
								{getRows(filteredArray)}
							</tbody>
						</table>
					)
				}

			}
		}

		const getItemsWithIssueFlagOld = (flag) => {
			if(itemsWithIssueFlags) {
				let filteredArray = itemsWithIssueFlags.filter(function(flagObj){
					return flagObj.itemIssueFlags.includes(flag);
				});

				if(filteredArray.length === 0) {
					return <></>
				} else {
					return(
						<table className="issueSummaryTable">
							<tbody>
								<tr>
									<th>Inspection item</th>
									<th>Inspection issue</th>
								</tr>
								{getRows(filteredArray)}
							</tbody>
						</table>
					)
				}

			}
		}
		

		return (
			<>
				<div className="a4Page standardPage">
					<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
					<NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
					<div className="p-4">
						<div className="sectionHeader">
							<span>Inspection defects summary</span>
						</div>
							
						<span><strong>Major Structural Defect</strong></span>			
						<br/>									
						{getItemsWithIssueFlag('majorStructuralDefect')}

						<br/>
						<br/>

						<span><strong>Major Non Structural Defect</strong></span>
						<br/>
						{getItemsWithIssueFlag("majorNonStructuralDefect")}

					</div>
					<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				</div>
				<div className="a4Page standardPage">
					<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
					<NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
					<div className="p-4">
						<div className="sectionHeader">
							<span>Inspection safety item summary</span>
						</div>
						
						<span><strong>Regulatory Safety Item</strong></span>			
						<br/>			
						{getItemsWithIssueFlag("regulatorySafetyItem")}

						<br/>
						<br/>

						<span><strong>Safety Item</strong></span>
						<br/>
						{getItemsWithIssueFlag("safetyItem")}
						
						<br />
					</div>
					<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				</div>
			</>
		);
	};

	export default IssueFlags;