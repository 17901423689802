import React, { useState, useEffect } from "react";
//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const ItemIssuePage = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    editMode,
    itemIndex,
    inspectionItem,
    sectionHeaderArray,
    sectionHeaderIndex,
    signedURLArray
}) => {

    const [liveInspectionItem, setLiveInspectionItem] = useState("");

    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        setLiveInspectionItem(inspectionItem);
    }, [inspectionItem]);

    const handleItemCommentUpdate = (value) => {
        let newLiveInspectionItem = { ...liveInspectionItem };
        newLiveInspectionItem.itemComments = value;
        setLiveInspectionItem(newLiveInspectionItem);

        //Update the async storage value    
        let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
        newInspectionObj.items[itemIndex - 1] = newLiveInspectionItem;

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

    const handleIssueCommentUpdate = (value, issueIndex) => {
        let newLiveInspectionItem = { ...liveInspectionItem };
        if (newLiveInspectionItem.itemIssues) {
            newLiveInspectionItem.itemIssues[issueIndex].issueComments = value;
        }
        setLiveInspectionItem(newLiveInspectionItem);

        //Update the async storage value    
        let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
        newInspectionObj.items[itemIndex - 1] = newLiveInspectionItem;

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

    const additionalIssuePages = () => {
        let componentJSX = [];
        let itemIssues = inspectionItem.itemIssues || [];

        for (var i = 1; i < itemIssues.length; i = i + 2) {
            let issueIndex = i;
            componentJSX.push(
                <div className="a4Page standardPage" key={`issuePage${i}`}>
                    <Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
                    <NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
                    <div className="p-4">
                        <table className="issueCommentsTable">
                            <tbody>
                                <tr>
                                    <th align="left" colSpan="2">{itemIndex}.{i + 1} {itemIssues[i].issueName}</th>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        {issueFlag(liveInspectionItem.itemIssues[i].issueFlags)}

                                        {editMode && (
                                            <textarea
                                                name={`liveItemComment${i}`}
                                                rows="4"
                                                onChange={(e) => handleIssueCommentUpdate(e.target.value, issueIndex)}
                                                value={liveInspectionItem.itemIssues[i].issueComments}
                                            ></textarea>
                                        )}

                                        {!editMode && (
                                            <p>{liveInspectionItem.itemIssues[i].issueComments}</p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>
                                        <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemIssues[i].issuePhotos[0]} classString={"cellImageIssue landscapePhoto"} />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemIssues[i].issuePhotos[1]} classString={"cellImageIssue landscapePhoto"} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {itemIssues.length > (i + 1) && (
                            <table className="issueCommentsTable">
                                <tbody>
                                    <tr>
                                        <th align="left" colSpan="2">{itemIndex}.{i + 2} {itemIssues[i + 1].issueName}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            {issueFlag(liveInspectionItem.itemIssues[i + 1].issueFlags)}

                                            {editMode && (
                                                <textarea
                                                    name={`liveItemComment${i + 1}`}
                                                    rows="4"
                                                    onChange={(e) => handleIssueCommentUpdate(e.target.value, issueIndex + 1)}
                                                    value={liveInspectionItem.itemIssues[i + 1].issueComments}
                                                ></textarea>
                                            )}

                                            {!editMode && (
                                                <p>{liveInspectionItem.itemIssues[i + 1].issueComments}</p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}>
                                            <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemIssues[i + 1].issuePhotos[0]} classString={"cellImageIssue landscapePhoto"} />
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemIssues[i + 1].issuePhotos[1]} classString={"cellImageIssue landscapePhoto"} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                    <Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
                </div>
            );
        }
        return componentJSX;
    };

    const issueFlag = (issueFlags = []) => {
        let componentJSX = [];
        if (issueFlags.includes("majorStructuralDefectResidentialBuilding") || issueFlags.includes("majorStructuralDefectResidentialProperty")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Major Structural Defect
                    </span>
                    <br />
                </>
            );
        }
        if (issueFlags.includes("majorStructuralDefectBeyondResidentialBuilding") || issueFlags.includes("majorStructuralDefectBeyondResidentialProperty")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Major Structural Defect
                    </span>
                    <br />
                </>
            );
        }
        if (issueFlags.includes("majorStructuralDefect")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Major Structural Defect
                    </span>
                    <br />
                </>
            );
        }
        if (issueFlags.includes("majorNonStructuralDefect")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Major Non Structural Defect
                    </span>
                    <br />
                </>
            );
        }
        if (issueFlags.includes("regulatorySafetyItem")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Regulatory Safety Item
                    </span>
                    <br />
                </>
            );
        }
        if (issueFlags.includes("safetyItem")) {
            componentJSX.push(
                <>
                    <span>
                        <strong>Issue flag: </strong>Safety Item
                    </span>
                    <br />
                </>
            );
        }
        return componentJSX;
    };

    return (
        <>
            {liveInspectionItem && (
                <>
                    {inspectionItem.itemName ? (
                        <>
                            <div className="a4Page standardPage">
                                <Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
                                <NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
                                <div className="p-4">
                                    <table className="itemCommentsTable">
                                        <tbody>
                                            <tr>
                                                <th align="left" colSpan="2">{itemIndex}. {inspectionItem.itemName}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    {editMode && (
                                                        <textarea
                                                            name="liveItemComment"
                                                            rows="4"
                                                            onChange={(e) => handleItemCommentUpdate(e.target.value)}
                                                            value={liveInspectionItem.itemComments}
                                                        ></textarea>
                                                    )}

                                                    {!editMode && <p>{liveInspectionItem.itemComments}</p>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>
                                                    <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={liveInspectionItem.itemPhotos[0]} classString={"cellImageIssue landscapePhoto"} />
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={liveInspectionItem.itemPhotos[1]} classString={"cellImageIssue landscapePhoto"} />
                                                </td>
                                            </tr>
                                    
                                            </tbody>
                                    </table>
                                    {liveInspectionItem.itemIssues && liveInspectionItem.itemIssues.length > 0 ? (
                                        <table className="issueCommentsTable">
                                            <tbody>
                                                <tr>
                                                    <th align="left" colSpan="2">
                                                        {itemIndex}.{1} {liveInspectionItem.itemIssues[0].issueName}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        {issueFlag(liveInspectionItem.itemIssues[0].issueFlags)}
                                                        {editMode && (
                                                            <textarea
                                                                name="liveIssueComment0"
                                                                rows="4"
                                                                onChange={(e) =>
                                                                    handleIssueCommentUpdate(e.target.value, 0)
                                                                }
                                                                value={liveInspectionItem.itemIssues[0].issueComments}
                                                            ></textarea>
                                                        )}
                                                        {!editMode && <p>{liveInspectionItem.itemIssues[0].issueComments}</p>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Photo
                                                            signedURLArray={signedURLArray}
                                                            inspectorUserID={inspectionJobRecord.inspectorUserID}
                                                            jobID={inspectionJobRecord.jobID}
                                                            fileName={
                                                                liveInspectionItem.itemIssues[0].issuePhotos[0]
                                                            }
                                                            classString={"cellImageIssue landscapePhoto"}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Photo
                                                            signedURLArray={signedURLArray}
                                                            inspectorUserID={inspectionJobRecord.inspectorUserID}
                                                            jobID={inspectionJobRecord.jobID}
                                                            fileName={
                                                                liveInspectionItem.itemIssues[0].issuePhotos[1]
                                                            }
                                                            classString={"cellImageIssue landscapePhoto"}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>

                                <Footer
                                    organisationConfigRecord={organisationConfigRecord}
                                    inspectionJobRecord={inspectionJobRecord}
                                />
                            </div>

                            {additionalIssuePages()}
                        </>
                    ): <></>}
                </>
            )}
        </>
    );
};

export default ItemIssuePage;
