import React, { useState, useEffect, useRef } from "react";
import Label from "react-bootstrap/Modal";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { getAuth, sendPasswordResetEmail } from "@firebase/auth";

import { postRequestReturnAll } from "../../../helpers/apiHelper";

// Style components
import "./index.css";
import UserTableButton from './UserTableButton'
import UserProfileModal from "../UserProfileModal";

const UserTable = ({
	getUserRecordList,
	tableData,
	setLoadingModalVisible,
	setConfirmationModalVisible,
}) => {

	const blankUserRecord = {
		"uid": "",
        "email": "",
        "displayName": "",
        "roles": [],
		"inspectorDescription": "",
		"inspectorRole": "No",
		"officeRole": "No",
		"masterRole": "No",
	}
	
	const [userProfileModalVisible, setUserProfileModalVisible] = useState(false);
	const [userProfileModalAction, setUserProfileModalAction] = useState("Create");
	

	const [userRecordForModal, setUserRecordForModal] = useState(blankUserRecord);

	

	const renderTooltip = (text) => (props) => (
		<Tooltip id="button-tooltip" {...props}>
		  {text}
		</Tooltip>
	);

	const triggerResetEmail = async (email) => {
		//Job successfully created / updated
		setConfirmationModalVisible(true);
		try {
			const auth = getAuth();
			sendPasswordResetEmail(auth, email)
				.then(() => {
					// Email sent.
					setTimeout(function() {setConfirmationModalVisible(false)}, 2000);
				})
				.catch((error) => {
					// An error happened.
					console.error(error);
					alert(error)
				});
			
		} catch (error) {
			console.log(error.message)
		}
	}

	const deleteUser = async (userID) => {
		setLoadingModalVisible(true);

		let response1 = await postRequestReturnAll("/deleteUser", {
			"userID": userID
		});
		let response2 = await postRequestReturnAll("/deleteInspectorDescription", {
			"userID": userID
		});

		if(!(response1.status === 200 || response1.status === 201 || response1.status === 202)) {
			setLoadingModalVisible(false);
			alert(`An error has occurred while deleting the users account`);
			
		} else if(!(response2.status === 200 || response2.status === 201 || response2.status === 202)) {
			setLoadingModalVisible(false);
			alert(`An error has occurred while deleting the inspector description.`)
		} else {
			
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setLoadingModalVisible(false);
			
			setTimeout(function () { setConfirmationModalVisible(false); getUserRecordList(); }, 2000);
		}
	}

	
	  
	const showTable = () => {
		
		if (tableData === null || tableData == []) {
			return (
				<div className="text-center">
				<Label tag="h5">An error has occurred</Label>
				</div>
			);
		} else {
			return (
				<table role="table" className="portalTable">
					<thead>
						<tr role="row">
							<th role="columnheader">User ID</th>
							<th role="columnheader">Name</th>
							<th role="columnheader">Email</th>
							<th role="columnheader" style={{textAlign:'center'}}>
							Inspector
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={renderTooltip("Inspector role allows user to complete jobs on the mobile application. If they only have the inspector role, they will only be able to see their own jobs on the portal.")}
							>
								<span style={{ marginLeft: '5px', cursor: 'pointer' }}>
									<i className="fa fa-info-circle" style={{color: 'white'}}></i>
								</span>
							</OverlayTrigger>
							</th>
							<th role="columnheader" style={{textAlign:'center'}}>
							Office
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={renderTooltip("Office role allows user to create and edit jobs. They are able to generate PDF and zip folders once jobs are approved. They can create and edit inspector schedules.")}
							>
								<span style={{ marginLeft: '5px', cursor: 'pointer' }}>
									<i className="fa fa-info-circle" style={{color: 'white'}}></i>
								</span>
							</OverlayTrigger>
							</th>
							<th role="columnheader" style={{textAlign:'center'}}>
							Master
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={renderTooltip("The master role job archiving and deletion, access to all data extracts and can manage all users.")}
							>
								<span style={{ marginLeft: '5px', cursor: 'pointer' }}>
									<i className="fa fa-info-circle" style={{color: 'white'}}></i>
								</span>
							</OverlayTrigger>
							</th>
							<th role="columnheader"></th>
							<th role="columnheader"></th>
							<th role="columnheader"></th>
						</tr>
					</thead>
					<tbody role="rowgroup">
						{Array.isArray(tableData) &&
						tableData.map((value, index) => {		
							let userRecordForRow = {
								"uid": value.uid,
								"email": value.email,
								"displayName": value.displayName,
								"inspectorDescription": value.inspectorDescription,
								"roles": [],
								"inspectorRole": (value.roles.includes('Inspector')) ? "Yes": "No",
								"officeRole": (value.roles.includes('Office')) ? "Yes": "No",
								"masterRole": (value.roles.includes('Master')) ? "Yes": "No"
							}
							return (
							<tr role="row" key={index}>
								<td role="cell">{value.uid}</td>
								<td role="cell">{value.displayName}</td>
								<td role="cell">{value.email}</td>
								<td role="cell" style={{textAlign:'center', verticalAlign: 'center'}}>
									{value.roles.includes('Inspector') && <i className="fa fa-check-circle" style={{color: 'black'}}></i>}
								</td>
								<td role="cell" style={{textAlign:'center', verticalAlign: 'center'}}>
									{value.roles.includes('Office') && <i className="fa fa-check-circle" style={{color: 'black'}}></i>}
								</td>
								<td role="cell" style={{textAlign:'center', verticalAlign: 'center'}}>
									{value.roles.includes('Master') && <i className="fa fa-check-circle" style={{color: 'black'}}></i>}
								</td>
								<td role="cell" width="40px">
									<UserTableButton
										iconName={"pencil"}
										tooltipText={"Edit user profile"}
										backgroundColour={"warning"}
										onClick={() => {
											setUserProfileModalAction('Update');
											setUserRecordForModal(userRecordForRow);
											setUserProfileModalVisible(true);
										}}
									/>
								</td>
								<td role="cell" style={{ width: "40px" }}>
									<UserTableButton
										iconName={"envelope"}
										backgroundColour={"info"}
										tooltipText={"Send password reset email. Make sure user checks their junk folder!"}
										onClick={() => {
											triggerResetEmail(value.email);											
										}}
									/>
								</td>
								<td role="cell" style={{ width: "40px" }}>
									<UserTableButton
										iconName={"trash"}
										backgroundColour={"danger"}
										tooltipText={"Delete user"}
										onClick={() => {
											deleteUser(value.uid);
										}}
									/>
								</td>
							</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
	};

	return( 
		<>
			<UserProfileModal 
				userProfileModalAction={userProfileModalAction} 
				visible={userProfileModalVisible} 
				userRecordForModal={userRecordForModal} 
				onClose={() => { setUserProfileModalVisible(false) }} 
				setLoadingModalVisible={setLoadingModalVisible} 
				setConfirmationModalVisible={setConfirmationModalVisible} 
				returnAndLoadUsers={getUserRecordList} />
			
			<div className="d-flex mt-4">
				<button className="btn btn-success btn-rounded btn-sm m-0" type="button" onClick={() => { 
					setUserProfileModalAction('Create'); 
					setUserRecordForModal(blankUserRecord); 
					setUserProfileModalVisible(true);
					}}>
					<i className="fa fa-plus-circle"></i>
					<span> Create user</span>
				</button>
			</div>
			<br />
			{showTable()}
		</>
	)
};

export default UserTable;
