import React, { useState, useEffect, useRef, useContext } from "react";
import Label from "react-bootstrap/Modal";

import { getAuth, sendPasswordResetEmail } from "@firebase/auth";

import { postRequest } from "../../../helpers/apiHelper";

// Style components
import "./index.css";
import JobTableButton from "../jobTable/JobTableButton";


const AgentTable = ({
	tableData,
	setLoadingModalVisible,
	setConfirmationModalVisible,
	setRecordForModal,
	setCreateAgentModalVisible,
	getRecordList
}) => {
	
	useEffect(() => {
		console.log(tableData)
	}, []);

	const deleteAgent = async (record) => {
		//Ensure loading modal is displayed
		setLoadingModalVisible(true);

		//Uses the same 
		await postRequest("/deleteAgent", {record: record});

		//Refresh the table
		setTimeout(() => {
			getRecordList();
		}, 1000);
	}
	  
	const showTable = () => {

		console.log(tableData)
		if (tableData === null || tableData === []) {
			return (
				<div className="text-center">
				<Label tag="h5">An error has occurred</Label>
				</div>
			);
		} else {
			return (
				<table role="table" className="portalTable">
					<thead>
						<tr role="row">
							<th role="columnheader">Agent Full Name</th>
							<th role="columnheader">agent Email Address</th>
							<th role="columnheader">Agent Phone Number</th>
							<th role="columnheader"></th>
						</tr>
					</thead>
					<tbody role="rowgroup">
						{Array.isArray(tableData) &&
						tableData.map((value, index) => {
							return (
							<tr role="row" key={index}>
								<td role="cell">{value.agentFullName}</td>
								<td role="cell">{value.agentEmailAddress}</td>
								<td role="cell">{value.agentPhoneNumber}</td>
								<td role="cell">									
									<JobTableButton
										iconName={"trash"}
										backgroundColour={"danger"}
										tooltipText={"Delete agent"}
										onClick={() => {
											deleteAgent(value);											
										}}
									/>
								</td>
							</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
	};

	return <>{showTable()}</>;
};

export default AgentTable;
