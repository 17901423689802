import React, { useState, useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';


//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import { LoadingModal } from "../../../components/portal";
import JobForm from "../../../views/jobForm";

//Import helpers
import { postRequest, postRequestWithoutToken } from "../../../helpers/apiHelper";
import { useParams, useSearchParams } from "react-router-dom";

const EditReport = (props) => {
	const editMode = props.editMode;
	// const params = useParams()
	const [params] = useSearchParams();
	const [inspectionJobRecord, setInspectionJobRecord] = useState({});
	const [reportJobID, setReportJobID] = useState(null);
	const [loadingModalVisible, setLoadingModalVisible] = useState(false);

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		console.log('Edit mode: ' + editMode)

		const getInspectionJobRecord = async () => {
			const inspectionJobRecord = await postRequestWithoutToken(
			  "/getInspectionJobRecordWithReportToken",
			  { reportToken: params.get("reportToken") }
			);
	  
			inspectionJobRecord && setInspectionJobRecord(inspectionJobRecord[0]);
			try {
				//Store the inspectionObjString on page load (this is a once off function)				
				setReportJobID(inspectionJobRecord[0].jobID);

			} catch (e) {console.log(e)}
		};

		getInspectionJobRecord();
  	}, []);

	const saveEditedJob = async () => {
		//Set the loading modal to be visible (via parent component)
		setLoadingModalVisible(true);
		
		let inspectionObjString = localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`);
		
		let inspectionObj = JSON.parse(inspectionObjString);
		if(inspectionObj.items.length == 0 || !inspectionObj.hasOwnProperty('SWMSObj')) {
			alert('Error with edited report, no items found, any edits will not be saved. Please contact your administrator if this issue is recurring.')
		} else {
			//Update via post Request
			await postRequest("/uploadInspectionObjString", {
				jobID: reportJobID,
				inspectionObjString: inspectionObjString,
			});
	
			await postRequest("/updateJobStatus", {
				jobID: reportJobID,
				newStatus: "Edited",
			});
	
			setLoadingModalVisible(false);
		}

	}

	const getFrameSrc = () => {
		//Before getting frame source, clean the local storage
		//NOTE - MUST MAKE SURE THAT THE CURRENT JOBID IS NOT CLEARED FROM CACHE AS PAGE RELOADS EACH TIME THE JOB IS SAVED
		Object.keys(localStorage)
			.filter(x =>
				x.startsWith('reportEditor') && x !== 'reportEditor'+inspectionJobRecord.jobID)
			.forEach(x => 
				localStorage.removeItem(x))

		if(inspectionJobRecord.inspectionClass === 'Construction inspections') {
			return `./editReport/construction?reportToken=${params.get("reportToken")}`
		}
		if(inspectionJobRecord.inspectionClass === 'Dilapidation inspections') {
			return `./editReport/dilapidation?reportToken=${params.get("reportToken")}`
		}
		if(inspectionJobRecord.inspectionClass === 'Building inspections') {
			return `./editReport/building?reportToken=${params.get("reportToken")}`
		}
		if(inspectionJobRecord.inspectionClass === 'General building inspections') {
			return `./editReport/generalBuilding?reportToken=${params.get("reportToken")}`
		}
		if(inspectionJobRecord.inspectionClass === 'Post construction inspections') {
			return `./editReport/postConstruction?reportToken=${params.get("reportToken")}`
		}
		if(inspectionJobRecord.inspectionClass === 'Pre settlement inspections') {
			return `./editReport/preSettlement?reportToken=${params.get("reportToken")}`
		}
	}

	return (
		<>	
			<LoadingModal visible={loadingModalVisible} />
			<ViewHeaderTitle title={"Edit report"} />		
			<div style={{textAlign:'center'}}>
				<button type="button"
					className="btn btn-success rounded ml-2"
					onClick={() => saveEditedJob()}>
					Save edits
				</button>
				<br/>
				<span>Please make sure you press save edits before you leave the page.</span>
				{/*
					<p>New inspectionObj:{liveInspectionObj && JSON.stringify(liveInspectionObj)}</p>
					<p>Old inspectionObj:{inspectionObj && JSON.stringify(inspectionObj)}</p>
				*/}
				<div style={{padding:10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<iframe
						id={"reportFrame"}
						height={"1123px"}
						width={"815px"}
						title={"Report editor"}
						style={{ display: "block", margin: "10 auto" }}
						src={getFrameSrc()}
					/>
					
				</div>

			</div>
		</>
	);
};

export default EditReport;
