import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const PropertySummary = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    editMode,
    sectionHeaderArray,
    sectionHeaderIndex,
    signedURLArray
}) => {

    const [livePropertyDescription, setLivePropertyDescription] = useState("");
    
    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        //console.log(inspectionObj)
        setLivePropertyDescription(inspectionObj.propertyDescription)
    }, [inspectionObj]);

    const handleUpdate = (value) => {
        //console.log(value);
        setLivePropertyDescription(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));

        newInspectionObj.propertyDescription = value;
        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
        
    };

    return (
        <>
            <div className="a4Page standardPage">
                <Header
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
                <NavBar
                    activeSection={sectionHeaderArray[sectionHeaderIndex]}
                    sectionArray={sectionHeaderArray}
                />
                    <div className="p-4">
                        <div className="sectionHeader">
                            <span>Property Summary</span>
                        </div>
                        <table className="propertyDescriptionTable">
                            <tbody>
                                <tr>
                                    <th>
                                        <i className="fa fa-comments"></i>{" "}
                                        Property description
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        {(inspectionJobRecord.originalInspectionJobID) > 0 && (
                                            <><span><b>Please refer to previous report with Job ID:</b></span> <span><b>{inspectionJobRecord.originalInspectionJobID}.</b></span></>
                                        )}
                                        {editMode && (
                                            <textarea
                                                name="livePropertyDescription"
                                                rows="8"
                                                onChange={(e) => {
                                                    handleUpdate(e.target.value);
                                                }}
                                                value={livePropertyDescription}
                                            >
                                            </textarea>
                                        )}

                                        {!editMode && (
                                            <span>
                                                {inspectionObj && inspectionObj.propertyDescription}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="propertyImageTable">
                            <tbody>
                                <tr>
                                    <th>
                                        <i className="fa fa-camera"></i>{" "}
                                        Property image
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        {(inspectionObj && Array.isArray(inspectionObj.propertyPhotos)) &&                                            
                                            <Photo
                                                inspectorUserID={inspectionJobRecord.inspectorUserID}
                                                jobID={inspectionJobRecord.jobID}
                                                fileName={inspectionObj.propertyPhotos[0]}
                                                classString={"propertyImage landscapePhoto"}
                                                signedURLArray={signedURLArray}
                                            />                                                                                     
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <Footer
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
            </div>
        </>
    );
};

export default PropertySummary;