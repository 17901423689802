import { Formik, Form, Field, ErrorMessage } from "formik";

import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import DatePicker from "react-datepicker";
import locale from 'date-fns/locale/en-GB'
import "react-datepicker/dist/react-datepicker.css";

import { momentFormate } from "../../helpers/generalHelper";
//Import components 
import InputGroupDropdown from "./jobForm/InputGroupDropdown";
import moment from "moment";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";
import ScheduleGroupDropdown from "./schedule/ScheduleGroupDropdown";


const ScheduleModalAppointment = ({ appointmentRecordForModal, action, visible, onClose, setLoadingModalVisible, setConfirmationModalVisible, returnAndLoadSchedule }) => {

    const appointmentTypeOptions = [
        { value: 'Personal leave', label: 'Personal leave' },
        { value: 'Holiday', label: 'Holiday' },
        { value: 'Unavailable', label: 'Unavailable' }
    ]

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const submitbtn = useRef(null);

    const [isForm, setIsFormChange] = useState(false);

    const [userID, setuserID] = useState(null);

    const [userIDOptions, setuserIDOptions] = useState([{ value: '', label: 'Select' }]);
    const [inspectorObjArray, setInspectorObjArray] = useState([]);

	const deleteAppointment = async (appointmentID) => {
		setLoadingModalVisible(true);

		const response = await postRequestReturnAll("/deletePrivateAppointment", {appointmentID: appointmentID});
		// console.log(response, 'response')
		setLoadingModalVisible(false);
		if (response.status === 200 || response.status === 201 || response.status === 202) {
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadSchedule() }, 2000);

		} else {
			//Show error
			console.log('An error has occurred when deleting an appointment')
		}
	};

    const showTable = () => {
        const [form, setForm] = useState({
            initialValues: {
                appointmentID: '',
                userID: '',
                appointmentType: 'Personal leave',
                appointmentStartDate: momentFormate(startDate),
                appointmentEndDate: momentFormate(startDate),
                appointmentStartTime: '00:00',
                appointmentEndTime: '23:59'
            },
        })

        useEffect(() => {
            const getInspectorUserObjArray = async () => {
                const inspectorObjArray = await postRequest("/getInspectorUserObjArray");
                setInspectorObjArray(inspectorObjArray);
                let tempInspectorObjArray = []
                inspectorObjArray.forEach((inspectorObj) => {
                    tempInspectorObjArray.push({ value: inspectorObj.uid, label: inspectorObj.displayName })
                })
                setuserIDOptions(tempInspectorObjArray);
            }

            getInspectorUserObjArray();

        }, []);


        useMemo(() => {

            if (appointmentRecordForModal && Object.keys(appointmentRecordForModal).length !== 0) {

                const sd = new Date(appointmentRecordForModal.appointmentStartDate.split('-').reverse().join("-"))
                const ed = new Date(appointmentRecordForModal.appointmentEndDate.split('-').reverse().join("-"))

                setStartDate(sd)
                setEndDate(ed);
                
                // setFormValue(appointmentRecordForModal, sd, ed);

                setForm({
                    initialValues: {
                        ...form.initialValues,
                        appointmentID: appointmentRecordForModal.appointmentID,
                        appointmentType: appointmentRecordForModal.appointmentType,
                        userID: appointmentRecordForModal.userID,
                        appointmentStartDate: momentFormate(sd),
                        appointmentEndDate: momentFormate(ed),
                        appointmentStartTime: appointmentRecordForModal.appointmentStartTime,
                        appointmentEndTime: appointmentRecordForModal.appointmentEndTime
                    }
                })
            }else{

                setForm({
                    initialValues: {
                        appointmentID: '',
                        userID: '',
                        appointmentType: 'Personal leave',
                        appointmentStartDate: momentFormate(startDate),
                        appointmentEndDate: momentFormate(startDate),
                        appointmentStartTime: '00:00',
                        appointmentEndTime: '23:59'
                    },
                })
            }

        }, [appointmentRecordForModal]);

       

        const submitForm = async (values) => {
            setLoadingModalVisible(true);
            console.log('Form values:>>>>>>>>>> ', values)
            setForm(form)

            let response;

            if(appointmentRecordForModal && Object.keys(appointmentRecordForModal).length !== 0){

                response = await postRequestReturnAll("/updatePrivateAppointment", values);

            }else{

                 response = await postRequestReturnAll("/createPrivateAppointment", values);
            }
            // console.log(response, 'response')
            setLoadingModalVisible(false);
            if (response.status === 200 || response.status === 201 || response.status === 202) {
                //Job successfully created / updated
                setConfirmationModalVisible(true);
                setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadSchedule() }, 2000);

            } else {
                //Show error
                console.log('An error has occurred when submitting an appointment to the server')
            }
        };



        return (
            <>
                <Formik initialValues={form.initialValues}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm()
                    }}>

                    {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <Form name='appointment' onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                            return false
                        }}>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentID">Appointment ID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="appointmentID"
                                            name="appointmentID"
                                            value={values.appointmentID}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="col">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="userID">User ID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="userID"
                                            value={values.userID}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentType">Appointment type<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="appointmentType"
                                            value={values.appointmentType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentStartDate">Appointment start date<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="appointmentStartDate"
                                            value={values.appointmentStartDate}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentEndDate">Appointment end date<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="appointmentEndDate"
                                            value={values.appointmentEndDate}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentStartTime">Start Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="appointmentStartTime"
                                            value={values.appointmentStartTime}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentEndTime">End Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="appointmentEndTime"
                                            value={values.appointmentEndTime}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>

                                <button className="d-none" type="submit" ref={submitbtn}>submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>Scheduler - Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showTable()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleModalAppointment;
