import React, { useState, useEffect } from "react";
import "./../../../src/assets/stylesheets/report/generalReport.css";
import "./../../../src/assets/stylesheets/jobSheet.css";
import { useParams, useSearchParams } from "react-router-dom";

//Import components
import Header from "./Header";
import Footer from "./Footer";

//Import helpers
import {
  postRequest,
  postRequestWithoutToken,
} from "./../../helpers/apiHelper";

const Report = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [reportToken, setReportToken] = useState({});
  const [inspectionJobRecord, setInspectionJobRecord] = useState({});
  const [organisationConfigRecord, setOrganisationConfigRecord] = useState({});
  const [inspectionObj, setInspectionObj] = useState({});
  const [propertyOverviewPhotos, setPropertyOverviewPhotos] = useState([]);
  const [inspectionNotesObj, setInspectionNotesObj] = useState({});
  const [inspectionItems, setInspectionItems] = useState([]);
  const [allAdditionalIssuePhotos, setAllAdditionalIssuePhotos] = useState([]);

  const [params] = useSearchParams();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const getOrganisationConfigRecord = async () => {
      const organisationConfigRecord = await postRequestWithoutToken(
        "/getOrganisationConfigRecordWithReportToken",
        { reportToken: params.get("reportToken") }
      );

      organisationConfigRecord &&
        setOrganisationConfigRecord(organisationConfigRecord[0]);
    };
    getOrganisationConfigRecord();
    const indexInspectionItems = async (inspectionItems) => {
      for (var i = 0; i < inspectionItems.length; i++) {
        inspectionItems.index = i + 1;
      }
      return inspectionItems;
    };
    const getInspectionJobRecord = async () => {
      const inspectionJobRecordResponse = await postRequestWithoutToken(
        "/getInspectionJobRecordWithReportToken",
        { reportToken: params.get("reportToken") }
      );

      inspectionJobRecordResponse && setInspectionJobRecord(inspectionJobRecordResponse[0]);
      try {
        let inspectionObj = JSON.parse(
          inspectionJobRecordResponse[0].inspectionObjString
        );
        console.log(inspectionJobRecordResponse[0]);
        setInspectionObj(inspectionObj);
        //console.log(inspectionObj);
        setPropertyOverviewPhotos(inspectionObj.propertyOverviewPhotos);
        //Index the items in order before setting the variable
        let inspectionItems = inspectionObj.items;
        let indexedInspectionItems = await indexInspectionItems(
          inspectionItems
        );
        setInspectionItems(indexedInspectionItems);
      } catch (e) {}
    };
    getInspectionJobRecord();
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      {/*Page 1*/}
      <div className="a4Page standardPage">
        <Header
          organisationConfigRecord={organisationConfigRecord}
          inspectionJobRecord={inspectionJobRecord}
          pageNumber={"1"}
        />
        <div style={{ padding: "5mm" }}>
          <div className="summaryHeader">
            <span>Job sheet</span>
          </div>
          <br />
          <div className="statusTableMasterContainer">
            <table className="jobSheetTableRed">
              <tbody>
                <tr>
                  <th colSpan="2">
                    <i className="fa fa-folder"></i> Job identifiers
                  </th>
                </tr>
                <tr>
                  <td>
                    <span>Job ID - Job status</span>
                  </td>
                  <td>
                    <span id="jobID">{inspectionJobRecord.jobID} - {inspectionJobRecord.jobStatus}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Office UserID</span>
                  </td>
                  <td>
                    <span id="officeUserID">{inspectionJobRecord.officeUserID}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Inspector</span>
                  </td>
                  <td>
                    <span id="inspectorUserID">{inspectionJobRecord.inspectorUserID}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Inspection type</span>
                  </td>
                  <td>
                    <span id="inspectionType">{inspectionJobRecord.inspectionType}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Original Job ID</span>
                  </td>
                  <td>
                    <span id="originalInspectionJobID">{inspectionJobRecord.originalInspectionJobID}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="jobSheetTableRed">
              <tbody>
                <tr>
                  <th colSpan="2">
                    <i className="fa fa-folder"></i> Inspection status
                  </th>
                </tr>
                <tr>
                  <td>
                    <span>Agreed payment</span>
                  </td>
                  <td>
                    <span></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Report saved</span>
                  </td>
                  <td>
                    <span></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Payment received</span>
                  </td>
                  <td>
                    <span></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Report sent to client</span>
                  </td>
                  <td>
                    <span></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <table className="jobSheetTable">
            <tbody>
              <tr>
                <th colSpan="2">
                  <i className="fa fa-folder"></i> Inspection details
                </th>
              </tr>
              <tr>
                <td>
                  <span>Inspection address</span>
                </td>
                <td>
                  <span id="inspectionAddress">
                  {inspectionJobRecord.propertyAddress}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Inspection date and time</span>
                </td>
                <td>
                  <span id="inspectionDateTime">
                    Date: {inspectionJobRecord.inspectionDate}, Inspection time: {inspectionJobRecord.inspectionStartTime} to {inspectionJobRecord.inspectionEndTime}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="jobSheetTable">
            <tbody>
              <tr>
                <th colSpan="2">
                  <i className="fa fa-address-card-o"></i> Client information
                </th>
              </tr>
              <tr>
                <td>
                  <span>Client name</span>
                </td>
                <td>
                  <span id="clientFullName">{inspectionJobRecord.clientFullName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Corporate org.</span>
                </td>
                <td>
                  <span id="corporateOrganisation">{inspectionJobRecord.corporateOrganisation} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client phone number</span>
                </td>
                <td>
                  <span id="clientPhoneNumber">{inspectionJobRecord.clientPhoneNumber} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client email address</span>
                </td>
                <td>
                  <span id="clientEmailAddress">{inspectionJobRecord.clientEmailAddress} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client postal address</span>
                </td>
                <td>
                  <span id="clientPostalAddress">
                  {inspectionJobRecord.clientPostalAddress} 
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client 2 name</span>
                </td>
                <td>
                  <span id="client2FullName">{inspectionJobRecord.client2FullName} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client 2 phone number</span>
                </td>
                <td>
                  <span id="client2PhoneNumber">{inspectionJobRecord.client2PhoneNumber} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Client 2 email address</span>
                </td>
                <td>
                  <span id="client2EmailAddress">{inspectionJobRecord.client2EmailAddress} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Agency name</span>
                </td>
                <td>
                  <span id="agencyName">{inspectionJobRecord.agencyName} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Agent name</span>
                </td>
                <td>
                  <span id="agentFullName">{inspectionJobRecord.agentFullName} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Agent phone number</span>
                </td>
                <td>
                  <span id="agentPhoneNumber">{inspectionJobRecord.agentPhoneNumber} </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Agent email address</span>
                </td>
                <td>
                  <span id="agentEmailAddress">
                  {inspectionJobRecord.agentEmailAddress} 
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="jobSheetTable4Columns">
            <tbody>
              <tr>
                <th colSpan="4">
                  <i className="fa fa-bank"></i> Job financials
                </th>
              </tr>
              <tr>
                <td>
                  <span>Total fee</span>
                </td>
                <td>
                  <span id="inspectionPriceTotal">{inspectionJobRecord.inspectionPriceTotal}</span>
                </td>
                <td>
                  <span>Travel fee</span>
                </td>
                <td>
                  <span id="inspectionPriceTravelFee">{inspectionJobRecord.inspectionPriceTravelFee}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Inspector fee</span>
                </td>
                <td>
                  <span id="inspectionPriceInspectorFee">{inspectionJobRecord.inspectionPriceInspectorFee}</span>
                </td>
                <td>
                  <span>Franchise fee</span>
                </td>
                <td>
                  <span id="inspectionPriceFranchiseFee">{inspectionJobRecord.inspectionPriceFranchiseFee}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Pest inspection?</span>
                </td>
                <td>
                  <span id="pestFlag">{inspectionJobRecord.pestFlag}</span>
                </td>
                <td>
                  <span>Pest fee</span>
                </td>
                <td>
                  <span id="inspectionPricePestFee">{inspectionJobRecord.inspectionPricePestFee}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer
          organisationConfigRecord={organisationConfigRecord}
          inspectionJobRecord={inspectionJobRecord}
        />
      </div>

      {/*Page 2*/}
      <div className="a4Page standardPage">
        <Header
          organisationConfigRecord={organisationConfigRecord}
          inspectionJobRecord={inspectionJobRecord}
          pageNumber={"2"}
        />
        <div style={{ padding: "5mm" }}>
          <div className="summaryHeader">
            <span>Job sheet</span>
          </div>
          <br />
          <table className="inspectionNotesTable">
            <tbody>
              <tr>
                <th>
                  <i className="fa fa-folder"></i> Booking description
                </th>
              </tr>
              <tr>
                <td>
                  <span id="bookingNotes">{inspectionJobRecord.propertyBookingDescription}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="inspectionNotesTable">
            <tbody>
              <tr>
                <th>
                  <i className="fa fa-folder"></i> Access notes
                </th>
              </tr>
              <tr>
                <td>
                  <span id="accessNotes">{inspectionJobRecord.propertyAccessNotes}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer
          organisationConfigRecord={organisationConfigRecord}
          inspectionJobRecord={inspectionJobRecord}
        />
      </div>
    </div>
  );
};

export default Report;