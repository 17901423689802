import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";


const NonIssueItems = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
	pageItemObjArray,
    editMode,
	signedURLArray
}) => {

    const [liveInspectionObj, setLiveInspectionObj] = useState({});
    const [livePageItemsArray, setLivePageItemsArray] = useState({});
    
    // Wait for the inspectionObj to update, if it does, update the items value
    useEffect(() => {
		console.log(pageItemObjArray)
        setLivePageItemsArray(pageItemObjArray)
    }, [pageItemObjArray]);
	
    useEffect(() => {
        setLiveInspectionObj(inspectionObj)
    }, [inspectionObj]);

    const handleUpdate = (pageItemObj, newCommentValue) => {
        //console.log(pageItemObj.itemID + ': ' + newCommentValue);
		console.log(livePageItemsArray)
		let newPageItemObj = {...pageItemObj};
		newPageItemObj.itemComments = newCommentValue;
		console.log(newPageItemObj)


		//Update the limited pageItemObjArray
		let newPageItemObjArray = livePageItemsArray.map(existingPageItemObj => {
			return newPageItemObj.itemID === existingPageItemObj.itemID ? newPageItemObj : existingPageItemObj;			
		});
        setLivePageItemsArray(newPageItemObjArray);

        //Update the async storage value    
		let newInspectionObjString = localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`);
		let newInspectionObj = JSON.parse(newInspectionObjString);
		let allItemsArray = newInspectionObj.items
		let newAllItemsArray = allItemsArray.map(existingItemObj => {
			return newPageItemObj.itemID === existingItemObj.itemID ? newPageItemObj : existingItemObj;		
		});
		newInspectionObj.items = newAllItemsArray;
		console.log(newInspectionObj)
        setLiveInspectionObj(newInspectionObj);


        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

	const getItemTable = (itemObjArrayIndex) => {
		let componentJSX = [];
		let itemObj = pageItemObjArray[itemObjArrayIndex];
		if (itemObj !== undefined && itemObj !== null && itemObj !== '') {
			componentJSX.push(
				<div className="itemImages">
					<table className="reportItemPhotoTable">
						<tbody>
							<tr>
								<th colSpan="4">Item passed: {itemObj.itemIndex} {itemObj.itemName}</th>
							</tr>
							{editMode && livePageItemsArray[itemObjArrayIndex] && (
								<tr>
									<td colSpan="4" align="left">
										{livePageItemsArray[itemObjArrayIndex].itemTest &&
											<><span><strong>Item test:</strong> {livePageItemsArray[itemObjArrayIndex].itemTest}</span><br/></>
										} 
										<textarea
											rows="1"
											onChange={(e) => {
												handleUpdate(itemObj, e.target.value);
											}}
											value={livePageItemsArray[itemObjArrayIndex].itemComments}
										>
										</textarea>
									</td>
								</tr>
							)}

							{!editMode && itemObj.itemComments.length > 0 && (
								<tr>
									<td colSpan="4" align="left">
										<strong>Item comments: </strong> {itemObj.itemComments}
									</td>
								</tr>
							)}
							<tr>
								<td style={{ textAlign: 'center' }}>
									<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemObj.itemPhotos[0]} classString={"cellImageItem landscapePhoto"} />       
								</td>
								<td style={{ textAlign: 'center' }}>
									<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemObj.itemPhotos[1]} classString={"cellImageItem landscapePhoto"} />       
								</td>
								<td style={{ textAlign: 'center' }}>
									<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemObj.itemPhotos[2]} classString={"cellImageItem landscapePhoto"} />       
								</td>
								<td style={{ textAlign: 'center' }}>
									<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={itemObj.itemPhotos[3]} classString={"cellImageItem landscapePhoto"} />       
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}
		return componentJSX;
	}

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar activeSection={"Inspection details"} sectionArray={["Summary", "Inspection issues", "Inspection details", "Appendix"]} />
				<div className="p-4">
					<div className="sectionHeader">
						<span>Passed inspection items</span>
					</div>
					<span className="">
						The following items were inspected, and passed the required test.
					</span>

					{getItemTable(0)}
					{getItemTable(1)}
					{getItemTable(2)}
					{getItemTable(3)}

				</div>

				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default NonIssueItems;