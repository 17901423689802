import React, { useState, useEffect } from "react";
import "./../../../src/assets/stylesheets/report/generalReport.css";

//Import components
import Header from "./Header";
import Footer from "./Footer";
import CoverPage from "./1CoverPage";
import PropertySummary from "./2PropertySummary";
import PropertyOverviewPhotos from "./3PropertyOverviewPhotos";
import ReportSummary from "./4ReportSummary";
import IssueFlags from "./5IssueFlagsBuilding";
import ItemSummary from "./6ItemSummary";
import Issues from "./7IssuePage";

//Import helpers
import {
  postRequest,
  postRequestWithoutToken,
} from "./../../helpers/apiHelper";

const Report = (props) => {
  const [inspectionJobRecord, setInspectionJobRecord] = useState({});
  const [organisationConfigRecord, setOrganisationConfigRecord] = useState({});
  const [inspectionObj, setInspectionObj] = useState({});
  const [propertyOverviewPhotos, setPropertyOverviewPhotos] = useState([]);
  const [inspectionNotesObj, setInspectionNotesObj] = useState({});

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const getOrganisationConfigRecord = async () => {
      const organisationConfigRecord = await postRequestWithoutToken(
        "/getOrganisationConfigRecord"
      );
      //   //console.log(organisationConfigRecord);
      organisationConfigRecord &&
        setOrganisationConfigRecord(organisationConfigRecord[0]);
    };
    getOrganisationConfigRecord();

    const getInspectionJobRecord = async () => {
      const inspectionJobRecord = await postRequestWithoutToken(
        "/getInspectionJobRecord",
        { jobID: 79334 }
      );
      //   //console.log(inspectionJobRecord);
      inspectionJobRecord && setInspectionJobRecord(inspectionJobRecord[0]);
      try {
        let inspectionObj = JSON.parse(
          inspectionJobRecord[0].inspectionObjString
        );
        //console.log(inspectionObj);
        //console.log(inspectionObj.propertyOverviewPhotos);
        setInspectionObj(inspectionObj);
        setPropertyOverviewPhotos(inspectionObj.propertyOverviewPhotos);
        setInspectionNotesObj(inspectionObj.inspectionNotesObj);
      } catch (e) {}
    };
    getInspectionJobRecord();
  }, []);

  return (
    <>
      {/*Cover page*/}
      <CoverPage
        organisationConfigRecord={organisationConfigRecord}
        inspectionJobRecord={inspectionJobRecord}
      />

      {/*Property summary photo*/}
      <PropertySummary
        organisationConfigRecord={organisationConfigRecord}
        inspectionJobRecord={inspectionJobRecord}
      />

      {/*Property overview photos - only if property photos exist*/}
      {propertyOverviewPhotos.length > 0 && (
        <PropertyOverviewPhotos
          propertyOverviewPhotos={propertyOverviewPhotos}
          organisationConfigRecord={organisationConfigRecord}
          inspectionJobRecord={inspectionJobRecord}
        />
      )}

      {/*Report summary*/}
      <ReportSummary
        organisationConfigRecord={organisationConfigRecord}
        inspectionJobRecord={inspectionJobRecord}
        inspectionObj={inspectionObj}
        inspectionNotesObj={inspectionNotesObj}
      />

      {/*Major alerts - Building only*/}

      {/*Item summary*/}
      <ItemSummary
        organisationConfigRecord={organisationConfigRecord}
        inspectionJobRecord={inspectionJobRecord}
        inspectionObj={inspectionObj}
        inspectionNotesObj={inspectionNotesObj}
      />
    </>
  );
};

export default Report;
