import React, { useEffect, useState } from "react";
import { FormFeedback } from "reactstrap";

const InputGroupText = ({ inputName, inputLabelName, required = false, disabled = false, touched, errors, values, handleBlur, handleChange, width="" }) => {

    return (
        <div className={`form-group ${width}`}>
            <label className="form-label" htmlFor={inputName}>
                {inputLabelName}
                {required &&
                    <span className="text-danger">*</span>
                }
            </label>
            <input
                invalid={touched[inputName] && errors[inputName]}
                className="form-control"
                type="text" onChange={handleChange} onBlur={handleBlur}
                disabled={disabled}
                placeholder={""}
                name={inputName}
                value={values[inputName]}
            />
            {
                touched[inputName] && errors[inputName] && (
                    <FormFeedback>
                        {errors[inputName]}
                    </FormFeedback>
                )
            }
        </div>
    );
};

export default InputGroupText;