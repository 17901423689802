import React, { useContext } from "react";

const NavBar = (props) => {

	const navBarItems = () => {
		let navBarItems = [];
		let sectionArray = props.sectionArray;
		if (sectionArray.length > 0) {
			//Iterate through the section array
			sectionArray.forEach((element, index) => {
				if (element === props.activeSection) {
					navBarItems.push(
						<div key={index} className="navBarItem navOn">
							{element}
						</div>
					)
				} else {
					navBarItems.push(
						<div key={index} className="navBarItem navOff">
							{element}
						</div>
					)
				}
			})
		}
		return navBarItems;
	}

	return (
		<>
			<div className="navBarSection">
				{navBarItems()}
			</div>
		</>
	);
};

export default NavBar;
