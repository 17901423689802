import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import MaintenanceBarChart from './9MaintenanceBarChart'

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0, 
	maximumFractionDigits: 0
});

const MaintenancePlanSummary = ({
    organisationConfigRecord,
    inspectionJobRecord,
	inspectionObj,
    sectionHeaderArray,
    sectionHeaderIndex,
	maintenanceIssuesArray
}) => {
	
    const [totalCost, setTotalCost] = useState(0);
    const [totalCostNow, setTotalCostNow] = useState(0);
    const [totalCostWithin1Year, setTotalCostWithin1Year] = useState(0);
    const [totalCostWithin2Year, setTotalCostWithin2Year] = useState(0);
    const [totalCostWithin3Year, setTotalCostWithin3Year] = useState(0);
    const [totalCostAnnualMaintenance, setTotalCostAnnualMaintenance] = useState(0);


    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
		console.log(maintenanceIssuesArray);

		let tempTotalCost = 0;
		let tempTotalCostNow = 0;
		let tempTotalCostWithin1Year = 0;
		let tempTotalCostWithin2Year = 0;
		let tempTotalCostWithin3Year = 0;
		let tempTotalCostAnnualMaintenance = 0;
		
		for (const maintenanceItem of maintenanceIssuesArray) {
			tempTotalCost += maintenanceItem.issueMaintenanceCost
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Now') { tempTotalCostNow += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 1 year') { tempTotalCostWithin1Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 2 years') { tempTotalCostWithin2Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == 'Within 3 years') { tempTotalCostWithin3Year += maintenanceItem.issueMaintenanceCost }
			if(maintenanceItem.issueMaintenanceCostTimeline == "Annual cost") { tempTotalCostAnnualMaintenance += maintenanceItem.issueMaintenanceCost }
		}
		setTotalCost(tempTotalCost);
		setTotalCostNow(tempTotalCostNow);
		setTotalCostWithin1Year(tempTotalCostWithin1Year);
		setTotalCostWithin2Year(tempTotalCostWithin2Year);
		setTotalCostWithin3Year(tempTotalCostWithin3Year);
		setTotalCostAnnualMaintenance(tempTotalCostAnnualMaintenance);
    }, [maintenanceIssuesArray]);

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar activeSection={"Inspection details"} sectionArray={["Summary", "Inspection issues", "Inspection details", "Appendix"]} />
				<div className="p-4">
					<div className="sectionHeader">
						<span>Maintenance plan</span>
					</div>
					
					<span className="">
						<strong>Estimated costs</strong>
					</span>
					<br/>
					<table className="maintenancePlanSummaryTable">
						<tr>
							<th align="left" colSpan="8">Cost estimates</th>
						</tr>
						<tr>
							<td>Total Est. cost</td>
							<td>Now</td>
							<td>Within 1 year</td>
							<td>Within 2 years</td>
							<td>Within 3 years</td>
							<td>Annual maint.</td>
						</tr>
						<tr>
							<td>{formatter.format(totalCost)}</td>
							<td>{formatter.format(totalCostNow)}</td>
							<td>{formatter.format(totalCostWithin1Year)}</td>
							<td>{formatter.format(totalCostWithin2Year)}</td>
							<td>{formatter.format(totalCostWithin3Year)}</td>
							<td>{formatter.format(totalCostAnnualMaintenance)}</td>
						</tr>
					</table>
					<br/>
					<br/>
					<div className="maintenanceBarChartContainer">
						<MaintenanceBarChart 
							data= {[
							  {
								name: 'Now',
								periodCost: totalCostNow,
								cumulativeCost: totalCostNow
							  },
							  {
								name: 'Within 1 year',
								periodCost: totalCostWithin1Year,
								cumulativeCost: totalCostNow + totalCostWithin1Year
							  },
							  {
								name: 'Within 2 years',
								periodCost: totalCostWithin2Year,
								cumulativeCost: totalCostNow + totalCostWithin1Year + totalCostWithin2Year
							  },
							  {
								name: 'Within 3 years',
								periodCost: totalCostWithin3Year,
								cumulativeCost: totalCostNow + totalCostWithin1Year + totalCostWithin2Year + totalCostWithin3Year
							  },
							  {
								name: 'Annual maintenance',
								periodCost: totalCostAnnualMaintenance,
								cumulativeCost: totalCostNow + totalCostWithin1Year + totalCostWithin2Year + totalCostWithin3Year + totalCostAnnualMaintenance
							  }
							]}
						/>
					</div>


					<span className="">
						<strong>Estimated cost</strong>
					</span>
					<br/>
					<span className="">
						The indicative costs are a high level estimates provided by a Houspect Building Inspector who is a Registered Builder in WA. These estimate costs are simply an indicative estimate based on the Builders industry knowledge.  The costs are intended to be used for high level planning purposes only. To improve the costs estimate (and hence reduce the risk that the costs may differ from that estimated), Property Owners/Managers should consider either engaging a Quantity Surveyor or obtain 3 quotes from reputable firms capable of undertaking the required work. Indicative costs exclude call out fees. 
					</span>
					<br/>
					<br/>


					<span className="">
						<strong>Time of Action</strong> 
					</span>
					<br/>
					<span className="">
						The time for action is a high level indicative estimate only as to when the likely expenditure will be required to be incurred and is an estimate by a Houspect Inspector, who is a Registered Builder in WA. The estimate is simply an indicative estimate based on the Builders experience of how long the current item will last before remedial maintenance or replacement will be required. 
					</span>
					<br/>

				</div>

				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default MaintenancePlanSummary;