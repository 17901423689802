import React, { useEffect, useState } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const UserTableButton = ({
  iconName,
  tooltipText,
  backgroundColour,
  action,
  onClick,
}) => {
  return (
    <div className="d-flex gap-3">
      <div className="view-job">
        <OverlayTrigger
          overlay={(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
          placement="bottom"
        >
          {/* <a href="https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf" download>esdrfgn</a> */}
            <button
              type="button"
              onClick={onClick}
              className={"btn btn-sm btn-" + backgroundColour}
            >
              <i className={"fas fa-fw fa-" + iconName} />
            </button>
          
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default UserTableButton;
