import React, { useState, useEffect } from "react";

import "./../../../../src/assets/stylesheets/report/generalReport.css";
import { useSearchParams } from "react-router-dom";

//Import components
// import Header from "./../Header";
// import Footer from "./../Footer";
import CoverPage from "./../1CoverPage";
import PropertySummary from "./../2PropertySummary";
import PropertyOverviewPhotos from "./../3PropertyOverviewPhotos";
import ReportSummaryPreSettlement from "./../4ReportSummaryPreSettlement";
// import IssueFlags from "./../5IssueFlags";
import ItemSummaryPreSettlement from "./../6ItemSummaryPreSettlement";
import FailedItemPreSettlement from "../7FailedItemPreSettlement";
import PassedItemsPreSettlement from "../8PassedItemsPreSettlement";
import AdditionalIssuePhotos from "../9AdditionalIssuePhotos";
import Scope from "../10Scope";
import Conditions from "../10Conditions";

//Import helpers
import {
  // postRequest,
  postRequestWithoutToken,
} from "../../../helpers/apiHelper";

//Import scope sections
import { scopePagesArray } from "./scope";
import { conditionsPagesArray } from "./conditions";
import { ArraySchema } from "yup";
import { elementType } from "prop-types";

const sectionHeaderArray = [
	"Summary",
	"Inspection issues",
	"Inspection details",
	"Appendix",
]

const Report = (props) => {
	const editMode = props.editMode;
	const [loading, setLoading] = useState(false);
	const [organisationConfigRecord, setOrganisationConfigRecord] = useState({});
	const [inspectionJobRecord, setInspectionJobRecord] = useState({});
	const [inspectionObj, setInspectionObj] = useState({});
	const [signedURLArray, setSignedURLArray] = useState([]);

	const [params] = useSearchParams();

	//Get organisation config record
	const getOrganisationConfigRecord = async () => {
		const organisationConfigRecord = await postRequestWithoutToken(
			"/getOrganisationConfigRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
		console.log(organisationConfigRecord)
		organisationConfigRecord &&
			setOrganisationConfigRecord(organisationConfigRecord[0]);
	};

	//Get the signed URL array
	const getSignedURLArray = async () => {
		const signedURLArrayResponse = await postRequestWithoutToken(
			"/getSignedURLForImageBulk",
			{ reportToken: params.get("reportToken") }
		);
		console.log(signedURLArrayResponse)
		
		signedURLArrayResponse &&
			setSignedURLArray(signedURLArrayResponse);			
	};

	//Get the inspection job record and index inspection items
	const indexInspectionItems = (itemOrderArray, inspectionJobRecordRaw) => {
		let inspectionObjRaw = JSON.parse(inspectionJobRecordRaw.inspectionObjString)
		let inspectionItems = inspectionObjRaw.items;

		inspectionItems.forEach(function (element, index) { 
			let itemIndex = itemOrderArray.findIndex(item => item.trim() === element.itemName.trim());

			//Item index will be -1 if item name no longer exists in config file. In case the inspection was done and an update to the configuration file was made. 
			if(itemIndex == -1) {
				inspectionItems[index].itemIndex = 10000
			} else {
				inspectionItems[index].itemIndex = itemIndex;
			}
		})
		
		let inspectionItemsSorted = inspectionItems.sort(function(a,b){
			return a.itemIndex - b.itemIndex;
			}
		);
		
		let newInspectionObj = {...inspectionObjRaw}
		newInspectionObj.items = inspectionItemsSorted;
		let newInspectionJobRecord = {...inspectionJobRecordRaw}
		newInspectionJobRecord.inspectionObjString = JSON.stringify(newInspectionObj);

		console.log(newInspectionObj)

		setInspectionObj(newInspectionObj);		
		setInspectionJobRecord(newInspectionJobRecord);
        localStorage.setItem(`reportEditor${newInspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
	};

	const getInspectionJobRecord = async () => {
		const inspectionJobRecordResponse = await postRequestWithoutToken(
			"/getInspectionJobRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
	
		const itemOrderArray = await postRequestWithoutToken(
			"/getItemOrderForPreSettlementReport",
			{ reportToken: params.get("reportToken") }
		);
		

		try {
			let inspectionJobRecordRaw = inspectionJobRecordResponse[0];
			//Index the items in order before setting the variable
			indexInspectionItems(itemOrderArray, inspectionJobRecordRaw);
		} catch (e) {console.log(e)}
	};
		
  	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		console.log('Edit mode: ' + editMode)

		getOrganisationConfigRecord();
		getInspectionJobRecord();
		getSignedURLArray();

		setTimeout(function(){
			populatePageNumbers();
		}, 3000);

		
  	}, []);

	const populatePageNumbers = () => {
		var pageNumberElementArray = document.querySelectorAll(".pageNumber");
		let totalPages = pageNumberElementArray.length + 1; //Account for cover page
		pageNumberElementArray.forEach(function (element, index) { 
			element.innerHTML = `Page ${index+2} of ${totalPages}`	//Start with page 1 and cover page doesn't have a page number class
		})
	}

	
  const itemSummaryPages = () => {
	if (Array.isArray(inspectionObj.items)) {
		let inspectionItems = inspectionObj.items;
		let rowsPerPage = 25;
		let noOfItems = inspectionItems.length;
		let noOfIssues = 0;
		
		for (var i = 0; i < inspectionItems.length; i++) {
			let inspectionItemIssues = inspectionItems[i].itemIssues;
			if (inspectionItemIssues.length > 0) {
				noOfIssues += inspectionItemIssues.length - 1; //Subtract 1 for the overall item row
			}
		}
		let pagesRequired = Math.ceil((noOfItems + noOfIssues) / rowsPerPage);
		let itemSummaryPagesJSX = [];
		for (let page = 0; page < pagesRequired; page++) {
			let pageNumber = page;
			itemSummaryPagesJSX.push(
				<ItemSummaryPreSettlement
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={0}
					inspectionItems={inspectionItems}
					pageIndex={pageNumber}
					rowsPerPage={rowsPerPage}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					editMode={editMode}
					signedURLArray={signedURLArray}
				/>
			);
		}
		return itemSummaryPagesJSX;
    }
  };

  const failedItemPages = () => {
    let failedItemPagesJSX = [];
	if (Array.isArray(inspectionObj.items)) {
		let inspectionItems = inspectionObj.items;	
		for (var i = 0; i < inspectionItems.length; i++) {
			if (inspectionItems[i].itemTestResult == 'Fail') {
				failedItemPagesJSX.push(
					<FailedItemPreSettlement
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={1}
						editMode={editMode}
						itemIndex={i + 1}
						inspectionItem={inspectionItems[i]}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						signedURLArray={signedURLArray}
					/>
				);
			}
		}
		return failedItemPagesJSX;
    }
  };

	const passedItemPages = () => {
		let passedItemPagesJSX = [];
		if (Array.isArray(inspectionObj.items)) {
			let inspectionItems = inspectionObj.items;
			let pageItemObjArray = [];
			for (var i = 0; i < inspectionItems.length; i++) {
				let inspectionItem = inspectionItems[i];
				let itemIndex = i + 1;
				inspectionItem.itemIndex = itemIndex;
				if (inspectionItem.itemTestResult == "Pass") {
					pageItemObjArray.push(inspectionItem);
					if (pageItemObjArray.length === 4) {
						passedItemPagesJSX.push(
							<PassedItemsPreSettlement
								sectionHeaderArray={sectionHeaderArray}
								sectionHeaderIndex={2}
								editMode={editMode}
								pageItemObjArray={pageItemObjArray}
								organisationConfigRecord={organisationConfigRecord}
								inspectionJobRecord={inspectionJobRecord}
								inspectionObj={inspectionObj}
								signedURLArray={signedURLArray}
							/>
						);
						pageItemObjArray = [];
					}
				}
			}
			//Check for any remaining
			if (pageItemObjArray.length > 0) {
				passedItemPagesJSX.push(
					<PassedItemsPreSettlement
						editMode={editMode}
						pageItemObjArray={pageItemObjArray}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						signedURLArray={signedURLArray}
					/>
				);
				pageItemObjArray = [];
			}

			return passedItemPagesJSX;
		}
	};


	const scopePages = () => {
		let scopePagesJSX = []
		for (var i = 0; i < scopePagesArray.length; i++) {
			scopePagesJSX.push(				
				<Scope
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={scopePagesArray[i]}
					/>
			)
		};

		return scopePagesJSX;
	}

	const conditionsPages = () => {
		let conditionsPagesJSX = []
		for (var i = 0; i < conditionsPagesArray.length; i++) {
			conditionsPagesJSX.push(				
				<Conditions
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={conditionsPagesArray[i]}
					/>
			)
		};

		return conditionsPagesJSX;
	}
	



	if(loading) {
		return <></>
	} else {
		
		//Need to make sure signed URL array is ready 
		if(signedURLArray.length > 0 ) {
			return (
				<>
					{/*Cover page*/}
					<CoverPage
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
					/>

					{/*Property summary photo and property description*/}
					<PropertySummary
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						editMode={editMode}
						signedURLArray={signedURLArray}
					/>

					{/* <PropertySummary 
								organisationConfigRecord={organisationConfigRecord} 
								inspectionJobRecord={inspectionJobRecord} 
								inspectionObj={inspectionObj}
								editMode={editMode}/> */}

					{/*Property overview photos - only if property photos exist
					{inspectionObj.hasOwnProperty('propertyOverviewPhotos') && (
					<PropertyOverviewPhotos
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						propertyOverviewPhotos={inspectionObj.propertyOverviewPhotos}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						signedURLArray={signedURLArray}
						/>
					)} */}

					{/*Report summary*/}
					<ReportSummaryPreSettlement
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						reportPurpose={"The purpose of this report is to identify defects, faults or incomplete works in the construction of the building, insofar as a Registered Builder can reasonably and visually identify those defects, faults or incomplete works which might be expected to be present in a building at Practical Completion or Post Completion. The report must be read in full. This report is not intended to be an independent expert opinion report which might be required in litigation matters involving WA Building and Energy, WA State Administrative Tribunal or the WA Courts. Houspect can be separately engaged to produce an independent expert opinion report if required."}
						/>

					{/*Major alerts - Building only*/}

					{/*Item summary*/}
					{itemSummaryPages()}

					{/*Issues*/}
					{failedItemPages()}

					{/*Non issue items*/}
					{passedItemPages()}

					{/*Scope*/}
					{scopePages()}

					{/*Condtitions*/}
					{conditionsPages()}
				</>
			);
		}
	}
};

export default Report;