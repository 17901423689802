import React, { useEffect, useState } from "react";

import {FormFeedback, Input} from "reactstrap";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const InputGroupTextWithHint = ({ inputName, inputLabelName, hintTitle, hintMessage, required=false, touched, errors, values, handleBlur, handleChange }) => {
    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">{hintTitle}</Popover.Header>
          <Popover.Body>
            {hintMessage}
          </Popover.Body>
        </Popover>
    );
    return (
      <div className="form-group">
        <label className="form-label" htmlFor={inputName}>
            {inputLabelName}
            {required &&
                <span className="text-danger">*</span>
            }            
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <i className="info-tooltip fa fa-info-circle" type="button"
                    data-toggleclientmodal="popover" 
                    title={hintTitle}
                    data-content={hintMessage}/>
            </OverlayTrigger>
        </label>
        <Input
            invalid={touched[inputName] && errors[inputName]}
            className="form-control"
            type="text" onChange={handleChange} onBlur={handleBlur}
            placeholder={""} name={inputName}
            value={values[inputName]}
        />
        {
            touched.clientFullName && errors.clientFullName && (
                <FormFeedback>
                    {errors.clientFullName}
                </FormFeedback>
            )
        }
      </div>
  );
};

export default InputGroupTextWithHint;
