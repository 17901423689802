import moment from "moment";
import {
  calcTime,
  jobInpectionTime,
  searchFilter,
  searchStatusFilter,
} from "../utils/service";

export const filterJobs = (
  jobData,
  setJobData,
  jobTableData,
  setJobTableData,
  jobFilterStatusArray,
  jobFilterTimes,
  search,
  setSearch,
  setLoadingModalVisible
) => {

  	if (Array.isArray(jobFilterTimes)) {
		if (jobFilterStatusArray.length !== 0 && jobFilterTimes.length !== 0) {
			const filteredJobTableArray = jobData.filter((job) => {
				if (job.jobStatus !== undefined && job.status !== null) {
				return jobFilterStatusArray.includes(job.jobStatus);
				}
				return false;
			});
			setJobTableData(() =>
				search === ""
				? filteredJobTableArray
				: searchFilter(filteredJobTableArray, search)
			);

			setLoadingModalVisible(false);
			return;
		}
		setJobTableData([]);
  	}

	// Get today's date

	const date = new Date(moment().format("YYYY-MM-D")).getTime();

	if (jobFilterTimes.length <= 0 || jobFilterStatusArray <= 0) {
		setLoadingModalVisible(false);
		setJobTableData([]);
	}

	//** filter jobTable when time and status both are selected */
	else if (
		jobFilterStatusArray.length !== 0 &&
		jobFilterTimes.length !== 0
	) {

		if (jobFilterTimes === "Today") {
		const arr = jobData.filter((job) => {
			if (job.jobStatus !== undefined && job.status !== null) {
			return jobFilterStatusArray.includes(job.jobStatus);
			}
		})


		arr && setJobTableData(() => (searchFilter(arr.filter((job) => {
			if (job.inspectionDate) {
			return date === jobInpectionTime(job.inspectionDate);
			}
			return false
		}), search))

		);


		} else {
		if (jobFilterTimes === "8+ days ago") {

			const dateFrom = new Date(moment().subtract(8, "d").format("YYYY-MM-D")).getTime();
			const arr = jobData.filter((job) => {
			if (job.jobStatus !== undefined && job.status !== null) {
				return jobFilterStatusArray.includes(job.jobStatus);
			}
			})


			arr && setJobTableData(() => searchFilter(arr.filter((job) => {
			if (job.inspectionDate) {
				return jobInpectionTime(job.inspectionDate) <= dateFrom;
			}
			return false
			}), search))


		}

		else if (jobFilterTimes === "8+ days away") {
			const dateFrom = new Date(moment().add(8, "d").format("YYYY-MM-D")).getTime();
			const arr = jobData.filter((job) => {
			if (job.jobStatus !== undefined && job.status !== null) {
				return jobFilterStatusArray.includes(job.jobStatus);
			}
			})

			arr && setJobTableData(() => searchFilter(arr.filter((job) => {
			if (job.inspectionDate) {
				return jobInpectionTime(job.inspectionDate) >= dateFrom;
			}
			return false
			}), search))

		}
		if (jobFilterTimes === "Within last 7 days") {

			const dateFrom = new Date(moment().subtract(7, "d").format("YYYY-MM-D")).getTime();

			const arr = jobData.filter((job) => {
			if (job.jobStatus !== undefined && job.status !== null) {
				return jobFilterStatusArray.includes(job.jobStatus);
			}
			})

			arr && setJobTableData(() => searchFilter(arr.filter((job) => {
			if (job.inspectionDate) {
				return (date > jobInpectionTime(job.inspectionDate)) && (jobInpectionTime(job.inspectionDate) >= dateFrom);
			}
			return false
			}), search))

		}

		else if (jobFilterTimes === "Next 7 days") {

			const dateFrom = new Date(moment().add(7, "d").format("YYYY-MM-D")).getTime();

			const arr = jobData.filter((job) => {
			if (job.jobStatus !== undefined && job.status !== null) {
				return jobFilterStatusArray.includes(job.jobStatus);
			}
			})

			arr && setJobTableData(() => searchFilter(arr.filter((job) => {
			if (job.inspectionDate) {
				return (date <= jobInpectionTime(job.inspectionDate)) && (jobInpectionTime(job.inspectionDate) <= dateFrom);
			}
			return false
			}), search))
		}

		}
	
	
		setLoadingModalVisible(false);
	}
	setLoadingModalVisible(false);
};
