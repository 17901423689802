import React, { useEffect, useMemo, useRef, useState } from "react";

//Import local styling
import "./../../assets/stylesheets/sideBar.css";

//Import components
import SideBarDividerLine from "../../components/navigation/SideBarDividerLine";
import SideBarDividerHeader from "../../components/navigation/SideBarDividerHeader";
import SideBarMenuItem from "../../components/navigation/SideBarMenuItem";
import SideBarMenuItemPDF from "../../components/navigation/SideBarMenuItemPDF";
import Logo from "./../../assets/images/inspectAPP.png";

//Import helpers
import { postRequest } from "../../helpers/apiHelper";

const SideBar = () => {
	
	const [userRoles, setUserRoles] = useState(["Inspector"]); //Default to inspector visibility

	useEffect(() => {
		getUserRoles();
	}, []);

	const getUserRoles = async () => {		
		const userRoles = await postRequest("/getUserRoles", {});
		setUserRoles(userRoles)
	}

	const getSideBarPDF = (path) => {

		let base_url = window.location.origin;
		let fileURL = `${base_url}/${path}`;
		//console.log(fileURL)
		window.location = fileURL;
	}

  	return (
		<div
			className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
			id="accordionSidebar">
				
			<div className="p-4">
				<img src={Logo} className="sideBarLogo" alt="Logo" />
			</div>
			<SideBarDividerLine />

			<SideBarDividerHeader headerTitle={"JOB MANAGEMENT"} />
			<SideBarMenuItem
				to="/"
				menuTitle={"View jobs"}
				iconName={"list"}
			/>
			{userRoles.includes('Office') && (
				<SideBarMenuItem
					to="/createJob"
					menuTitle={"Create job"}
					iconName={"plus-circle"}
				/>
			)}
			{userRoles.includes('Master') && (
				<>
					<SideBarMenuItem
						to="/archiveJobs"
						menuTitle={"Archive jobs"}
						iconName={"archive"}
					/>
					<SideBarMenuItem
						to="/deleteJobs"
						menuTitle={"Delete jobs"}
						iconName={"trash"}
					/>
				</>
			)}
			<SideBarDividerLine />

			<SideBarDividerHeader headerTitle={"TEAM MANAGEMENT"} />
			<SideBarMenuItem
				to="/viewSchedule"
				menuTitle={"View schedule"}
				iconName={"calendar-week"}
			/>
			{userRoles.includes('Master') && (
				<>
					<SideBarMenuItem
						to="/viewUsers"
						menuTitle={"View users"}
						iconName={"users"}
					/>
				</>
			)}
			<SideBarDividerLine />

			{userRoles.includes('Office') && (
				<>
					<SideBarDividerHeader headerTitle={"REPORTING"} />
					<SideBarMenuItem
						to="/extractData"
						menuTitle={"Extract data"}
						iconName={"file-download"}
					/>
					<SideBarMenuItem
						to="/viewAgents"
						menuTitle={"View agents"}
						iconName={"address-card"}
					/>
					<SideBarMenuItem
						to="/viewAgencies"
						menuTitle={"View agencies"}
						iconName={"building"}
					/>
					<SideBarDividerLine />
				</>
			)}

			{userRoles.includes('Office') && (
				<>
					<SideBarDividerHeader headerTitle={"SETTINGS"} />
					<SideBarMenuItem
						to="/configuration"
						menuTitle={"Configuration"}
						iconName={"gear"}
					/>
					<SideBarDividerLine />
				</>
			)}

			<SideBarDividerHeader headerTitle={"MORE INFORMATION"} />
			<SideBarMenuItemPDF
				menuTitle={"Privacy policy"}
				iconName={"low-vision"}
				onClick={() => {getSideBarPDF("pdfs/privacyPolicy.pdf")}}
			/>
			<SideBarMenuItemPDF
				menuTitle={"Terms and conditions"}
				iconName={"list-alt"}
				onClick={() => {getSideBarPDF("pdfs/endUserAgreement.pdf")}}
			/>
			<SideBarDividerLine />
		</div>
  );
};

export default SideBar;
