import React, { useContext } from "react";

const Footer = (props) => {
  
  return (
    <>
      <div className="footerSection">
        <div className="footerSectionBar" />
        <div className="footerSectionBarText">
          <span className="m-1"><i className="fa fa-address-book"></i> {props.organisationConfigRecord.organisationAddress}</span>
          <span className="m-1"><i className="fa fa-phone"></i> {props.organisationConfigRecord.organisationPhoneNumber}</span>
          <span className="m-1"><i className="fa fa-envelope"></i> {props.organisationConfigRecord.organisationEmail}</span>
          <br/>
          <span className="m-1"><i className="fa fa-file"></i> ABN: {props.organisationConfigRecord.organisationABN}</span>
          <span className="m-1"><i className="fa fa-desktop"></i> {props.organisationConfigRecord.organisationWebsiteURL}</span>
        </div>
      </div>
    </>
  );
};

export default Footer;
