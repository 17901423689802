import React, { useState, useEffect, useRef, useContext } from "react";

//**Import components */
import {
	LoadingModal,
	ConfirmationModal,
	FreeTextSearch
} from "../../components/portal";

import AgencyTable from "../../components/portal/agencyTable"
import CreateAgencyModal from "../../components/portal/CreateAgencyModal";

//**Import helpers */
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";

// import filterJob from "../../helpers/filterJobHelper";
import { AuthContext } from "../../contexts/AuthContext";

//**Style css components */
import "./../../../src/assets/stylesheets/portal.css";

const AgencyTableView = ({ mode }) => {
	
	const authValue = useContext(AuthContext);

	
	const [CreateAgencyModalVisible, setCreateAgencyModalVisible] = useState(false);

	//** State define */
	const [search, setSearch] = useState("");
	const [userRolesArray, setUserRolesArray] = useState([]);

	const [checkedState, setCheckedState] = useState([]);
	const [agencyTableData, setAgencyTableData] = useState([]);

	const [selectJob, setSelectJob] = useState(0);

	const [recordForModal, setRecordForModal] = useState({});

	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [loadingModalVisible, setLoadingModalVisible] = useState(true);

	//** UseRef */
	const selectRef = useRef(null);
	const deselectRef = useRef(null);

	// loading modal styling
	const loading = {
		position: "absolute",
		top: "60%",
		left: "50%",
		width: "5%",
		display: loadingModalVisible ? "block" : "none",
	};


	const handleClear = () => {
		setSearch("");
	};

	// Searching agencyTable
	const handleSearchClick = (input) => {
		console.log('Clicked: ' + input)
		setSearch(input);
		getRecordList(input);
	};



	const getRecordList = async (input="") => {
		//Ensure loading modal is displayed
		setLoadingModalVisible(true);

		//Uses the same 
		let agencyTableArray = await postRequest("/getAgenciesFromSearch", {searchInput: input});

		if (agencyTableArray) {			
			setAgencyTableData(agencyTableArray);
		}

		//Table generation complete, hide the loading modal
		setLoadingModalVisible(false);
	};

	useEffect(() => {
		if(userRolesArray && userRolesArray.length !==0){
			getRecordList(userRolesArray);
		}
	}, [userRolesArray]);


	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {

		if (agencyTableData.length === 0) {
			getRecordList();
		}
	}, []);
	


	return (
		<>
			<LoadingModal visible={loadingModalVisible} />
			<ConfirmationModal visible={confirmationModalVisible} />
			<CreateAgencyModal visible={CreateAgencyModalVisible} recordForModal={recordForModal} onClose={() => { setCreateAgencyModalVisible(false) }} getRecordList={getRecordList} setLoadingModalVisible={setLoadingModalVisible} />
			
			
			<div class="d-flex justify-content-between">
				
				<div className="d-flex align-items-start justify-content-end flex-column mb-">
					<button className="btn btn-success btn-rounded btn-sm m-0" type="button" onClick={() => { setCreateAgencyModalVisible(true); }}>
						<i className="fa fa-plus-circle"></i>
						<span> Create agency</span>
					</button>
					
					<div>
						{agencyTableData.length > 0 && (
							<p>{agencyTableData.length} agencies displayed</p>
						)}
					</div>
				</div>

				<div className="d-flex mb-3">
					
					<FreeTextSearch
						handleSearchClick={handleSearchClick}
						handleClear={handleClear}
						search={search}
						setSearch={setSearch}
						setLoadingModalVisible={setLoadingModalVisible}
					/>
								
				</div>
			</div>		

			<div style={{ display: 'flex', flexDirection: "row" }}>
			</div>
			<AgencyTable
				userRolesArray={userRolesArray}
				tableData={agencyTableData}
				checkedState={checkedState}
				setCheckedState={setCheckedState}
				mode={mode}
				setSelectJob={setSelectJob}
				setRecordForModal={setRecordForModal}
				selectRef={selectRef}
				deselectRef={deselectRef}
				getRecordList={getRecordList}
				setLoadingModalVisible={setLoadingModalVisible}
				setConfirmationModalVisible={setConfirmationModalVisible}
				setCreateAgencyModalVisible={setCreateAgencyModalVisible}
			/>
		</>
	);
};

export default AgencyTableView;
