import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Label from "react-bootstrap/Modal";

const SelectClientModal = ({ tableData, visible, onClose, selectClick, setFieldValue }) => {
  const showTable = () => {
    if (tableData === null || tableData === []) {
      return (
        <div className="text-center">
          <Label tag="h5">An error has occurred</Label>
        </div>
      );
    } else {
      if (tableData.length < 1) {
        return (
          <div className="text-center">
            <Label tag="h5">No results have been found</Label>
          </div>
        );
      } else {
        return (
          <table
            role="table"
            className="portalTable">
            <thead>
              <tr role="row">
                <th colSpan="1" role="columnheader">
                  client Full Name
                </th>
                <th colSpan="1" role="columnheader">
                  corporate Organisation
                </th>
                <th colSpan="1" role="columnheader">
                  client Postal Address
                </th>
                <th colSpan="1" role="columnheader">
                  Select
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              {tableData.map((value, index) => {
                return (
                  <tr role="row" key={index}>
                    <td role="cell">{value.clientFullName}</td>
                    <td role="cell">{value.corporateOrganisation}</td>
                    <td role="cell">{value.clientPostalAddress}</td>
                    <td role="cell">
                      <div className="d-flex gap-3">
                        <button
                          type="button"
                          color="success"
                          className="btn btn-success btn-sm"
                          onClick={() => selectClick(value, setFieldValue)}>
                          Select
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }
    }
  };

  return (
    <Modal
      show={visible}
      onHide={onClose}
      size="xl"
      backdrop="static"
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>Select an existing client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showTable()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectClientModal;