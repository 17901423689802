import React, {useState, useEffect} from "react";
//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const ItemPage = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    editMode,
    itemIndex,
    inspectionItem,
    sectionHeaderArray,
    sectionHeaderIndex,
	signedURLArray
}) => {


    const [liveItemComment, setLiveItemComments] = useState("");
    
    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        setLiveItemComments(inspectionItem.itemComments)
    }, [inspectionItem]);

    const handleUpdate = (value) => {
        //console.log(value);
        setLiveItemComments(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
		//Need to reduce index by 1 to be the array index, index in props is for the report numbering
		newInspectionObj.items[itemIndex-1].itemComments = value; 
		

        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
    };

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar 
                    activeSection={sectionHeaderArray[sectionHeaderIndex]}
                    sectionArray={sectionHeaderArray} />
				<div className="p-4">
					<div className="itemSummaryDivs">
						<div className="itemSummaryBiDivLeft">
							<table className="itemSummaryTable">
								<tbody>
									<tr>
										<th><i className="fa fa-list"></i> Item name</th>
									</tr>
									<tr>
										<td>{itemIndex}. {inspectionItem.itemName}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="itemSummaryBiDivRight">
							{inspectionItem.itemIssues.length > 0 && 
								<table className="itemSummaryIssueAlertTable">
									<tbody>
										<tr>
											<th><i className="fa fa-warning"></i> Item issues</th>
										</tr>
										<tr>
											<td>
												<span>{inspectionItem.itemIssues.length} issue(s) identified</span>
											</td>
										</tr>
									</tbody>
								</table>
							}	
						</div>
					</div>
					<table className="itemCommentsTable">
						<tbody>
							<tr>
								<th><i className="fa fa-comments"></i> Inspector item comments</th>
							</tr>
							<tr>
								<td>
									{editMode && (
										<textarea
											name="liveItemComment"
											rows="4"
											onChange={(e) => {
												handleUpdate(e.target.value);
											}}
											value={liveItemComment}
										>
										</textarea>
									)}

									{!editMode && (
										<p>
											{inspectionItem.itemComments}
										</p>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<div className="itemImages">
						<table className="reportItemPhotoTable">
							<tbody>
								<tr>
									<th colSpan="2"><i className="fa fa-images"></i> Item photos</th>
								</tr>
								<tr>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={inspectionItem.itemPhotos[0]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={inspectionItem.itemPhotos[1]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
								</tr>
								<tr>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={inspectionItem.itemPhotos[2]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
									<td style={{ textAlign: 'center' }}>
										<Photo signedURLArray={signedURLArray} inspectorUserID={inspectionJobRecord.inspectorUserID} jobID={inspectionJobRecord.jobID} fileName={inspectionItem.itemPhotos[3]} classString={"cellImageIssue landscapePhoto"} />       
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default ItemPage;