export const agencyTypeOptions = [
  { value: "", label: "Select agency type" },
  { value: "Real Estate Agent", label: "Real Estate Agent" },
  { value: "Strata Manager", label: "Strata Manager" },
  { value: "Property Buyer", label: "Property Buyer" },
  { value: "Settlement Agent", label: "Settlement Agent" },
  { value: "Other", label: "Other" },
];

export const propertyTypeOptions = [
  { value: "", label: "Select property type" },
  { value: "Residential", label: "Residential" },
  { value: "Commercial", label: "Commercial" },
  { value: "Other", label: "Other" },
];

export const jobStatusOptions = [
  { value: "Draft", label: "Draft" },
  { value: "Confirmed", label: "Confirmed" },
  { value: "Uploaded", label: "Uploaded" },
  { value: "Edited", label: "Edited" },
  { value: "Approved", label: "Approved" },
];

export const jobReferralType = [
  { value: "", label: "" },
  { value: "Conveyancer", label: "Conveyancer" },
  { value: "Corporate Client", label: "Corporate Client" },
  { value: "Finance Broker", label: "Finance Broker" },
  { value: "Houspect Website", label: "Houspect Website" },
  { value: "Inspector", label: "Inspector" },
  { value: "Internet", label: "Internet" },
  { value: "Listing Agent", label: "Listing Agent" },
  { value: "Other Agent", label: "Other Agent" },
  { value: "Other referral", label: "Other referral" },
  { value: "Past Client", label: "Past Client" },
  { value: "Strata Company", label: "Strata Company" },
];

export const times = [
  "6:00 AM",
  "6:15 AM",
  "6:30 AM",
  "6:45 AM",
  "7:00 AM",
  "7:15 AM",
  "7:30 AM",
  "7:45 AM",
  "8:00 AM",
  "8:15 AM",
  "8:30 AM",
  "8:45 AM",
  "9:00 AM",
  "9:15 AM",
  "9:30 AM",
  "9:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "1:00 PM",
  "1:15 PM",
  "1:30 PM",
  "1:45 PM",
  "2:00 PM",
  "2:15 PM",
  "2:30 PM",
  "2:45 PM",
  "3:00 PM",
  "3:15 PM",
  "3:30 PM",
  "3:45 PM",
  "4:00 PM",
  "4:15 PM",
  "4:30 PM",
  "4:45 PM",
  "5:00 PM",
  "5:15 PM",
  "5:30 PM",
  "5:45 PM",
];

export const allStatusesArray = [
  "Draft",
  "Confirmed",
  "Uploaded",
  "Edited",
  "Approved",
  "Completed",
  "Archived",
];

export const allTimesArray = [
  // ">30 days ago",
  "8+ days ago",
  "Within last 7 days",
  "Today",
  "Next 7 days",
  "8+ days away",
];

export const jobInpectionTime = (jobDate) => {
  return new Date(jobDate.split("-").reverse().join("-")).getTime();
};

export const calcTime = (date) => {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  m = m.toString().length === 1 ? `0${m}` : m;
  d = d.toString().length === 1 ? `0${d}` : d;
  let eTime = `${d}-${m}-${y}`;
  return eTime;
};

export const searchFilter = (array, search) => {
  
  return array.filter((job) => {

    let expression = `.*${search}.*`;
    let regex = new RegExp(expression, "i");

    return (
      regex.test(job.inspectionDate) ||
      regex.test(job.clientFullName) ||
      regex.test(job.inspectionType) ||
      regex.test(job.inspectorUserID) ||
      regex.test(job.corporateOrganisation) ||
      regex.test(job.jobID) ||
      regex.test(job.propertyAddress)
    );
  });
};
