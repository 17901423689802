import React from "react";
import { Link } from "react-router-dom";

const SideBarMenuItem = (props) => {
  return (
    <a onClick={props.onClick} className="nav-item active text-decoration-none">
      <li>
        <div className="nav-link">
          <i className={"fas fa-fw fa-" + props.iconName}></i>
          <span>{props.menuTitle}</span>
        </div>
      </li>
    </a>
  );
};

export default SideBarMenuItem;
