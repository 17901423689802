import React, { useContext } from "react";

//Import helpers
import { shortenAddress } from "../../helpers/generalHelper";

const Header = (props) => {

  const clientHeaderText = () => {
    let corporateOrganisation = props.inspectionJobRecord.corporateOrganisation;
    let clientFullName = props.inspectionJobRecord.clientFullName;
    if (corporateOrganisation === null || corporateOrganisation === '' || corporateOrganisation === undefined) {
      return clientFullName
    } else {
      return corporateOrganisation
    }
  }

  return (
    <>
      <div className="headerSection">
        <div className="headerSectionLeft">
          <img className="headerLogo landscapePhoto" alt="" src={props.organisationConfigRecord.organisationLogoHeaderURL} />
        </div>
        <div className="headerSectionCenter">
          <p className="headerSectionCenterText">
            Client: {clientHeaderText()}
          </p>
          <p className="headerSectionCenterText">
            Inspected on: {props.inspectionJobRecord.inspectionDate}
          </p>
        </div>
        <div className="headerSectionRight">
          <span className="pageNumber">Page {props.pageNumber}</span>
        </div>
      </div>
      <div className="headerSectionBar" />
    </>
  );
};

export default Header;
