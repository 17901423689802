import React, { useContext, useEffect, useState } from "react";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

//Import local styling
import "./../../../assets/stylesheets/signIn.css";
import Logo from "./../../../assets/images/inspectAPP.png";
import { AuthContext } from "./../../../contexts/AuthContext";

import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

//Import components

const SignIn = () => {

    let navigate = useNavigate();

    // If already logged in, effectively navigate to homepage
    const authContext = useContext(AuthContext);
    useEffect(() => {
      if (authContext.user !== null) {
        
      }
    }, [authContext])

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
      setIsLoading(true);
      e.preventDefault();
      if (!email && !password) {
        setIsLoading(false);
        return;
      }
      try {
        const auth = getAuth();
        const userData = await signInWithEmailAndPassword(auth, email, password);
        localStorage.setItem("refreshToken", userData.user.stsTokenManager.refreshToken);
        localStorage.setItem("uid", userData.user.uid);
        localStorage.setItem("displayName", userData.user.displayName);
        localStorage.setItem("email", userData.user.email);

        console.log(userData)
        // Signed in
        const user = userData.user;

        if (user.accessToken) {
          //console.log("user", user.accessToken);
          //setError("User Loggedin");
          setIsLoading(false);
          navigate('/');
        }
      } catch (error) {
        let errorMessage = "";
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "Enter valid email address";
            break;
          case "auth/wrong-password":
            errorMessage = "Enter valid password";
            break;
          case "auth/user-not-found":
            errorMessage = "Invalid email or password";
          default:
            errorMessage = error.message || "Error in Login";
            break;
        }
        setError(errorMessage);
        setIsLoading(false);
      }
    };

  return (
    <div className="signInBackground">
    <div className="fullSizeContainer">
      <div className="h-100 d-flex align-items-center justify-content-center">
        {isLoading ? (
          <PropagateLoader color="#ffffff" />
        ) : (
          <div className="card text-center card-box p-3 align-items-center">
            <img className="mb-4" src={Logo} alt="" width="200" />
            <form onSubmit={handleSubmit} autoComplete="off">
              <label className="float-left">Email</label>

              <input
                className="form-control"
                id="email"
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <label className="float-left pt-3">Password</label>
              <a href="/forgot-password" className="pt-3 float-right text-decoration-none">
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  Forgot your password?
                </span>
              </a>
              <input
                className="form-control"
                id="password"
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              {error && <div className="error-message">{error}</div>}
              <button className="btn btn-dark" type="submit">
                Sign in
              </button>
            </form>
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default SignIn;