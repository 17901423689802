import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import PropagateLoader from "react-spinners/PropagateLoader";

const ConfirmationModal = ({ visible }) => {
  
	
  if(visible) {
    return (
      <div style={{
        position: 'fixed',
        zIndex: 100,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)'
        }}>
          <div style={{
            position:'absolute',
            left:'50%',
            top:'50%',
          }}>
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            </div> 
      </div>
    );
  } else {
    return <></>
  }
};

export default ConfirmationModal;
