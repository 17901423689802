import React from "react";
import Select from "react-select";

const DefaultBlankDropdown = ({
    inputName,
    inputLabelName,
    dropdownOptions,
    dropdownHandler,
    setFieldValue,
    required = false,
    touched,
    errors,
    disable,
    values,
    value
}) => {
    const handleChange = (value) => {
        if (inputName === "inspectionClass") {
            dropdownHandler(value.value);
        }
        setFieldValue(inputName, value ? value.value : "");
    };


    return (
        <div className="">
            <div className="">
                <div className="form-group">
                    <label className="form-label" htmlFor={inputName} >
                        {inputLabelName}
                        {required &&
                            <span className="text-danger">*</span>
                        }
                    </label>

                    <select className="form-control"
                        disabled={disable}
                        id={inputName}
                        name={inputName}
                        value={values[inputName]}
                        onChange={(e) => handleChange({ value: e.target.value, label: e.target.value })}
                    >
                        <option disabled value={""}>{""}</option>
                        {
                            dropdownOptions && dropdownOptions.map((option, index) => {
                                return <option key={index} value={option.value}>{option.label}</option>
                            })
                        }

                    </select>
                </div>
            </div>
            {
                touched[{ inputName }] && errors[{ inputName }] && (
                    <div className="invalid-react-select-dropdown text-danger">
                        {errors[{ inputName }]}
                    </div>
                )
            }
        </div>
    );
};

export default DefaultBlankDropdown;

