import React, { useState, useEffect } from "react";

import './../../../assets/stylesheets/portal.css'

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import AgencyTableView from "../../../views/agencyTableView";

const AgencyTable = (props) => {
    return (
        <>
            <ViewHeaderTitle title={props.headerTitle} />
            <AgencyTableView />
        </>
    );
};

export default AgencyTable;
