import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";

const ItemSummary = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    editMode,
    pageIndex,
    rowsPerPage,
    inspectionItems,
    showLocation,
    sectionHeaderArray,
    sectionHeaderIndex,
    signedURLArray
}) => {


    const [liveInspectorObservations, setLiveInspectorObservations] = useState("");
    
    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        //console.log(inspectionObj)
        setLiveInspectorObservations(inspectionObj.inspectorObservations)
    }, [inspectionObj]);

    const handleUpdate = (value) => {
        //console.log(value);
        setLiveInspectorObservations(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
        newInspectionObj.inspectorObservations = value;
        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
        
    };




    const itemRows = () => {
        let componentJSX = [];
        let startRowIndex = pageIndex*rowsPerPage;
        let endRowIndex = (pageIndex+1)*rowsPerPage;
        let currentIndex = 0;
        if (inspectionItems) {
            for (var i = 0; i < inspectionItems.length; i++) {
                currentIndex++;
                let inspectionItemIssues = inspectionItems[i].itemIssues;
                if (inspectionItemIssues.length > 0) {
                    currentIndex--; //We reverse the original row for the item
                    for (var j = 0; j < inspectionItemIssues.length; j++) {
                        currentIndex++;//And then count each additional issue row
                        if((currentIndex >= startRowIndex) && (currentIndex < endRowIndex)) {
                            componentJSX.push(
                                <tr key={String(i) + "." + String(j)}>
                                    <td>
                                        {i + 1}. {inspectionItems[i].itemName}
                                    </td>
                                    {showLocation && <td>{inspectionItems[i].itemLocation}</td>}
                                    <td>
                                        {i + 1}.{j + 1}{" "}
                                        {inspectionItemIssues[j].issueName}
                                    </td>
                                </tr>
                            );
                        }
                    }
                } else {
                    if((currentIndex >= startRowIndex) && (currentIndex < endRowIndex)) {
                        componentJSX.push(
                            <tr key={String(i) + ".00"}>
                                <td>
                                    {i + 1}. {inspectionItems[i].itemName}
                                </td>
                                {showLocation && <td>{inspectionItems[i].itemLocation}</td>}
                                <td>Acceptable / no issues identified</td>
                            </tr>
                        );
                    }
                }
            }
        }
        return componentJSX;
    };

    return (
        <>
            <div className="a4Page standardPage">
                <Header
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
                <NavBar
                    activeSection={sectionHeaderArray[sectionHeaderIndex]}
                    sectionArray={sectionHeaderArray}
                />
                <div className="p-4">
                    <p className="sectionHeader">Inspection item Summary</p>

                    {pageIndex === 0 && (
                            <>
                                <p className="m-0">
                                    <b>Inspector observations: </b>
                                </p>
                                {editMode && (
                                    <textarea
                                        name="liveInspectorObservations"
                                        rows="4"
                                        onChange={(e) => {
                                            handleUpdate(e.target.value);
                                        }}
                                        value={liveInspectorObservations}
                                    >
                                    </textarea>
                                )}

                                {!editMode && (
                                    <p>
                                        {
                                            inspectionObj.inspectorObservations && 
                                            <span>{inspectionObj.inspectorObservations}</span>
                                        }
                                    </p>
                                )}
                            </>
                        )}
                    <table className="propertySummaryTableItems">
                        <tbody>
                            <tr>
                                <th>Inspection item</th>
                                {showLocation && <th>Item location</th>}
                                <th>Inspection issues</th>
                            </tr>
                            {itemRows()}
                        </tbody>
                    </table>
                </div>

                <Footer
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
            </div>
        </>
    );
};

export default ItemSummary;