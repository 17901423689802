import React, {useState, useEffect} from "react";
//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useSearchParams } from "react-router-dom";

//Import helpers
import {
	// postRequest,
	postRequestWithoutToken,
  } from "../../helpers/apiHelper";

const ReportSummary = ({organisationConfigRecord, inspectionJobRecord, inspectionObj, sectionHeaderArray, sectionHeaderIndex, reportPurpose}) => {

	
    const [inspectorDescription, setInspectorDescription] = useState("");    

	const [params] = useSearchParams();

	const clientName = () => {
		if(inspectionJobRecord) {
			let corporateOrganisation = inspectionJobRecord.corporateOrganisation;
			let clientFullName = inspectionJobRecord.clientFullName;
			if (corporateOrganisation === null || corporateOrganisation === '' || corporateOrganisation === undefined) {
				return clientFullName
			} else {
				return corporateOrganisation
			}
		}
	}


	const getInspectorDescription = async (inspectorUserID) => {
		try {
			const inspectorDescriptionResponse = await postRequestWithoutToken(
				"/getInspectorDescriptionForReport",
				{ reportToken: params.get("reportToken"), inspectorUserID }
			);
	
			setInspectorDescription(inspectorDescriptionResponse);

		} catch(e) {

		}
	};
	
    useEffect(() => {
        //console.log(inspectionObj)
		if(inspectionJobRecord.hasOwnProperty('inspectorUserID')) {
			getInspectorDescription(inspectionJobRecord.inspectorUserID);
		}
    }, [inspectionJobRecord]);

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
				<div className="p-4">
					<div className="sectionHeader"><span>Report summary</span></div>
					<table className="reportSummaryTable">
						<tbody>
							<tr>
								<th colSpan="2"><i className="fa fa-list"></i> Inspection details</th>
							</tr>
							<tr>
								<td>Client name</td>
								<td>{clientName()}</td>
							</tr>
							<tr>
								<td>Client address</td>
								<td>{inspectionJobRecord.clientPostalAddress}</td>
							</tr>
							<tr>
								<td>Job ID</td>
								<td>{inspectionJobRecord.jobID}</td>
							</tr>
							<tr>
								<td>Property address</td>
								<td>{inspectionJobRecord.propertyAddress}</td>
							</tr>
							<tr>
								<td>Inspection date</td>
								<td>{inspectionJobRecord.inspectionDate}</td>
							</tr>
							<tr>
								<td>Occupied</td>
								<td>{inspectionObj.propertyOccupiedDuringInspection}</td>
							</tr>
							<tr>
								<td>Weather</td>
								<td>{inspectionObj.weatherDuringInspection}</td>
							</tr>
							<tr>
								<td>Inspector</td>
								<td>{inspectorDescription}</td>
							</tr>
							<tr>
								<td>Report purpose</td>
								
								{!(inspectionJobRecord.originalInspectionJobID) > 0 && (
									<td>The purpose of this report is to undertake a pre settlement building inspection where the focus is to confirm that specific items are in “good working order” at the time of the inspection. The inspection assumes that a good working order clause forms a part of the purchase contract. The intent of the inspections is to simply identify operational defects or faults in the operation of various items in so far as a Registered Builder can reasonably and or visually identify those defects or faults. The Inspector is not a Registered Plumber, Electrician, Gas Fitter, A/c technician, Pool technician, Security Technician or similar and hence there are limits on what can be inspected. The inspection is conducted in accordance with AS 4349.1. The report must be read in full.</td>
								)} 
								{(inspectionJobRecord.originalInspectionJobID) > 0 && (
									<td>The purpose of this report is specifically to follow up a previous inspection undertaken by Houspect to determine any change in the conditions previously reported. The scope of the inspection has been agreed between the client and the inspector in the engagement documentation.</td>
								)} 
							</tr>
							<tr>
								<td>Report summary</td>
								<td>Based on our visual inspection and within the terms of our engagement, a range of items within the property were inspected from a good working order perspective. The findings of this inspection are contained within this report.</td>
							</tr>
						</tbody>
					</table>
					<br />
					<img id="approverSignature" src={organisationConfigRecord.reportApproverSignatureURL} alt="" /><br />
					<p className="m-0"><b>{organisationConfigRecord.reportApproverName}</b></p>
					<p className="m-0">{organisationConfigRecord.reportApproverPosition}</p>
					<p className="m-0">{organisationConfigRecord.fullOrganisationName}</p>
				</div>
				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default ReportSummary;