import React, { useState, useEffect } from "react";

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import "./index.css";
import "./assets/stylesheets/global.css";

// Import components
import AuthLayout from "./pages/auth-layout";
import SignIn from "./pages/auth/signin";
import ForgotPassword from "./pages/auth/forgot-password";

import AuthContextProvider from "./contexts/AuthContext";
//import { PrivateRoute } from "./helpers/PrivateRoute";

import "./firebase";

//Portal components
import CreateJob from "./pages/portal/createJob";
import EditJob from "./pages/portal/editJob";
import ReportEditor from "./pages/portal/reportEditor";
import ViewJobs from "./pages/portal/viewJobs";
import ArchiveJobs from "./pages/portal/archiveJobs";
import DeleteJobs from "./pages/portal/deleteJobs";
import ViewUsers from "./pages/portal/viewUsers";
import ViewSchedule from "./pages/portal/viewSchedule";
import ExtractData from "./pages/portal/extractData";
import Configuration from "./pages/portal/configuration";
import ViewAgents from "./pages/portal/viewAgents";
import ViewAgencies from "./pages/portal/viewAgencies";

//Jobsheet
import JobSheet from "./pages/jobSheet";

//Report component
import Report from "./pages/report";
import ReportConstruction from "./pages/report/construction";
import ReportDilapidation from "./pages/report/dilapidation";
import ReportBuilding from "./pages/report/building";
import ReportGeneralBuilding from "./pages/report/generalBuilding";
import ReportPostConstruction from "./pages/report/postConstruction";
import ReportPreSettlement from "./pages/report/preSettlement";


// Project Context
import InspectionTypeContext from "./contexts/InspectionTypeContext";

function App() {
	return (
		<>
				<Routes>
					<Route
						path="/"
						element={
							<AuthLayout />
						}>
						<Route index element={<ViewJobs headerTitle={"View jobs"} />}></Route>
						<Route path="viewJobs" element={<ViewJobs headerTitle={"View jobs"} />} />
						<Route path="createJob" element={<InspectionTypeContext><CreateJob /></InspectionTypeContext>} />
						<Route path="copyJob" element={<InspectionTypeContext><CreateJob /></InspectionTypeContext>} />
						<Route path="editJob" element={<InspectionTypeContext><EditJob /></InspectionTypeContext>} />
						{/* <Route path="createJob/:jobID" element={<InspectionTypeContext><CreateJob /></InspectionTypeContext>} /> */}

						<Route path="archiveJobs" element={<ArchiveJobs headerTitle={"Archive jobs"} />} />
						<Route path="deleteJobs" element={<DeleteJobs headerTitle={"Delete jobs"} />} />


						<Route path="viewUsers" element={<ViewUsers headerTitle={"View users"} />} />

						<Route path="viewSchedule" element={<ViewSchedule headerTitle={"View schedule"} />} />
						<Route path="extractData" element={<ExtractData headerTitle={"Extract data"} />} />
						<Route path="configuration" element={<Configuration headerTitle={"Configuration"} />} />
						<Route path="viewAgents" element={<ViewAgents headerTitle={"View agents"} />} />
						<Route path="viewAgencies" element={<ViewAgencies headerTitle={"View agencies"} />} />

						<Route path="reportEditor" element={<ReportEditor />} />
					</Route>


					{/* ROUTES UNPROTECTED BY FIREBASE AUTHENTICATION - USES REPORT TOKEN IF NEEDING TO MAKE POST REQUESTS TO API*/}
					<Route path="/signin" element={<SignIn />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />

					{/* /jobSheet?jobID=77715 */}
					<Route path="/jobSheet" element={<JobSheet />} />
					<Route path="/report" element={<Report />} />

					<Route path="/PDFReport/construction" element={<ReportConstruction editMode={false} />} />
					<Route path="/PDFReport/dilapidation" element={<ReportDilapidation editMode={false} />} />
					<Route path="/PDFReport/building" element={<ReportBuilding editMode={false} />} />
					<Route path="/PDFReport/generalBuilding" element={<ReportGeneralBuilding editMode={false} />} />
					<Route path="/PDFReport/postConstruction" element={<ReportPostConstruction editMode={false} />} />
					<Route path="/PDFReport/preSettlement" element={<ReportPreSettlement editMode={false} />} />

					<Route path="/editReport/construction" element={<ReportConstruction editMode={true} />} />
					<Route path="/editReport/dilapidation" element={<ReportDilapidation editMode={true} />} />
					<Route path="/editReport/building" element={<ReportBuilding editMode={true} />} />
					<Route path="/editReport/generalBuilding" element={<ReportGeneralBuilding editMode={true} />} />
					<Route path="/editReport/postConstruction" element={<ReportPostConstruction editMode={true} />} />
					<Route path="/editReport/preSettlement" element={<ReportPreSettlement editMode={true} />} />
				</Routes>
		</>
	);
}

export default App;