import React, {useState, useEffect} from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";

const ItemSummary = ({
    organisationConfigRecord,
    inspectionJobRecord,
    inspectionObj,
    externalItemsArray,
    internalItemsArray,
    editMode,
    pageIndex,
    rowsPerPage,
    inspectionItems,
    showLocation,
    sectionHeaderArray,
    sectionHeaderIndex,
    signedURLArray
}) => {


    const [liveInspectorObservations, setLiveInspectorObservations] = useState("");
    
    // Wait for the inspectionObj to update, if it does, update the propertyDescription
    useEffect(() => {
        //console.log(inspectionObj)
        setLiveInspectorObservations(inspectionObj.inspectorObservations)
    }, [inspectionObj]);

    const handleUpdate = (value) => {
        //console.log(value);
        setLiveInspectorObservations(value);

        //Update the async storage value    
		let newInspectionObj = JSON.parse(localStorage.getItem(`reportEditor${inspectionJobRecord.jobID}`));
        newInspectionObj.inspectorObservations = value;
        localStorage.setItem(`reportEditor${inspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
        
    };




    const itemRows = (startIndex, inspectionItems) => {
        let componentJSX = [];
        let startRowIndex = pageIndex*rowsPerPage;
        let endRowIndex = (pageIndex+1)*rowsPerPage;
        let currentIndex = 0;
        if (inspectionItems) {
            for (var i = 0; i < inspectionItems.length; i++) {
                currentIndex++;
                let inspectionItemIssues = inspectionItems[i].itemIssues;
                if((currentIndex >= startRowIndex) && (currentIndex < endRowIndex)) {
                    componentJSX.push(
                        <tr key={String(i) + ".00"}>
                            <td>
                                {i + startIndex}. {inspectionItems[i].itemName}
                            </td>
                            <td>{inspectionItemIssues.length}</td>
                        </tr>
                    );
                }
            }
        }
        return componentJSX;
    };

    return (
        <>
            <div className="a4Page standardPage">
                <Header
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
                <NavBar
                    activeSection={sectionHeaderArray[sectionHeaderIndex]}
                    sectionArray={sectionHeaderArray}
                />
                <div className="p-4">
                    <p className="sectionHeader">Inspection item Summary</p>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div style={{width:'50%', textAlign:'center', paddingRigh:'2px'}}>
                            <span style={{textAlign:'center'}}><strong>External areas</strong></span>
                            <table className="propertySummaryDilapidationTableItems">
                                <tbody>
                                    <tr>
                                        <th>Inspection item</th>
                                        <th>Issues</th>
                                    </tr>
                                    {itemRows(1, externalItemsArray)}
                                </tbody>
                            </table>
                        </div>
                        <div style={{width:'50%', textAlign:'center', paddingLeft:'2px'}}>
                            <span style={{textAlign:'center'}}><strong>Internal areas</strong></span>
                            <table className="propertySummaryDilapidationTableItems">
                                <tbody>
                                    <tr>
                                        <th>Inspection item</th>
                                        <th>Issues</th>
                                    </tr>
                                    {itemRows(externalItemsArray.length + 1, internalItemsArray)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Footer
                    organisationConfigRecord={organisationConfigRecord}
                    inspectionJobRecord={inspectionJobRecord}
                />
            </div>
        </>
    );
};

export default ItemSummary;