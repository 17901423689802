import React, { useContext } from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const PropertyOverviewPhotos = (props) => {
  //Dynamically fill table
  const propertyOverviewPhotosTableBody = () => {
    // //console.log(props.propertyOverviewPhotos);
    let propertyOverviewPhotos = props.propertyOverviewPhotos;
    let inspectorUserID = props.inspectionJobRecord.inspectorUserID;
    let jobID = props.inspectionJobRecord.jobID;
    let componentJSX = [];
    let rowNumber = 1;
    for (var i = 0; i < 4; i++) {
      componentJSX.push(
        <tr key={i}>
          <td>
            <Photo inspectorUserID={inspectorUserID} jobID={jobID} fileName={propertyOverviewPhotos[3 * i]} classString={"propertyOverviewImage landscapePhoto"} signedURLArray={props.signedURLArray}/>  
          </td>
          <td>
            <Photo inspectorUserID={inspectorUserID} jobID={jobID} fileName={propertyOverviewPhotos[3 * i + 1]} classString={"propertyOverviewImage landscapePhoto"}  signedURLArray={props.signedURLArray}/>  
          </td>
          <td>
            <Photo inspectorUserID={inspectorUserID} jobID={jobID} fileName={propertyOverviewPhotos[3 * i + 2]} classString={"propertyOverviewImage landscapePhoto"} signedURLArray={props.signedURLArray}/> 
          </td>
        </tr>
      );
    }
    return componentJSX;
  };

  return (
    <>
      <div className="a4Page standardPage">
        <Header
          organisationConfigRecord={props.organisationConfigRecord}
          inspectionJobRecord={props.inspectionJobRecord}
        />
        <NavBar
          activeSection={props.sectionHeaderArray[props.sectionHeaderIndex]}
          sectionArray={props.sectionHeaderArray}
        />
        <div className="p-4">
          <div className="sectionHeader">
            <span>Property Overview Photos</span>
          </div>
          <table className="propertyOverviewPhotosTable">
            <tbody>
              <tr>
                <th colSpan="3">
                  <i className="fa fa-camera"></i> Property Overview Photos
                </th>
              </tr>
              {propertyOverviewPhotosTableBody()}
            </tbody>
          </table>
        </div>
        <Footer
          organisationConfigRecord={props.organisationConfigRecord}
          inspectionJobRecord={props.inspectionJobRecord}
        />
      </div>
    </>
  );
};

export default PropertyOverviewPhotos;