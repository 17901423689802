import React, { useEffect, useState } from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import "../../../assets/stylesheets/steppedForm.css";
import {FormFeedback, Input} from "reactstrap";

const InputGroupMultiLineText = ({ inputName, inputLabelName, placeholder, rows, required=false, touched, errors, values, handleBlur, handleChange }) => {
  return (
      <div className="form-group">
        <label className="form-label" htmlFor={inputName}>
            {inputLabelName}
            {required &&
                <span className="text-danger">*</span>
            }            
        </label>
        <Input
            invalid={touched[inputName] && errors[inputName]}
            className="form-control"
            rows={rows}
            placeholder={placeholder}
            type="textarea" onChange={handleChange} onBlur={handleBlur}
            name={inputName}
            value={values[inputName]}
        />
        {
            touched[inputName] && errors[inputName] && (
                <FormFeedback>
                    {errors[inputName]}
                </FormFeedback>
            )
        }
      </div>
  );
};

export default InputGroupMultiLineText;
