import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import PropagateLoader from "react-spinners/PropagateLoader";

const LoadingModal = ({ visible }) => {
  if(visible) {
    return (
      <div style={{
        position: 'fixed',
        zIndex: 100,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)'
        }}>
          <div style={{
            width:'200px',
            height:'100px',
            position:'absolute',
            left:'50%',
            top:'50%',
          }}>
          <PropagateLoader color="#ffffff" />
            </div> 
      </div>
    );
  } else {
    return <></>
  }
};

export default LoadingModal;
