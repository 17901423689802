import React, { useContext } from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";


const Scope = ({organisationConfigRecord, inspectionJobRecord, inspectionObj, sectionHeaderArray, sectionHeaderIndex, pageBodyJSX}) => {
	
	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
				<NavBar activeSection={sectionHeaderArray[sectionHeaderIndex]} sectionArray={sectionHeaderArray} />
				<div className="p-4">
					<div className="sectionHeader">
						<span>Scope of work</span>
					</div>
					{pageBodyJSX}
				</div>
				<Footer organisationConfigRecord={organisationConfigRecord} inspectionJobRecord={inspectionJobRecord} />
			</div>
		</>
	);
};

export default Scope;