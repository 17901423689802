import React, { useState, useEffect } from "react";

//Import components
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";
import JobForm from "../../../views/jobForm";

//Import helpers
import { postRequest } from "../../../helpers/apiHelper";
import { useSearchParams } from "react-router-dom";

const EditJob = (props) => {
  // const params = useParams()
  const [jobRecord, setJobRecord] = useState([]);
  const [params] = useSearchParams();

  const [loadingModalVisible, setLoadingModalVisibility] = useState(false);


  //If there is a copy job URL parameter, identify that here
  //...
  //The following post request will get the relevant job fields from the server

  useEffect(() => {
    const getJobRecordToBeCopied = async () => {
      setLoadingModalVisibility(true)
      const jobRecord = await postRequest("/getJobRecord", {
        jobID: params.get("jobID"),
      });
      // //console.log(jobRecord);
      setJobRecord(jobRecord);
      setLoadingModalVisibility(false)
    };
    getJobRecordToBeCopied();
  }, [params]);

  return (
    <>
      <ViewHeaderTitle title={"Edit job"} />
      <JobForm action={"Edit"} jobRecord={jobRecord} loadingModalVisible={loadingModalVisible} setLoadingModalVisibility={setLoadingModalVisibility} />
    </>
  );
};

export default EditJob;
