import React, { useState, useEffect } from "react";

export const scopePagesStructuralArray = [
	<>
		<strong>1. Service</strong><br />
		As requested by the Client the inspection provided by the Building Inspector (“Inspector”) is a Pre-Purchase Structural Inspection. <br />
		<br />
		<strong>2. Purpose of Inspection&nbsp;</strong><br />
		This inspection is an inspection carried out pursuant to an Australian Standard. It is an inspection to advise upon the condition of the structures on the property and any major structural defects found in relation to the property. The
		Inspection is carried out in accordance with AS 4349.1. Inspection is carried out for the sole benefit of the client who has requested the inspection.<br />
		<br />
		<strong>2.1 Scope Limitation&nbsp;</strong><br />
		The inspection and report should not be seen as an all-encompassing report dealing with a building from every aspect. Rather it should be seen as a reasonable attempt to identify any major structural defects visible at the time of the inspection. Whether or not a defect is a major defect depends on a number of factors including the age of the building. It is not within the scope of this inspection type to comment on major non-structural or minor defects. <br />
		<br />
		<strong>2.2 Visual Inspection&nbsp;</strong><br />
		All inspections are visual inspections limited to those areas and sections of the property fully accessible and visible to the inspector on the date of the inspection. The inspection does not include an invasive inspection or breaking
		apart, dismantling, removing or moving objects including but not limited to foliage, moulding, roof insulation / sisalation, floor or wall coverings, sidings, ceilings, floors, furnishings, appliances or personal possessions. The inspector
		cannot see inside walls, between floors, inside skillion roofing, behind assorted goods in cupboards, other areas that are concealed or obstructed. The inspector will not dig and cannot, gouge, force or perform any other invasive
		procedures. Visible timbers will not and cannot be destructively probed or hit without prior written permission of the property owner. The inspector will only report on readily observable state of the property at the time of inspection. The
		inspection therefore cannot deal with:
		<ul>
			<li>possible concealment of defects, including but not limited to, defects concealed by lack of accessibility, obstructions such as furniture, wall linings and floor coverings, or by applied finishes such as render and paint; and</li>
			<li>
				undetectable or latent defects, including but not limited to, defects that may not be apparent at the time of inspection due to seasonal changes, recent or prevailing weather conditions, and whether or not services have been used
				some time prior to the inspection being carried out.
			</li>
		</ul>
		<strong>2.3 Not a Certificate of compliance&nbsp;</strong><br />
		A report prepared in accordance with AS4349.1 is not a certificate of compliance of the property within the requirements of any Act, regulation, ordinance, local law or by-law, and is not a warranty against problems developing with the
		building in the future. &nbsp; The Australian Standards do not include the identification of unauthorized building work or of work not compliant with building regulations. This Standards assumes that the existing use of the building will
		continue.&nbsp;<br />
		<br />
		<strong>2.4 Comparative Position and Acceptance Criteria&nbsp;</strong><br />
		The building shall be compared with a building that was constructed in accordance with the generally accepted practice at the time of construction and which has been maintained &nbsp;such that there has been no significant loss of strength
		and serviceability.&nbsp;<br />
		<br />
		</>,

	<> 
		<strong>2.5	Purchase Contract</strong><br />
		As noted at 2 above, the inspection is carried out pursuant to the relevant Australian Standard. It is not carried out pursuant to the terms of any contract the Client may have signed, and the Client is solely responsible for interpreting this report and applying it to their Contract. <br />
		<br />
		<strong>3. Areas to be inspected&nbsp;</strong><br />
		<strong>3.1 General&nbsp;</strong><br />
		The inspection shall cover all accessible areas defined by the construction permit. As per the Standards, the client shall arrange right of entry, facilitate physical entry to the property and supply necessary information to enable the
		inspector to undertake the inspection and prepare a report. The inspector is not responsible for arranging entry to property or parts of property.&nbsp; The inspector shall inspect accessible parts of the building and appurtenances,
		together with relevant feature of the property within 30m of the main building and within the boundaries of the site, or as otherwise agreed in the inspection agreement. In this context, relevant features include car accommodation, detached
		laundry, ablution facilities and garden sheds, retaining walls more than 700mm high, paths and driveways, steps, fencing.&nbsp; Inspection of Strata and Company Title residential property shall be limited to the nominated residence and does
		not include common property.&nbsp;<br />
		The following area shall be inspected where applicable or accessible:&nbsp;<br />
		<ul>
			<li>The interior of the building&nbsp;</li>
			<li>The roof space&nbsp;</li>
			<li>The exterior of the building&nbsp;</li>
			<li>The sub-floor space&nbsp;</li>
			<li>The roof exterior&nbsp;</li>
			<li>The property within 30m of the building subject to inspection&nbsp;</li>
		</ul>
		<strong>3.2 Safe and reasonable access&nbsp;</strong><br />
		The extent of accessible areas shall be determined by the inspector at the time of inspection, based on the conditions encountered at the time of inspection and WA Worksafe OH&amp;S Laws. The inspector shall also determine whether
		sufficient space is available to allow safe access. As a general rule minimum crawls spaces of 600mm x 600 mm are required.&nbsp; Pursuant to regulation, the inspector is not permitted to access the roof cover where power to the roof space
		cannot be isolated at the meter box or sub board and or solar inverter.&nbsp; The inspection shall include only accessible areas and areas that are within the inspector’s line of sight and close enough to enable reasonable appraisal.&nbsp;

		The inspector shall inspect an elevated area only where –&nbsp;
		<ul>
			<li>it is at a height at which safe reasonable access is available, or where safe and reasonable access is otherwise available; or</li>
			<li>an unobstructed line of sight is present from safe use of a 3.6m ladder and the building elements present are close enough to allow appraisal.</li>
		</ul>
		NOTE: “Elevated area” includes the roof, roof space, crawl space, landing feature, and the like, generally elevated above the ground and intended for normal use by occupants. A 3.6m ladder is considered generally reasonable for safe use by
		one operator during an inspection, subject to prevailing weather conditions. Regardless of the ladder length, weight and size, safe use of ladder or safe access may mean that inspection of a roof, elevated platform or roof space is not
		possible in part, or at all, during an inspection and, in such circumstances, and inspector may recommend the use of special access equipment and that a further inspection be undertaken when a safe method of access is present.&nbsp;<br />
		
	</>,

	<> 
		<strong>3.3 Excluded Areas&nbsp;</strong><br />
		Areas where reasonable entry is denied to the inspector, or where reasonable access is not available, are excluded from, and do not form part of, the inspection. Excluded areas include areas where safe OH&amp;S was not available. Excluded
		areas include areas where access was specifically denied (i.e. locked rooms) or access was not provided, (manholes not opened prior to the inspectors arrival) while the inspector is on site conducting the inspection. Where appropriate, we
		would recommend that consideration be given to the subsequent inspection of excluded areas, where they exist, given these areas will have been excluded from scope of our report.<br />
		<strong>3.4 Exclusion of items from inspection&nbsp;</strong><br />
		The inspector need not inspect or report on the following:&nbsp;
		<ol type="a">
			<li>Items that outside of the agreed specific scope of the inspection&nbsp;</li>
			<li>Footings below ground.&nbsp;</li>
			<li>Concealed damp-proof course.&nbsp;</li>
			<li>
				All electrical, gas and plumbing items which under WA Legislation would require a Licenced Electrician, Plumber or Gas Fitter to test and inspect including but not limited to:
				<ul>
					<li>Utility conduits and services</li>
					<li>Electrical systems, security systems, access stems and components</li>
					<li>Solar and other sustainability infrastructure</li>
					<li>Lifts.</li>
					<li>Ventilation.</li>
					<li>Air conditioning systems.</li>
					<li>
						Electrical (or gas) appliances including but not limited to garbage disposal, electrical installations, light switches and fittings, dishwashers, incinerators, ovens, stoves, ducted vacuum systems.TV, sound and
						communications.
					</li>
					<li>Hot water systems.</li>
					<li>Swimming pools, spas, ponds, and pumps or filters.</li>
					<li>Water bores and water tanks.</li>
					<li>Back flow devices and pumping equipment</li>
					<li>Reticulation equipment&nbsp;</li>
					<li>Car stackers.</li>
					<li>Roof access safety equipment.</li>
					<li>Plumbing.&nbsp;</li>
					<li>Adequacy of roof drainage as installed.&nbsp;</li>
					<li>Gas fittings and fixtures.&nbsp;</li>
					<li>Automatic garage door mechanisms.&nbsp;</li>
				</ul>
			</li>
			<li>The operation of fireplaces and solid fuel heaters, including chimneys and flues.</li>
			<li>Paint coatings, except external protective coatings.&nbsp;</li>
			<li>Health hazards (eg allergies, lead content, presence of asbestos, soil toxicity)</li>
			<li>Timber and metal framing sizes and adequacy.&nbsp;</li>
			<li>Concealed tie-downs and bracing.&nbsp;</li>
			<li>Timber pest activity.&nbsp;</li>
			<li>Soil conditions.&nbsp;</li>
			<li>Control joints.&nbsp;</li>
			<li>Concealed framing-timber or any areas concealed by wall linings/sidings.&nbsp;</li>
			<li>Aluminium composite panels</li>
			<li>Furniture and accessories.&nbsp;</li>
		</ol>
	</>,

	<> 
		Consideration should be given to the inspection of excluded items by an appropriately qualified professional.&nbsp; The report does not include the inspection or assessment of items or matters that do not fall within the Inspectors direct
		expertise. Estimating the cost of remedying defects is not included in a Building Inspection report.&nbsp;
		<br /><br />
		<strong>4. Inspection process&nbsp;</strong><br />
		The inspection shall comprise visual appraisal and limited assessment of serviceability.&nbsp; The inspector shall document their findings in the inspection report.&nbsp; Where applicable the inspector may include recommendation for further
		inspections by a specialist inspector (Electricians, Plumbers, Structural Engineers, Fire Engineers, Geotechnical Engineer, Pest Inspectors and similar).&nbsp;<br />
		<br />
		<strong>5. Extent of Reporting&nbsp;</strong><br />
		The inspection report will comment on the agreed specific inspection scope criteria for individual inspections. The following general inspection scope criteria applies if it has not been excluded: 
		<ul>
			<li>Major structural defects&nbsp;</li>
			<li>General impressions in relation to major non-structural defects and minor defects</li>
			<li>Safety hazards&nbsp;</li>
		</ul>
		<strong>6. Defects</strong><br />
		During an inspection the inspector may identify that a building element is defective but that the defect does not fall neatly into one of the categories of defect. In such a case the inspector will use a combination of defect properties or
		otherwise assess and describe the defect in his/her own words, based on his/her experience.&nbsp; In many cases, the actual structural elements of a building will be obscured by finishes and other non-structural building elements, and the
		inspector may be unable to assess directly the state of the structural member. In such cases, the inspector has to infer the performance of the structure by observing the effect of the structure on the non-structural building elements. For
		example, the inspector normally will be unable to inspect the footings of a building as they are buried beneath the ground; however, cracking in non-structural masonry walls above the ground may indicate that a defect exists within the
		footing system.&nbsp;<br /><br />
		<strong>7. Inspections for Major Structural Defects</strong><br />
		An inspection for major structural defects shall comprise visual assessment of accessible areas of the property to identify major structural defects to the building and structures within 30 meters to form an opinion regarding the general
		condition of the structures. AS4349.1 Inspection of Buildings. A defect of sufficient magnitude where rectification has to be carried out in order to avoid unsafe conditions, loss of utility or further deterioration of the property.

	</>,

	<> 
		Consistent with AS4349.1 Appendix A and The Real Estate Institute of WA (“REIWA”) Pre-Purchase Annexure and assessment of Major Structural Defects <u>excludes any assessment or comment on the following</u>:&nbsp;
		<ul>
			<li>
				Any non-structural element, e.g. roof plumbing and roof covering, general gas, water and sanitary plumbing, electrical wiring, partition walls, cabinetry, windows, doors, trims, fencing minor structures, non-structural damp issues,
				ceiling linings, floor coverings, decorative finishes such as plastering, painting, tiling etc.
			</li>
			<li>An assessment of any aspect or component of the property that cannot be seen or that requires testing and/or measurement to determine soundness.</li>
			<li>Any area or item that was not, or could not be, observed by the inspector.&nbsp;</li>
			<li>General maintenance other than that which is deemed to be directly related to the ongoing structural performance of the property.</li>
			<li>
				Serviceability damp defects such as condensation, rising damp, lateral damp, falling damp should only be assessed and reported on where structural damage has occurred, is occurring, or may occur (eg fungal rot) significant spalling
				of masonry or concrete structural elements, significant fretting or mortar, rusting of primary structural elements. Stormwater drainage and surface water defects commonly cause or exacerbate foundation instability and these issues
				should be assessed and reported where relevant.
			</li>
		</ul>
		Based on the above a major structural defect is defined as a major defect on a structural element of the property structures having regard to the items that must be excluded as defined above. It is possible that a building may have major or
		minor defects which are not major structural defects.&nbsp;<br />
		<br />
		<strong>7.1 Major Structural Defects</strong><br />
		A building or structure that is declared as having “a major structural defect” is a building or structure in which there is a major defect on a structural element visible at the time of inspection in a building load bearing member
		(foundation, footings, walls, column, wall frame, floor frame ceiling frame, roof frame) which will worsen over time under normal building dead loads, live loads and wind loads even if given normal maintenance.&nbsp; The defect will require
		extraordinary maintenance, repair, replacement or additional structural support to be stabilised so that the building can then be considered structurally sound.&nbsp; If the defect is not rectified the structural defect will lead to failure
		of the structural element affected, other structural elements around it, and/or cause a safety issue to the occupant or normal user of the building.&nbsp; Examples of “major structural defects” include:&nbsp;
		<ul>
			<li>Failure or movement in structural building element&nbsp;</li>
			<li>Compromised foundation materials which may be contributing to subsidence</li>
			<li>Lack of tie down straps to a metal roofed building built after 1978 with original roof, or an older building which has had roof cover changed from tiles to metal or asbestos to metal,</li>
			<li>Severe damage to a structural member&nbsp;</li>
			<li>Alteration of structural elements – i.e Skylights cut through rafters or ceiling joists producing situations where someone working on or in the roof could be in danger</li>
		</ul>
		It is possible to declare the building as having no major structural defects”, but the verandah, patio, carport as containing “major structural defects”.&nbsp;

	</>,

	<> 
		<strong>7.2 Major Non-Structural Defects</strong><br />
		“Major non-structural defects” means defects visible at the time of the inspection to the building only taking into account the age and the type of the building, requiring substantial repairs or urgent attention and rectification. Major
		non-structural defects do not include minor defects that are common to most properties including minor blemishes, minor corrosion, minor cracking, minor weathering, general deterioration, unevenness, and physical damage to materials and
		finishes. Major non-structural defects do not include maintenance items which are items of repair common to buildings of similar age or construction type.&nbsp;
		<ul>
			<li>Defects and issues associated with roof cover and roof plumbing including water ingress</li>
			<li>Non-structural cracking&nbsp;</li>
			<li>Collapsing ceilings &nbsp;</li>
			<li>Water ingress issues (including rising damp, lateral damp, and damp associated with plumbing issues)</li>
			<li>Balcony, balustrade and stair noncompliance issues.&nbsp;</li>
			<li>Missing fire walls.&nbsp;</li>
			<li>Swimming pool fences missing.&nbsp;</li>
		</ul>
		<strong>7.3 Minor defects</strong><br />
		A defect other than a major defect that requires immediate intervention to avert further deterioration.&nbsp; The report shall describe the overall extent of minor defects. The inspector is not required to comment on individual minor
		defects and imperfections.&nbsp; Minor defects are common to most properties and may include minor blemishes, corrosion, cracking, weathering, general deterioration, unevenness, and physical damage to materials and finishes, such as
		de-silvering of mirrors. It is expected that defects of this type would be rectified as part of normal ongoing property maintenance.&nbsp; Pre-Purchase Building inspections will provide a general impression in relation to minor
		defects.&nbsp;<br />
		<br />
		<strong>8. Safety Items and Hazards</strong><br />
		Based on visual inspection the inspector will attempt to comment on safety items which are subject to specific legislation in WA. These includes:
		<ul>
			<li>The existence and location of smoke alarms&nbsp;</li>
			<li>The existence of Residual Current Devices (RCD’s)</li>
		</ul>
		Where these items are not in situ they many constitute a Safety item. Clients should always consider engaging an Electrical Contractor to review the Compliance of Smoke Alarms and RCD’s to Legislative requirements. Based on visual
		inspection the inspector will attempt to visually identify any observed item that constitutes a present or imminent serious safety hazard.&nbsp;<br />

		<br />
		<strong>9. Categorising Conditions</strong><br />
		Houspect has tried to categorise our gradings of material condition as: 
		<ul>
			<li>Satisfactory - in relation to its age and will require normal ongoing maintenance. </li>
			<li>Fair – will require maintenance within the near future. </li>
			<li>Poor - Needs maintenance or replacement now.</li>
		</ul>

		Wherever we describe a building material in this report, the client acknowledges that the material described represents a substantial component of the building material observed.

	</>
]