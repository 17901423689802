import React, {useState, useEffect} from "react";

//Import helpers
import { postRequestWithoutToken } from "../../helpers/apiHelper";


const Photo = ({inspectorUserID, jobID, fileName, classString, signedURLArray}) => {
	
    const [srcString, setSrcString] = useState("");

    useEffect(() => {
        signedURL()
    }, []);

	const signedURL = async () => {
		
		if(!fileName || (fileName === "") || (fileName === null)) {
			return null;
		} else {
			try {
				var index = fileName.lastIndexOf("/");
				var length = fileName.length;
				var cloudinaryFileName = inspectorUserID + '-' + jobID + '-' + fileName.substring(index+1, length);
				let filteredSignedURLArray = signedURLArray.filter(arrayObj => arrayObj.cloudinaryFileName === cloudinaryFileName);
				let signedURL = filteredSignedURLArray[0].signedURL
				setSrcString(signedURL)
			}catch(e) {
				console.log(e);	
			}
		}		
	}

	return (
		<>
			<img className={classString} alt="" src={srcString}/>  
		</>
	);
};

export default Photo;
