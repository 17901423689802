import React from "react";
import { FormFeedback, Input } from "reactstrap";

const InputGroupRadio = ({
  inputName,
  inputLabelName,
  handleRadioCheck,
  setFieldValue,
  required = false,
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
}) => {
  const options = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={inputName}>
        {inputLabelName}
        {required && <span className="text-danger">*</span>}
      </label>
      <div
        className="form-check form-check-inline"
        style={{
          width: "102px",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {options.map((option, i) => {
          return (
            <div className="" key={i}>
              <input
                id={`${option.label}-${i}-${inputName}`}
                className="form-check-input"
                type="radio"
                name={inputName}
                onChange={handleChange}
                value={option.value}
                checked={values[inputName] === option.value}
              />
              <label htmlFor={`${option.label}-${i}-${inputName}`}>{option.label}</label>
            </div>
          );
        })}
      </div>
      {!!touched[inputName] && !!errors[inputName] && (
        <FormFeedback>{errors[inputName]}</FormFeedback>
      )}
    </div>
  );
};

export default InputGroupRadio;
