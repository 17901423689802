import React, { useEffect, useRef, useState } from "react";
// import boostrap 
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";





const IncompleteJobFormModal = ({ visible, onClose }) => {

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>Job form error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Please ensure all required job fields are submitted. </p>
                    <p>Required job fields are:</p>
                    <ul>
                        <li>Job status</li>
                        <li>Inspection class</li>
                        <li>Inspection type</li>
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IncompleteJobFormModal;
