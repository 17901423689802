import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { inpectionTypePost } from "../helpers/InspectionType";
import { postRequest } from "../helpers/apiHelper";
const inspectionContext = createContext(null);

export const inspection = () => {
  return useContext(inspectionContext);
};

const inspectionClassOptions = [
  { value: "", label: "Select inspection class" },
  { value: "other", label: "other" },
  { value: "Building inspections", label: "Building inspections" },
  { value: "Dilapidation inspections", label: "Dilapidation inspections" },
];

const InspectionTypeContext = ({ children }) => {
  const [classOptions, setClassOptions] = useState(inspectionClassOptions);
  const [inspectionTypeList, setInspectionTypeList] = useState([]);

  const [inspectionClass, setInspectionClass] = useState([]);
  const [inspectionType, setInspectionType] = useState(null);
  const [selectClass, setSelectClass] = useState("");

  useEffect(() => {
    //Get inspection type list
    const getInspectionTypeList = async () => {
      const inspectionTypeListResponse = await postRequest(
        "/getInspectionTypeList"
      );
      setInspectionTypeList(inspectionTypeListResponse);
    };
    inspectionTypeList &&
      inspectionTypeList.length === 0 &&
      getInspectionTypeList();

    const classType =
      inspectionTypeList &&
      inspectionTypeList.length !== 0 &&
      inspectionTypeList.map((obj) => {
        return { value: obj.inspectionClass, label: obj.inspectionClass };
      });

    classType && setInspectionClass(classType);
  }, [inspectionTypeList]);

  useEffect(() => {
    // if (selectClass !== "") {
    //   inpectionTypePost({ 'inspectionClass': selectClass }).then(data => {
    //     const arr = data && data.map((value) => {
    //       return { value: value['inspectionTypes'], label: value['inspectionTypes'] }
    //     })

    //     setInspectionType(arr);
    //   })
    // }

    if (selectClass !== "") {
      inspectionTypeList.map((value) => {
        if (value.inspectionClass === selectClass) {
          const arr = value.inspectionTypes.map((type) => {
            return { value: type, label: type };
          });
          setInspectionType(arr);
        }
      });
    }
  }, [selectClass]);

  return (
    <inspectionContext.Provider
      value={{
        inspectionClass,
        setInspectionClass,
        inspectionType,
        setInspectionType,
        setSelectClass,
      }}
    >
      {children}
    </inspectionContext.Provider>
  );
};

export default InspectionTypeContext;
