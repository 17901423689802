import React, { useContext } from "react";

//Import helpers
import { shortenAddress } from "../../helpers/generalHelper";

const CoverPage = (props) => {

	return (
		<>
			<div className="a4Page" id="coverPage">
				<div className="coverContent">
					<img id="coverLogo" alt="" src={props.organisationConfigRecord.organisationLogoCoverURL} />

					{!(props.inspectionJobRecord.originalInspectionJobID) > 0 && (
						<h3><b>{props.inspectionJobRecord.inspectionType}</b></h3>
					)} 
					{(props.inspectionJobRecord.originalInspectionJobID) > 0 && (
						<h3><b>Follow Up {props.inspectionJobRecord.inspectionType.replace('inspection', 'Inspection')}</b></h3>
					)} 
					<br />
					<p>
						<b>Job ID: </b> {props.inspectionJobRecord.jobID}
					</p>
					<p>
						<b>Address: </b> {shortenAddress(props.inspectionJobRecord.propertyAddress)}
					</p>
				</div>
			</div>
		</>
	);
};

export default CoverPage;
