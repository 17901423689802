import React from "react";

const SearchGroup = ({
  inputName,
  inputLabelName,
  handleSearchClick,
  search,
  onChange,
}) => {
  const handleOnSearch = (e) => {
    onChange(e);
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={inputName}>
        {inputLabelName}
      </label>
      <div
        className="form-find"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <input
          className="form-control"
          id={inputName}
          type="text"
          name={inputName}
          placeholder={"Search in database..."}
          value={search}
          onChange={handleOnSearch}
        />
        <a
          className={"searchGroupButton"}
          id={"submit" + { inputName }}
          name={"submit" + { inputName }}
          onClick={() => {
            handleSearchClick(search);
          }}
        >
          <p className={"searchGroupButtonText"}>Search</p>
        </a>
      </div>
    </div>
  );
};

export default SearchGroup;
