import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { mergeStyles } from "react-select";
import { FormFeedback } from "reactstrap";

// const regex = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/i;

const InspectionTimeGroup = ({ inputName, inputLabelName, required = false, touched, errors, values, setFieldValue }) => {

    const [disable, setDisable] = useState(true)
    const [time, setTime] = useState("");

    const handleChange = (e) => {
        setTime(e.target.value)
        let Time = (e.target.value).split(" ")[0];
        let Meridiem = (e.target.value).split(" ")[1];

        // //console.log(Time,'time', Meridiem, 'merid')
        // //console.log((/^(1[0-2]|0?[1-9]):[0-5][0-9]$/).test(Time))

        if (((/^(1[0-2]|0?[1-9]):[0-5][0-9]$/).test(Time) && (/^(AM|PM)$/).test(Meridiem))) {
            if (Meridiem.length > 2) {
                //console.log('mergeStyles', Time.concat(" ", Meridiem.slice(0, 2)))
                setDisable(true)
                setTime(Time.concat(" ", Meridiem.slice(0, 2)))
                setFieldValue(inputName, Time.concat(" ", Meridiem.slice(0, 2)))
            } else {
                setDisable(true);
                setTime(e.target.value)
                setFieldValue(inputName, Time.concat(" ", Meridiem))
            }
        }
        else if (Meridiem !== undefined && Meridiem.length > 2) {

            let concate = Meridiem.slice(0, 2)
            setTime(Time.concat(" ", concate))
            setDisable(true)
            setFieldValue(inputName, Time.concat(" ", Meridiem.slice(0, 2)))

        } else if (Meridiem !== undefined && /^[^AP]/.test(Meridiem)) {
            setTime(Time.concat(" "))
        }
        
        else if ((Meridiem === undefined || Meridiem !== undefined) && !((/^[ 0-9:]*$/).test(Time))) {
            setTime(Time.slice(0, Time.length-1))
        }
        
        else if (Meridiem === undefined) {
            setTime(e.target.value)
        }

    }

    useEffect(() => {
        setTime(time => values[inputName]);
    }, [values[inputName]])

    useEffect(() => {
        setDisable(disable => values[inputName] === "" ? true : false)
    }, [values[inputName]])


    if(window.location.pathname === "/editJob"){
        return (
            <div className="form-group">
                <label className="form-label" htmlFor={inputName}>
                    {inputLabelName}
                    {required &&
                        <span className="text-danger">*</span>
                    }
                </label>
                <input
                    invalid={touched[inputName] && errors[inputName]}
                    className="form-control"
                    type="text" onChange={(e) => handleChange(e)}
                    disabled={true}
                    placeholder={inputLabelName}
                    name={inputName}
                    value={time}
                />
                {
                    touched[inputName] && errors[inputName] && (
                        <FormFeedback>
                            {errors[inputName]}
                        </FormFeedback>
                    )
                }
            </div>
        );
    }


    return (
        <div className="form-group">
            <label className="form-label" htmlFor={inputName}>
                {inputLabelName}
                {required &&
                    <span className="text-danger">*</span>
                }
            </label>
            <input
                invalid={touched[inputName] && errors[inputName]}
                className="form-control"
                type="text" onChange={(e) => handleChange(e)}
                disabled={disable} onFocus={() => setDisable(disable => false)}
                onBlur={() => setDisable(disable => time.length > 0 ? false : true)}

                placeholder={inputLabelName}
                name={inputName}
                value={time}
            />
            {
                touched[inputName] && errors[inputName] && (
                    <FormFeedback>
                        {errors[inputName]}
                    </FormFeedback>
                )
            }
        </div>
    );
};

export default InspectionTimeGroup;
