import { Formik, Form, Field, ErrorMessage } from "formik";

import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import DatePicker from "react-datepicker";
import locale from 'date-fns/locale/en-GB'
import "react-datepicker/dist/react-datepicker.css";

import { momentFormate } from "../../helpers/generalHelper";
//Import components 
import InputGroupDropdown from "./jobForm/InputGroupDropdown";
import moment from "moment";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";
import ScheduleGroupDropdown from "./schedule/ScheduleGroupDropdown";


const ScheduleModalAppointment = ({ appointmentRecordForModal, action, visible, onClose, setLoadingModalVisible, setConfirmationModalVisible, returnAndLoadSchedule }) => {

    const appointmentTypeOptions = [
        { value: 'Personal leave', label: 'Personal leave' },
        { value: 'Holiday', label: 'Holiday' },
        { value: 'Unavailable', label: 'Unavailable' },
        { value: 'Job hold', label: 'Job hold' }
    ]

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const submitbtn = useRef(null);

    const [isForm, setIsFormChange] = useState(false);

    const [userID, setuserID] = useState(null);

    const [userIDOptions, setuserIDOptions] = useState([{ value: '', label: 'Select' }]);
    const [inspectorObjArray, setInspectorObjArray] = useState([]);

    const [officeUserID, setOfficeUserID] = useState(null);

    

	useEffect(() => {
		getCurrentUserAttributes();
	}, []);

	const getCurrentUserAttributes = async () => {		
		const currentUserAttributes = await postRequest("/getCurrentUserAttributes", {});
        console.log(currentUserAttributes)
        setOfficeUserID(currentUserAttributes.name);
        /**
		setUserRoles(currentUserAttributes.roles);
		setOrg(currentUserAttributes.org);
    	setDisplayName(currentUserAttributes.name); */
	}

	const deleteAppointment = async (appointmentID) => {
		setLoadingModalVisible(true);

		const response = await postRequestReturnAll("/deletePrivateAppointment", {appointmentID: appointmentID});
		// console.log(response, 'response')
		setLoadingModalVisible(false);
		if (response.status === 200 || response.status === 201 || response.status === 202) {
			//Job successfully created / updated
			setConfirmationModalVisible(true);
			setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadSchedule() }, 2000);

		} else {
			//Show error
			console.log('An error has occurred when deleting an appointment')
		}
	};

    const showTable = () => {
        const [form, setForm] = useState({
            initialValues: {
                appointmentID: '',
                officeUserID: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).displayName : "",
                userID: '',
                appointmentType: 'Personal leave',
                appointmentStartDate: momentFormate(startDate),
                appointmentEndDate: momentFormate(endDate),
                appointmentStartTime: '00:00',
                appointmentEndTime: '23:59',
                appointmentComments: ''
            },
        })

        useEffect(() => {
            const getInspectorUserObjArray = async () => {
                const inspectorObjArray = await postRequest("/getInspectorUserObjArray");
                setInspectorObjArray(inspectorObjArray);
                let tempInspectorObjArray = []
                tempInspectorObjArray.push({ value: '', label: 'Select' });
                inspectorObjArray.forEach((inspectorObj) => {
                    tempInspectorObjArray.push({ value: inspectorObj.uid, label: inspectorObj.displayName })
                })
                setuserIDOptions(tempInspectorObjArray);
            }

            getInspectorUserObjArray();

        }, []);


        useMemo(() => {

            if (appointmentRecordForModal && Object.keys(appointmentRecordForModal).length !== 0) {

                const sd = new Date(appointmentRecordForModal.appointmentStartDate.split('-').reverse().join("-"))
                const ed = new Date(appointmentRecordForModal.appointmentEndDate.split('-').reverse().join("-"))

                setStartDate(sd)
                setEndDate(ed);
                
                // setFormValue(appointmentRecordForModal, sd, ed);

                setForm({
                    initialValues: {
                        ...form.initialValues,
                        appointmentID: appointmentRecordForModal.appointmentID,                        
                        officeUserID: appointmentRecordForModal.officeUserID,
                        appointmentType: appointmentRecordForModal.appointmentType,
                        userID: appointmentRecordForModal.userID,
                        appointmentStartDate: momentFormate(sd),
                        appointmentEndDate: momentFormate(ed),
                        appointmentStartTime: appointmentRecordForModal.appointmentStartTime,
                        appointmentEndTime: appointmentRecordForModal.appointmentEndTime,
                        appointmentComments: appointmentRecordForModal.appointmentComments
                    }
                })
            }else{

                setForm({
                    initialValues: {
                        appointmentID: '',
                        officeUserID: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).displayName : "",
                        userID: '',
                        appointmentType: 'Personal leave',
                        appointmentStartDate: momentFormate(startDate),
                        appointmentEndDate: momentFormate(endDate),
                        appointmentStartTime: '7:00 AM',
                        appointmentEndTime: '6:00 PM',
                        appointmentComments: ''
                    },
                })
            }

        }, [appointmentRecordForModal]);

       
        const getDateVal = (dateString, timeString) => {
            //dateString will be in the format "21-03-2022"	
            //timeString will be in the format "09:00"
            
            let dateStartArray = dateString.split("-"); //[21,03,2022]
            let dateObj = new Date();
            dateObj.setDate(Number(dateStartArray[0]));
            dateObj.setMonth(Number(dateStartArray[1])-1);
            dateObj.setYear(Number(dateStartArray[2]));

            let timeStringClean = timeString.replace(' AM','').replace(' PM',''); //"09:00" 
            const timeArray = timeStringClean.split(":"); //[09,00]
            
            if(timeString.includes("PM")) {
                dateObj.setHours(Number(timeArray[0])+12, Number(timeArray[1]),0); //Set time in (hours, minutes, seconds)            
            } else {
                dateObj.setHours(Number(timeArray[0]), Number(timeArray[1]),0); //Set time in (hours, minutes, seconds)                            
            }
            
            let dateVal = dateObj.getTime()
            return dateVal
        }


        const submitForm = async (values) => {
            setLoadingModalVisible(true);
            console.log('Form values:>>>>>>>>>> ', values)
            setForm(form)

            let formValues = values;

            if(formValues.userID == '') {
                alert('Please select an inspector')
                setLoadingModalVisible(false);
            } else {
            
                let appointmentStartDateVal = getDateVal(values.appointmentStartDate, values.appointmentStartTime);
                let appointmentEndDateVal = getDateVal(values.appointmentEndDate, values.appointmentEndTime);
    
                formValues.appointmentStartDateVal = appointmentStartDateVal;
                formValues.appointmentEndDateVal = appointmentEndDateVal;

                formValues.officeUserID = officeUserID;
    
                let response;
    
                if(formValues.appointmentID == ''){
                    response = await postRequestReturnAll("/createPrivateAppointment", values);    
                }else{
                    response = await postRequestReturnAll("/updatePrivateAppointment", values);
                }
                // console.log(response, 'response')
                setLoadingModalVisible(false);
                if (response.status === 200 || response.status === 201 || response.status === 202) {
                    //Job successfully created / updated
                    setConfirmationModalVisible(true);
                    
                    setTimeout(function () { setConfirmationModalVisible(false); returnAndLoadSchedule(); }, 2000);
    
                } else {
                    //Show error
                    console.log('An error has occurred when submitting an appointment to the server')
                }

            }
        };



        return (
            <>
                <Formik initialValues={form.initialValues}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm()
                    }}>

                    {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <Form name='appointment' onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                            return false
                        }}>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentID">Appointment ID<span className="text-danger">*</span> - Populated after appointment creation</label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="appointmentID"
                                            name="appointmentID"
                                            value={values.appointmentID}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="officeUserID">Office UserID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="officeUserID"
                                            name="officeUserID"
                                            value={values.officeUserID}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <ScheduleGroupDropdown
                                            inputName={"userID"}
                                            inputLabelName={"Inspector"}
                                            dropdownOptions={userIDOptions}
                                            dropdownHandler={setuserID}
                                            setFieldValue={setFieldValue}
                                            value={userIDOptions[0]}
                                            required={true}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            handleBlur={handleBlur} handleChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <ScheduleGroupDropdown
                                            inputName={"appointmentType"}
                                            inputLabelName={"Appointment type"}
                                            dropdownOptions={appointmentTypeOptions}
                                            // dropdownHandler={setuserID}
                                            setFieldValue={setFieldValue}
                                            value={userIDOptions[0]}
                                            required={true}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            handleBlur={handleBlur} handleChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentStartDate">Appointment start date<span className="text-danger">*</span></label>
                                        <DatePicker
                                            name="appointmentStartDate"
                                            className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            locale={locale}
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                setFieldValue("appointmentStartDate", momentFormate(date));
                                                //Only auto update end date if start date is after it
                                                if(date > endDate) {
                                                    setEndDate(date)
                                                    setFieldValue("appointmentEndDate", momentFormate(date))
                                                }
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentEndDate">Appointment end date<span className="text-danger">*</span></label>
                                        <DatePicker
                                            name="appointmentEndDate"
                                            className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            locale={locale}
                                            selected={endDate}
                                            onChange={(date) => {
                                                setEndDate(date)
                                                setFieldValue("appointmentEndDate", momentFormate(date))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentStartTime">Start Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            name="appointmentStartTime"
                                            value={values.appointmentStartTime}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentEndTime">End Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            type="text"
                                            name="appointmentEndTime"
                                            value={values.appointmentEndTime}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>

                            </div>
                                
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="appointmentID">Appointment comments</label>
                                        <textarea className="form-control focus:outline-none"
                                            rows={4}
                                            type="text"
                                            id="appointmentComments"
                                            name="appointmentComments"
                                            value={values.appointmentComments}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <button className="d-none" type="submit" ref={submitbtn}>submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>Scheduler - Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showTable()}
            </Modal.Body>
            <Modal.Footer>
               {
                appointmentRecordForModal !== null && 
                <Button type='submit' form='appointment' variant="danger" onClick={() => {
                    deleteAppointment(appointmentRecordForModal.appointmentID);
                    onClose();
                }}>
                    Delete
                </Button>
               } 
               
                <Button type='submit' form='appointment' variant="success" onClick={() => {
                    submitbtn.current.click();
                    onClose();
                }}>
                    {appointmentRecordForModal !== null && (<span>Update</span>)}
                    {appointmentRecordForModal == null && (<span>Create</span>)}
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleModalAppointment;
