import React from "react";
import { useState } from "react";
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";

const InputGroupText = ({
  inputName,
  handleSearchClick,
  handleClear,
  search,
  setSearch,
  setLoadingModalVisible
}) => {
  const [text, setText] = useState("");

  const handleOnSearch = (e) => {
    setText(() => e.target.value)
    // setSearch(() => e.target.value);
  };


  //<button type="button" className="btn btn-dark rounded ml-2" onClick={() => handleSearchClick(search)}>Select all</button>

  //<button type="button" className="btn btn-dark rounded ml-2" onClick={() => handleSearchClick(search)}>Select all</button>

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center p-1">
          <h6 className="m-0 font-weight-bold text-dark">Text filter</h6>

          <div className=" h-auto text-center p-0">
            <button
              type="button"
              className="btn btn-dark mx-1"
              onClick={(e) => {
                setLoadingModalVisible(true)
                handleSearchClick(text);

              }}
            >
              Search
            </button>

            <button
              type="button"
              className="btn btn-danger mx-1"
              onClick={(e) => {
                setLoadingModalVisible(true)
                setText("")
                handleSearchClick("");

              }}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="card-body p-2">
          <div
            className="form-find"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <input
              className="form-control"
              style={{ padding: 0, paddingLeft: 5, fontSize: "12px" }}
              id={inputName}
              type="text"
              name={inputName}
              placeholder={"Enter keyword"}
              value={text}
              onChange={handleOnSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InputGroupText;
