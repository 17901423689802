import React, { useState, useEffect } from "react";

import "./../../../../src/assets/stylesheets/report/generalReport.css";
import { useSearchParams } from "react-router-dom";

//Import components
// import Header from "./../Header";
// import Footer from "./../Footer";
import CoverPage from "./../1CoverPage";
import PropertySummary from "./../2PropertySummary";
import ReportSummary from "./../4ReportSummary";
import IssueFlagsGeneralBuilding from "../5IssueFlagsGeneralBuilding";
import ItemIssuePage from "../7ItemIssuePageGeneralBuilding";
import AdditionalIssuePhotos from "../9AdditionalIssuePhotos";
import MaintenancePlanSummary from "../9MaintenancePlanSummary";
import MaintenancePlan from "../9MaintenancePlan";
import Scope from "../10Scope";
import Conditions from "../10Conditions";

//Import helpers
import {
  // postRequest,
  postRequestWithoutToken,
} from "../../../helpers/apiHelper";

//Import scope sections
import { scopePagesArray } from "./scope";
import { conditionsPagesArray } from "./conditions";

const sectionHeaderArray = [
	"Report summary",
	"Inspection summary",
	"Inspection details",
	"Appendix",
]

const Report = (props) => {
	const editMode = props.editMode;
	const [loading, setLoading] = useState(false);
	const [organisationConfigRecord, setOrganisationConfigRecord] = useState({});
	const [inspectionJobRecord, setInspectionJobRecord] = useState({});
	const [inspectionObj, setInspectionObj] = useState({});
	const [signedURLArray, setSignedURLArray] = useState([]);

	const [params] = useSearchParams();

	//Get organisation config record
	const getOrganisationConfigRecord = async () => {
		const organisationConfigRecord = await postRequestWithoutToken(
			"/getOrganisationConfigRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
		console.log(organisationConfigRecord)
		organisationConfigRecord &&
			setOrganisationConfigRecord(organisationConfigRecord[0]);
	};

	//Get the signed URL array
	const getSignedURLArray = async () => {
		const signedURLArrayResponse = await postRequestWithoutToken(
			"/getSignedURLForImageBulk",
			{ reportToken: params.get("reportToken") }
		);
		console.log(signedURLArrayResponse)
		
		signedURLArrayResponse &&
			setSignedURLArray(signedURLArrayResponse);			
	};

	//Get the inspection job record and index inspection items
	const indexInspectionItems = (itemOrderArray, inspectionJobRecordRaw) => {
		let inspectionObjRaw = JSON.parse(inspectionJobRecordRaw.inspectionObjString)
		let inspectionItems = inspectionObjRaw.items;

		inspectionItems.forEach(function (element, index) { 
			let itemIndex = itemOrderArray.indexOf(element.itemName);
			//Item index will be -1 if item name no longer exists in config file. In case the inspection was done and an update to the configuration file was made. 
			if(itemIndex == -1) {
				inspectionItems[index].itemIndex = 10000
			} else {
				inspectionItems[index].itemIndex = itemIndex;
			}
		})
		
		let inspectionItemsSorted = inspectionItems.sort(function(a,b){
			return a.itemIndex - b.itemIndex;
			}
		);

		let newInspectionObj = {...inspectionObjRaw}
		newInspectionObj.items = inspectionItemsSorted;
		let newInspectionJobRecord = {...inspectionJobRecordRaw}
		newInspectionJobRecord.inspectionObjString = JSON.stringify(newInspectionObj);

		setInspectionObj(newInspectionObj);		
		setInspectionJobRecord(newInspectionJobRecord);
        localStorage.setItem(`reportEditor${newInspectionJobRecord.jobID}`, JSON.stringify(newInspectionObj));
	};

	const getInspectionJobRecord = async () => {
		const inspectionJobRecordResponse = await postRequestWithoutToken(
			"/getInspectionJobRecordWithReportToken",
			{ reportToken: params.get("reportToken") }
		);
	
		const itemOrderArray = await postRequestWithoutToken(
			"/getItemOrderForBuildingReport",
			{ reportToken: params.get("reportToken") }
		);

		try {
			let inspectionJobRecordRaw = inspectionJobRecordResponse[0];
			//Index the items in order before setting the variable
			indexInspectionItems(itemOrderArray, inspectionJobRecordRaw);
		} catch (e) {console.log(e)}
	};

  	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		console.log('Edit mode: ' + editMode)

		getOrganisationConfigRecord();
		getInspectionJobRecord();		
		getSignedURLArray();

		setTimeout(function(){
			populatePageNumbers();
		}, 3000);
  	}, []);

	const populatePageNumbers = () => {
		var pageNumberElementArray = document.querySelectorAll(".pageNumber");
		let totalPages = pageNumberElementArray.length + 1; //Account for cover page
		pageNumberElementArray.forEach(function (element, index) { 
			element.innerHTML = `Page ${index+2} of ${totalPages}`	//Start with page 1 and cover page doesn't have a page number class
		})
		}

	const itemIssuePages = () => {
		let itemIssuePagesJSX = [];
		if (Array.isArray(inspectionObj.items)) {	
			let inspectionItems = inspectionObj.items;	
			for (var i = 0; i < inspectionItems.length; i++) {
				itemIssuePagesJSX.push(
					<ItemIssuePage
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={2}
						editMode={editMode}
						itemIndex={i + 1}
						inspectionItem={inspectionItems[i]}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						signedURLArray={signedURLArray}
					/>
				);
			}
			return itemIssuePagesJSX;
		}
	};


	const additionalIssuePhotoPages = () => {
		let allAdditionalIssuePhotosJSX = [];
		let additionalIssuePhotosArray = [];
		if (Array.isArray(inspectionObj.items)) {
			let inspectionItems = inspectionObj.items;
			for (const item of inspectionItems) {
				if (Array.isArray(item.itemIssues)) {
					let itemIssues = item.itemIssues;
					if (itemIssues.length > 0) {
						for (const issue of itemIssues) {
							for (const additionalIssuePhoto of issue.additionalIssuePhotos) {
								additionalIssuePhotosArray.push({
									itemName: item.itemName,
									issueName: issue.issueName,
									additionalIssuePhoto: additionalIssuePhoto,
								});
								//If full page reached, generate the page
								if (additionalIssuePhotosArray.length === 16) {
									allAdditionalIssuePhotosJSX.push(
										<AdditionalIssuePhotos
											sectionHeaderArray={sectionHeaderArray}
											sectionHeaderIndex={2}
											additionalIssuePhotosArray={additionalIssuePhotosArray}
											organisationConfigRecord={organisationConfigRecord}
											inspectionJobRecord={inspectionJobRecord}
											inspectionObj={inspectionObj}
											inspectionNotesObj={inspectionObj.inspectionNotesObj}
											signedURLArray={signedURLArray}
										/>
									);
									//Reset the array to remove the 16 photos
									additionalIssuePhotosArray = [];
								}
							}
						}
					}
				}
			}
			console.log(inspectionItems)
			//Check for any remaining photos to be added to a page, only not called if there are 16, 32, 48 etc. additional issue photos
			if (additionalIssuePhotosArray.length > 0) {
				allAdditionalIssuePhotosJSX.push(
					<AdditionalIssuePhotos
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={2}
						additionalIssuePhotosArray={additionalIssuePhotosArray}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						inspectionNotesObj={inspectionObj.inspectionNotesObj}
						signedURLArray={signedURLArray}
					/>
				);
			}
		}
		return allAdditionalIssuePhotosJSX;
	};


	const maintenancePlanPages = () => {
		let maintenancePagesJSX = [];
		let maintenanceIssuesArray = [];
		if (Array.isArray(inspectionObj.items)) {
			let inspectionItems = inspectionObj.items;
			for (var i = 0; i <inspectionItems.length; i++) {
				let item = inspectionItems[i]
				if (Array.isArray(item.itemIssues)) {
					let itemIssues = item.itemIssues;
					if (itemIssues.length > 0) {
						for (var j = 0; j <itemIssues.length; j++) {
							let issue = itemIssues[j];
							if(issue.issueMaintenanceCostTimeline) {
								maintenanceIssuesArray.push({
									itemIndex: i+1,
									issueIndex: j+1,
									itemName: item.itemName,
									issueName: issue.issueName,
									issueMaintenanceCost: Number(issue.issueMaintenanceCost),
									issueMaintenanceCostTimeline: issue.issueMaintenanceCostTimeline
								});
							}
						}
					}
				}

			}
			
			let rowsPerPage = 33;
			let noOfMaintenanceIssues = maintenanceIssuesArray.length;
			let pagesRequired = Math.ceil(noOfMaintenanceIssues / rowsPerPage);
			//Check for any remaining photos to be added to a page, only not called if there are 16, 32, 48 etc. additional issue photos
			if (maintenanceIssuesArray.length > 0) {
				//Provide the summary page				
				maintenancePagesJSX.push(
					<MaintenancePlanSummary
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={2}
						maintenanceIssuesArray={maintenanceIssuesArray}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						signedURLArray={signedURLArray}
					/>
				);
				for (let page = 0; page < pagesRequired; page++) {
					maintenancePagesJSX.push(
						<MaintenancePlan
							sectionHeaderArray={sectionHeaderArray}
							sectionHeaderIndex={2}
							maintenanceIssuesArray={maintenanceIssuesArray}
							pageIndex={page}
							rowsPerPage={rowsPerPage}
							organisationConfigRecord={organisationConfigRecord}
							inspectionJobRecord={inspectionJobRecord}
							inspectionObj={inspectionObj}
							signedURLArray={signedURLArray}
						/>
					);
				}
			}
		}
		return maintenancePagesJSX;
	};

	const scopePages = () => {
		let scopePagesJSX = []
		
		for (var i = 0; i < scopePagesArray.length; i++) {
			scopePagesJSX.push(				
				<Scope
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={scopePagesArray[i]}
					/>
			)
		};

		return scopePagesJSX;
	}

	const conditionsPages = () => {
		let conditionsPagesJSX = []
		for (var i = 0; i < conditionsPagesArray.length; i++) {
			conditionsPagesJSX.push(				
				<Conditions
					sectionHeaderArray={sectionHeaderArray}
					sectionHeaderIndex={3}
					organisationConfigRecord={organisationConfigRecord}
					inspectionJobRecord={inspectionJobRecord}
					inspectionObj={inspectionObj}
					pageBodyJSX={conditionsPagesArray[i]}
					/>
			)
		};

		return conditionsPagesJSX;
	}
	



	if(loading) {
		return <></>
	} else {
		//Need to make sure signed URL array is ready 
		if(signedURLArray.length > 0 ) {
			return (
				<>
					{/*Cover page*/}
					<CoverPage
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
					/>

					{/*Property summary photo and property description*/}
					<PropertySummary
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						editMode={editMode}
						signedURLArray={signedURLArray}
					/>

					{/*Report summary*/}
					<ReportSummary
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={0}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						reportPurpose={"The purpose of this report is to identify major structural defects or faults in the construction of the building and associated structures in so far as a Registered Builder can reasonably and visually identify those defects or faults. The inspection is conducted in accordance with AS 4349.1. The report must be read in full."}
						/>

					{/*Issue flags - Building only*/}
					<IssueFlagsGeneralBuilding
						sectionHeaderArray={sectionHeaderArray}
						sectionHeaderIndex={1}
						organisationConfigRecord={organisationConfigRecord}
						inspectionJobRecord={inspectionJobRecord}
						inspectionObj={inspectionObj}
						/>
						
					{/*Items and ssues*/}
					{itemIssuePages()}

					{/*Additional issue photos*/}
					{additionalIssuePhotoPages()}

					{/*Additional issue photos*/}
					{maintenancePlanPages()}

					{/*Scope*/}
					{scopePages()}

					{/*Condtitions*/}
					{conditionsPages()}
				</>
			);
		}
	}
};

export default Report;