import React, { useContext } from "react";

//Import helpers
import { shortenAddress } from "../../helpers/generalHelper";

const Header = ({organisationConfigRecord, inspectionJobRecord}) => {

  const clientHeaderText = () => {
    let corporateOrganisation = inspectionJobRecord.corporateOrganisation;
    let clientFullName = inspectionJobRecord.clientFullName;
    if (corporateOrganisation === null || corporateOrganisation === '' || corporateOrganisation === undefined) {
      return clientFullName
    } else {
      return corporateOrganisation
    }
  }
  return (
    <>
      <div className="headerSection">
        <div className="headerSectionLeft">
          <img className="headerLogo landscapePhoto" alt="" src={organisationConfigRecord.organisationLogoHeaderURL} />
        </div>
        <div className="headerSectionCenter">
          <p className="headerSectionCenterText">
            <strong>Client:</strong> {clientHeaderText()}
          </p><br/>
          <p className="headerSectionCenterText">
          <strong>Address:</strong> {shortenAddress(inspectionJobRecord.propertyAddress)}
          </p><br/>
          <p className="headerSectionCenterText">
            <strong>Inspected on:</strong> {inspectionJobRecord.inspectionDate}
          </p>
        </div>
        <div className="headerSectionRight">
          <span className="pageNumber">Page x</span>
        </div>
      </div>
      <div className="headerSectionBar" />
    </>
  );
};

export default Header;
