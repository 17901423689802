import React, { useEffect, useRef, useState } from "react";

// import formik 
import { Formik, Form, Field, ErrorMessage } from "formik";

// date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import locale from 'date-fns/locale/en-GB'
import { momentFormate } from "../../helpers/generalHelper";

//Import components 
import InputGroupDropdown from "./jobForm/InputGroupDropdown";
import moment from "moment";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";

// import boostrap 
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { InspectGroupDropdown } from "./jobForm";





const ScheduleModalJob = ({ jobRecordForModal, visible, onClose }) => {

    const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    const submitbtn = useRef(null);

    // const [appointmentModalInspectorUserID, setAppointmentModalInspectorUserID] = useState(null);

    const [inspectorUserIDOptions, setInspectorUserIDOptions] = useState([{ value: '', label: 'Select' }]);
    const [inspectorObjArray, setInspectorObjArray] = useState([]);



    const showTable = () => {

        const [form, setForm] = useState({
            initialValues: {
                inspectionClass: "",
                inspectionType: "",
                jobStatus: "",
                inspectorUserID: "",
                officeUserID: "",
                jobID: "",
                inspectionDate: null,
                inspectionStartTime: "",
                inspectionEndTime: "",
                propertyAddressd: "",
                clientFullName: "",
                corporateOrganisation: ""
            },
        })

        useEffect(() => {
            const getInspectorUserObjArray = async () => {
                const inspectorObjArray = await postRequest("/getInspectorUserObjArray");
                setInspectorObjArray(inspectorObjArray);
                let tempInspectorObjArray = []
                inspectorObjArray.forEach((inspectorObj) => {
                    tempInspectorObjArray.push({ value: inspectorObj.uid, label: inspectorObj.displayName })
                })
                setInspectorUserIDOptions(tempInspectorObjArray);
            }

            getInspectorUserObjArray();

        }, []);

        const submitForm = async (values) => {
            // setLoadingModalVisible(true);
            console.log('Form values: ', values)
            // setForm(form)


            // const response = await postRequestReturnAll("/createPrivateAppointment", values);

            // if (response.status === 200 || response.status === 201 || response.status === 202) {

            // } else {
            //     //Show error
            //     console.log('An error has occurred when submitting an appointment to the server')
            // }
        };

        useEffect(() => {
            if (jobRecordForModal && Object.entries(jobRecordForModal).length !== 0) {
                let date = jobRecordForModal.inspectionDate.split('-').reverse().join("-");
                setStartDate(new Date(date))
            }
        }, [jobRecordForModal])


        return (
            <>

                <Formik initialValues={jobRecordForModal && Object.entries(jobRecordForModal).length !== 0 ? jobRecordForModal : form.initialValues}
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm()
                    }}>

                    {({ touched, errors, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <Form name='appointment' onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                            return false
                        }}>

                            <div className="row">

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectorUserID">Inspector UserID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="inspectorUserID"
                                            name="inspectorUserID"
                                            value={values.inspectorUserID}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="officeUserID">Office UserID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="officeUserID"
                                            name="officeUserID"
                                            value={values.officeUserID}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectorUserID">Job ID<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            id="jobID"
                                            name="jobID"
                                            value={values.jobID}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>                                
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="clientFullName">client Name<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="clientFullName"
                                            value={values.clientFullName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="corporateOrganisation">Corporate Organisation
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="corporateOrganisation"
                                            value={values.corporateOrganisation}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectionClass">
                                            Inspection class
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="inspectionClass"
                                            value={values.inspectionClass}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectionType">
                                            Inspection type
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="inspectionType"
                                            value={values.inspectionType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="propertyAddress">
                                            Property address
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="propertyAddress"
                                            value={values.propertyAddress}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectionDate">Inspection date<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="inspectionDate"
                                            value={values.inspectionDate}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectionStartTime">Inspection Start Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="inspectionStartTime"
                                            value={values.inspectionStartTime}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="inspectionEndTime">Inspection End Time<span className="text-danger">*</span></label>
                                        <input className="form-control"
                                            disabled={true}
                                            type="text"
                                            name="inspectionEndTime"
                                            value={values.inspectionEndTime}
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                            </div>

                            <button className="d-none" type="submit" ref={submitbtn}>submit</button>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <Modal
            show={visible}
            onHide={onClose}
            size="xl"
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>Scheduler - Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showTable()}
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleModalJob;
