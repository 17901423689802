import React from "react";
import Select from "react-select";

const InputGroupDropdown = ({ inputName, inputLabelName, dropdownOptions, setFieldValue, required = false, touched, errors, values, value }) => {

    // console.log(value, values[inputName], inputName)
    if(inputName === "appointmentModalInspectorUserID"){
        console.log(dropdownOptions, 'drop')
    }
    return (
        <div className="">
            <div className="">
                <div className="form-group">
                    <label className="form-label" htmlFor={inputName} >
                        {inputLabelName}
                        {required &&
                            <span className="text-danger">*</span>
                        }
                    </label>

                    <select className="form-control"
                        id={inputName}
                        name={inputName}
                        value={values[inputName] && values[inputName].length === 0 ? value.value : values[inputName]}
                        onChange={(e) => setFieldValue(inputName, e.target.value)}
                    >
                        {values[inputName] && values[inputName].length !== 0 && <option value={values[inputName]}>{values[inputName]}</option>}
                        {
                            dropdownOptions && dropdownOptions.map((option, index)=>{
                                if(option.value !== values[inputName]){
                                    return <option key={index} value={option.value}>{option.label}</option>
                                }
                            })
                        }
                      
                    </select>
                </div>
            </div>
            {
                touched[{ inputName }] && errors[{ inputName }] && (
                    <div className="invalid-react-select-dropdown text-danger">
                        {errors[{ inputName }]}
                    </div>
                )
            }
        </div>
    );
};

export default InputGroupDropdown;
