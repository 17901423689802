import React, { useContext } from "react";

//Import components
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Photo from "./Photo";

const AdditionalIssuePhotos = (props) => {

	const getAdditionalIssuePhotoRow = (photoObjArray, index) => {
		if(index>=photoObjArray.length) {
			return <td></td>
		} else {
			let photoObj = photoObjArray[index]
			//console.log(photoObj)
			return(			
				<td style={{ textAlign: 'center' }}>
					<span className="smallText"><strong>Item: </strong> {photoObj.itemName}</span>
					<br/>
					<span className="smallText"><strong>Issue: </strong> {photoObj.issueName}</span><br/>
					<Photo
						inspectorUserID={props.inspectionJobRecord.inspectorUserID}
						jobID={props.inspectionJobRecord.jobID}
						fileName={photoObj.additionalIssuePhoto}
						classString={"cellImageItem landscapePhoto"} 
						signedURLArray={props.signedURLArray}
					/>   
				</td>
			)
		}
	}

	const getAdditionalIssuePhotoRows = (photoObjArray) => {
		let rowsJSX = [];
		for(var i = 0; i < 4; i++){	
			rowsJSX.push(
				<tr>
					{getAdditionalIssuePhotoRow(photoObjArray, i*4+0)}
					{getAdditionalIssuePhotoRow(photoObjArray, i*4+1)}
					{getAdditionalIssuePhotoRow(photoObjArray, i*4+2)}
					{getAdditionalIssuePhotoRow(photoObjArray, i*4+3)}
				</tr>
			)				
		}
		return rowsJSX;
	}

	const getAdditionalIssuePhotoTable = () => {
		let componentJSX = [];
		let photoObjArray = props.additionalIssuePhotosArray;
		if (photoObjArray !== undefined && photoObjArray !== null && photoObjArray !== '') {
			componentJSX.push(
				<div className="itemImages">
					<table className="reportAdditionalIssuePhotoTable">
						<tbody>
							<tr>
								<th colSpan="4">Additional issue photos</th>
							</tr>
							{getAdditionalIssuePhotoRows(photoObjArray)}
						</tbody>
					</table>
				</div>
			)
		}
		return componentJSX;
	}

	return (
		<>
			<div className="a4Page standardPage">
				<Header organisationConfigRecord={props.organisationConfigRecord} inspectionJobRecord={props.inspectionJobRecord} />
				<NavBar activeSection={"Inspection details"} sectionArray={["Summary", "Inspection issues", "Inspection details", "Appendix"]} />
				<div className="p-4">
					<div className="sectionHeader">
						<span>Additional issue photos</span>
					</div>
					<span className="">
						The following photos were taken to provide further detail on issues.
					</span>

					{getAdditionalIssuePhotoTable()}

				</div>

				<Footer organisationConfigRecord={props.organisationConfigRecord} inspectionJobRecord={props.inspectionJobRecord} />
			</div>
		</>
	);
};

export default AdditionalIssuePhotos;