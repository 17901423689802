import React from 'react'

const SideBarDividerLine = () => {
  return (
    <hr className="sidebar-divider"/>
  )
}

export default SideBarDividerLine

