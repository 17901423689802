import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import { Formik, Form } from "formik";
import { FormFeedback } from "reactstrap";

//Import stylesheets
import locale from "date-fns/locale/en-GB";
import "./../../assets/stylesheets/steppedForm.css";
import "react-datepicker/dist/react-datepicker.css";

//Import helpers
import { postRequest, postRequestReturnAll } from "../../helpers/apiHelper";
import { momentFormate } from "../../helpers/generalHelper";

// Import Serivces 
import { jobReferralType } from "../../utils/service";

// context function
import { inspection } from "../../contexts/InspectionTypeContext";

//Import components
import {
  InputGroupAddress,
  InputGroupDropdown,
  InputGroupFinancial,
  InputGroupGMT,
  InputGroupMultiLineText,
  InputGroupRadio,
  InputGroupText,
  InputGroupTextWithHint,
  InspectGroupDropdown,
  InspectionTimeGroup,
  SearchGroup,
  SelectTimeButton,
  DefaultBlankDropdown
} from "../../components/portal/jobForm/index";
import { LoadingModal } from "../../components/portal/index";
import { ConfirmationModal } from "../../components/portal/index";
import { IncompleteJobFormModal } from "../../components/portal/index";


//**Utils service Comman function */
import {
  agencyTypeOptions,
  jobStatusOptions,
  propertyTypeOptions,
  times,
} from "../../utils/service";

import {
  SelectAgentModal,
  SelectClientModal,
  SelectAgencyMode,
} from "../../components/portal/jobForm/modal";
import moment from "moment";
import { date } from "yup";


const JobForm = ({ action, jobRecord, loadingModalVisible, setLoadingModalVisibility }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [statePayload, setStatePayload] = useState({
		searchInput: "",
		searchAgency: "",
		searchAgent: "",
	});

	const [inspectorObjArray, setInspectorObjArray] = useState([]);


	const [agencyTypeOption, setAgencyTypeOption] = useState(agencyTypeOptions);

	// const [loadingModalVisible, setLoadingModalVisibility] = useState(false);
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [incompleteJobFormModalVisible, setIncompleteJobFormModalVisible] = useState(false);


	const [clientModalVisible, toggleClientModalVisibility] = useState(false);
	const [agentModalVisible, toggleAgentModalVisibility] = useState(false);
	const [agencyModalVisible, toggleAgencyModalVisibility] = useState(false);
	const [clientSearchData, setClientSearchData] = useState([]);
	const [agentSearchData, setAgentSearchData] = useState([]);
	const [agencySearchData, setAgencySearchData] = useState([]);
	const [propertyTypeOption, setPropertyTypeOption] = useState({ value: "", label: "" });


	const {
		setInspectionClass,
		inspectionType,
		setInspectionType,
		setSelectClass,
		inspectionClass,
	} = inspection();

	const [jobStatus, setJobStatus] = useState(null);
	const [inspectorUserID, setInspectorUserID] = useState({ value: "", label: "Select" });

	const [submitting, setSubmitting] = useState(false);

	const [inspectorUserIDOptions, setInspectorUserIDOptions] = useState([
		{ value: "", label: "Select" },
	]);
  
	const [inspectionStartTime, setStartTime] = useState("00:00");
	const [inspectionEndTime, setEndTime] = useState("00:00");

	//console.log('localstorage', JSON.parse(localStorage.getItem('userInfo')).displayName)
	const [startDate, setStartDate] = useState(new Date());
	const [form, setForm] = useState({
		clientFullName: "",
		corporateOrganisation: "",
		clientPhoneNumber: "",
		clientEmailAddress: "",
		clientPostalAddress: "",
		client2FullName: "",
		client2PhoneNumber: "",
		client2EmailAddress: "",
		agencyName: "",
		agencyType: "",
		agencyEmailAddress: "",
		agencyPhoneNumber: "",
		agencyPostalAddress: "",
		agentPhoneNumber: "",
		agentEmailAddress: "",
		agentFullName: "",
		propertyAddress: "",
		propertyPostCode: "",
		propertySuburb: "",
		propertyState: "",
		propertyType: "",
		propertyLevels: "",
		propertyBedrooms: "",
		propertyBathrooms: "",
		propertyBookingDescription: "",
		propertyAccessNotes: "",
		jobID: "",
		jobStatus: "Confirmed",
		jobReference: "",
		testJobFlag: false,
		pestFlag: false,
		clientIsOwnerFlag: false,
		inspectionClass: "",
		inspectionType: "",
    	jobReferralType: "",
		jobReferral: "",
		clientWorkOrderNumber: "",
		originalInspectionJobID: "",
		inspectorUserID: "",
		officeUserID: "",
		inspectionDate: moment().format("DD-MM-YYYY"),
		inspectionStartTime: "",
		inspectionEndTime: "",
		inspectionPriceTotal: 0,
		inspectionPriceInspectorFee: 0,
		inspectionPriceTravelFee: 0,
		inspectionPriceFranchiseFee: 0,
		inspectionPricePestFee: 0,
		gmtValue: "8"
	});

	const toggleClientModal = () =>
		toggleClientModalVisibility(!clientModalVisible);
	const toggleAgentModal = () => toggleAgentModalVisibility(!agentModalVisible);
	const toggleAgencyModal = () =>
		toggleAgencyModalVisibility(!agencyModalVisible);

	//** InputGroupDropdown used */
	const agencyTypeHandler = (value) => {
		// setAgencyTypeOption(value)
	};

  const handleClientSearchClick = async (searchText) => {
    setLoadingModalVisibility(true);

    const payload = {
      searchInput: statePayload.searchInput,
    };

    const response = await postRequest("/getClientsFromSearch", payload);
    setClientSearchData(response || []);

    //Hide loading modal
    setLoadingModalVisibility(false);
    //Show client modal
    toggleClientModal();
  };

  const handleAgentSearchClick = async (searchText) => {
    setLoadingModalVisibility(true);

    const payload = {
      searchInput: statePayload.searchAgent,
    };

    const response = await postRequest("/getAgentsFromSearch", payload);
    setAgentSearchData(response || []);
    setLoadingModalVisibility(false);

    toggleAgentModal();
  };

  const handleAgencySearchClick = async (searchText) => {
    setLoadingModalVisibility(true);

    const payload = {
      searchInput: statePayload.searchAgency,
    };

    const response = await postRequest("/getAgenciesFromSearch", payload);
    setAgencySearchData(response || []);

    setLoadingModalVisibility(false);
    toggleAgencyModal();
  };

  const onSelectClickClient = (item, setFieldValue) => {
    for (const key in item) {
      setFieldValue(key, item[key]);
    }
    toggleClientModal();
  };

  const onSelectClickAgency = (item, setFieldValue) => {
    for (const key in item) {
      setFieldValue(key, item[key]);
    }
    toggleAgencyModal();
  };

  const onSelectClickAgent = (item, setFieldValue) => {
    for (const key in item) {
      setFieldValue(key, item[key]);
    }
    toggleAgentModal();
  };

  const propertyTypeHandler = (value) => {
    setPropertyTypeOption(value);
  };

  const handleRadioCheck = (inputName, value) => {
    let temp = form;
    temp[{ inputName }] = value;
    setForm(temp);
  };

  /*
    Post requests
    All actions - Load agency types
    All actions - Load statuses
    All actions - Inspector userIDs
    Create job - Generate jobID
    Update job - Get job object
    */

  // update inspectiondate when on editJob and copy job page 
  useEffect(() => {
    if(jobRecord[0]){
      console.log(jobRecord[0].inspectionDate)
      try {
        if(jobRecord[0].inspectionDate.indexOf('-') > -1) {
          let dateValue = new Date(jobRecord[0].inspectionDate.split('-').reverse().join('-'));
          setStartDate(dateValue)
        }
      } catch(e) {
        console.log(e)
      }
    }
  }, [jobRecord])

  // Similar to componentDidMount and componentDidUpdate:

  useEffect(() => {
    let currentUid = localStorage.getItem("uid");
    let currentDisplayName = localStorage.getItem("displayName");

    const getInspectorUserObjArray = async () => {
      const inspectorObjArray = await postRequest("/getInspectorUserObjArray");
      // //console.log(inspectorObjArray)
      setInspectorObjArray(inspectorObjArray);
      //Populate inspector userID options
      let tempInspectorObjArray = [];
      inspectorObjArray.forEach((inspectorObj) => {
        tempInspectorObjArray.push({
          value: inspectorObj.uid,
          label: inspectorObj.displayName,
        });
      });
      // //console.log(tempInspectorObjArray, 'tempInspectorObjArray')
      setInspectorUserIDOptions(tempInspectorObjArray);
    };
    getInspectorUserObjArray();
    //2. Handle action (either Create, Copy or Update)
    if (action === "Create" || action === "Copy") {
      let temp = form;
      temp.jobID = "To be generated";
      temp.officeUserID = currentUid;

      setForm(temp);
    }
  }, []);

  const types = [];
  if (inspectionType) {
    types.push(...inspectionType.map((t) => {
      return t.value;
    }));
  }

  const submitForm = async (values, resetForm) => {
    console.log(values, 'values')
    if (inspectionType && values['inspectionType'] === "") {
      values = { ...values, inspectionType: inspectionType[0].value }
    }

    if (location.pathname === "/copyJob" && types.length > 0) {
      values = { ...values, inspectionType: types.includes(values['inspectionType']) ? values['inspectionType'] : types[0] }
    }

    setSubmitting(true)
    setLoadingModalVisibility(true);
    // console.log('Form values: ', values)
    setForm(form)

    //Confirm required fields are populated (Inspection class, inspection type and jobStatus)
    if (
      values['inspectionClass'] == "" ||
      values['inspectionType'] == "" ||
      values['jobStatus'] == ""
    ) {
      setIncompleteJobFormModalVisible(true);

    } else {

      if (action == 'Create') {
        const response = await postRequestReturnAll("/createJob", values);

        setLoadingModalVisibility(false);

        if (response.status === 200 || response.status === 201 || response.status === 202) {
          //Job successfully created / updated
          setConfirmationModalVisible(true);
          resetForm({
            values: {
              clientFullName: "",
              corporateOrganisation: "",
              clientPhoneNumber: "",
              clientEmailAddress: "",
              clientPostalAddress: "",
              client2FullName: "",
              client2PhoneNumber: "",
              client2EmailAddress: "",
              agencyName: "",
              agencyType: "",
              agencyEmailAddress: "",
              agencyPhoneNumber: "",
              agencyPostalAddress: "",
              agentPhoneNumber: "",
              agentEmailAddress: "",
              agentFullName: "",
              propertyAddress: "",
              propertyPostCode: "",
              propertySuburb: "",
              propertyState: "",
              propertyType: "",
              propertyLevels: "",
              propertyBedrooms: "",
              propertyBathrooms: "",
              propertyBookingDescription: "",
              propertyAccessNotes: "",
              jobID: "",
              jobStatus: "Confirmed",
              jobReference: "",
              testJobFlag: false,
              pestFlag: false,
              clientIsOwnerFlag: false,
              inspectionClass: "",
              inspectionType: "",
              jobReferralType: "",
              jobReferral: "",
              clientWorkOrderNumber: "",
              originalInspectionJobID: "",
              inspectorUserID: "",
              officeUserID: "",
              inspectionDate: "",
              gmtValue: 8,
              inspectionStartTime: "",
              inspectionEndTime: "",
              inspectionPriceTotal: 0,
              inspectionPriceInspectorFee: 0,
              inspectionPriceTravelFee: 0,
              inspectionPriceFranchiseFee: 0,
              inspectionPricePestFee: 0,
            },
          });
          setTimeout(function () { setConfirmationModalVisible(false); navigate('/') }, 2000);

          //window.location='/'
        } else {
          //Show error
        }
      }

      if (action == 'Edit') {
        const response = await postRequestReturnAll("/editJob", values);
        // console.log(response, 'response')
        setLoadingModalVisibility(false);
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          //Job successfully created / updated
          setConfirmationModalVisible(true);
          setTimeout(function () { setConfirmationModalVisible(false); navigate('/') }, 2000);

          //window.location='/'
        } else {
          //Show error
        }
      }

    }
  };

  return (
    <>
      <LoadingModal visible={loadingModalVisible} />
      <ConfirmationModal visible={confirmationModalVisible} setConfirmationModalVisible={setConfirmationModalVisible} />
      <IncompleteJobFormModal visible={incompleteJobFormModalVisible} onClose={() => { setIncompleteJobFormModalVisible(false); setLoadingModalVisibility(false) }} />


      <div className="container ms-form-container mb-4">
        {/* <SelectClientModal tableData={clientSearchData} visible={clientModalVisible} onClose={toggleClientModal} selectClick={onSelectClickClient} />
                <SelectAgentModal tableData={agentSearchData} visible={agentModalVisible} onClose={toggleAgentModal} selectClick={onSelectClickAgent} />
                <SelectAgencyModal tableData={agencySearchData} visible={agencyModalVisible} onClose={toggleAgencyModal} selectClick={onSelectClickAgency} /> */}

        <Formik
          initialValues={
            jobRecord.length !== 0
              ? {
                  ...jobRecord[0],
                  jobStatus: action === 'Edit' ? jobRecord[0].jobStatus : "Confirmed",
                  officeUserID: localStorage.getItem("uid")
                }
              : form
          }
          enableReinitialize={true}
          //Need to leave this as false to avoid client,agent or agency responses overriding values
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);

          }}
        >
          {({
            touched,
            errors,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e.target);
                return false;
              }}
            >
              <div>
                <div onClick={() => toggleClientModal()}>
                  <SelectClientModal
                    tableData={clientSearchData}
                    visible={clientModalVisible}
                    onClose={toggleClientModal}
                    selectClick={onSelectClickClient}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div onClick={() => toggleAgentModal()}>
                  <SelectAgentModal
                    tableData={agentSearchData}
                    visible={agentModalVisible}
                    onClose={toggleAgentModal}
                    selectClick={onSelectClickAgent}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div onClick={() => toggleAgencyModal()}>

                  <SelectAgencyMode
                    tableData={agencySearchData}
                    visible={agencyModalVisible}
                    onClose={toggleAgencyModal}
                    selectClick={onSelectClickAgency}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>

              <fieldset
                id="steps-uid-0-p-0"
                role="tabpanel"
                aria-labelledby="steps-uid-0-h-0"
                className="body current"
                aria-hidden="false"
              >
                <div className="row">
                  <div className="col">
                    <p className="formSectionHeader m-0">Client information</p>
                  </div>
                  <div className="col">
                    <div className="fieldset-content client-information-content">
                      <SearchGroup
                        inputName="CreateJobsearchClient"
                        inputLabelName="Search for existing client"
                        onChange={(e) => {
                          // //console.log("e", e);
                          setStatePayload((prev) => ({
                            ...prev,
                            searchInput: e.target.value,
                          }));
                        }}
                        handleSearchClick={handleClientSearchClick}
                      // togle={() => toggleAgentModal}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"clientFullName"}
                      inputLabelName={"Client full name"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"clientPhoneNumber"}
                      inputLabelName={"Client phone number"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"clientEmailAddress"}
                      inputLabelName={"Client email address"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupTextWithHint
                      inputName={"corporateOrganisation"}
                      inputLabelName={"Corporate organisation name (optional)"}
                      hintTitle={"Corporate organisation"}
                      hintMessage={
                        "OPTIONAL: Enter the name of the corporate organisation"
                      }
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupAddress
                      inputName={"clientPostalAddress"}
                      inputLabelName={"Client postal address"}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      form={form}
                      setForm={setForm}
                      values={values['clientPostalAddress'] ? values : { ...values, ['clientPostalAddress']: "" }}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"client2FullName"}
                      inputLabelName={"Client 2 full name"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"client2PhoneNumber"}
                      inputLabelName={"Client 2 phone number"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"client2EmailAddress"}
                      inputLabelName={"Client 2 email address"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                {/* {This is Agency information} */}

                <hr className="rounded" />
                <div className="row">
                  <div className="col">
                    <p className="formSectionHeader m-0">Agency information</p>
                  </div>
                  <div className="col">
                    <SearchGroup
                      inputName="searchAgency"
                      inputLabelName="Search agency"
                      onChange={(e) => {
                        // //console.log("e", e);
                        setStatePayload((prev) => ({
                          ...prev,
                          searchAgency: e.target.value,
                        }));
                      }}
                      handleSearchClick={handleAgencySearchClick}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"agencyName"}
                      inputLabelName={"Agency name"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"agencyEmailAddress"}
                      inputLabelName={"Agency email address"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"agencyPhoneNumber"}
                      inputLabelName={"Agency phone number"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupDropdown
                      inputName={"agencyType"}
                      inputLabelName={"Agency type"}
                      dropdownOptions={agencyTypeOption}
                      // dropdownHandler={agencyTypeHandler}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values['agencyType'] ? values : { ...values, ['agencyType']: "" }}
                      // value={!submitting ? agencyTypeOption[0] : {value:"", label: ""}}
                      value={{ value: "", label: "" }}
                    />
                  </div>
                  <div className="col">
                    <InputGroupAddress
                      inputName={"agencyPostalAddress"}
                      inputLabelName={"Agency postal address"}
                      touched={touched}
                      errors={errors}
                      values={values['agencyPostalAddress'] ? values : { ...values, ['agencyPostalAddress']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      form={form}
                      setForm={setForm}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>

                {/* {This is Agent information} */}

                <hr className="rounded" />
                <div className="row">
                  <div className="col">
                    <p className="formSectionHeader m-0">Agent information</p>
                  </div>
                  <div className="col">
                    {/* <SearchGroup
                      inputName={"searchAgent"}
                      inputLabelName={"Search agent"}
                      handleSearchClick={handleAgentSearchClick}
                    /> */}

                    <SearchGroup
                      inputName="searchAgent"
                      inputLabelName="Search agent"
                      onChange={(e) => {
                        // //console.log("e", e);
                        setStatePayload((prev) => ({
                          ...prev,
                          searchAgent: e.target.value,
                        }));
                      }}
                      handleSearchClick={handleAgentSearchClick}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"agentFullName"}
                      inputLabelName={"Agent full name"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"agentEmailAddress"}
                      inputLabelName={"Agent email address"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"agentPhoneNumber"}
                      inputLabelName={"Agent phone number"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                {/* {This is Property information} */}

                <hr className="rounded" />
                <div className="row">
                  <div className="col">
                    <p className="formSectionHeader m-0">
                      Property information
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupAddress
                      inputName={"propertyAddress"}
                      inputLabelName={"Property address"}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      form={form}
                      setForm={setForm}
                      values={values['propertyAddress'] ? values : { ...values, ['propertyAddress']: "" }}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="col">
                    <InputGroupDropdown
                      inputName={"propertyType"}
                      inputLabelName={"Property type"}
                      dropdownOptions={propertyTypeOptions}
                      dropdownHandler={propertyTypeHandler}
                      setFieldValue={setFieldValue}
                      value={propertyTypeOption}
                      touched={touched}
                      errors={errors}
                      values={values["propertyType"] ? values : { ...values, ["propertyType"]: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    // value={!submitting ? propertyTypeOptions[1] : {value: "", label: ""}}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"propertySuburb"}
                      inputLabelName={"Property suburb"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"propertyPostCode"}
                      inputLabelName={"Property post code"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"propertyState"}
                      inputLabelName={"Property state"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupTextWithHint
                      inputName={"propertyLevels"}
                      inputLabelName={"Property levels"}
                      hintTitle={"Property levels"}
                      hintMessage={
                        "Provide the number of levels the property has, e.g. single, double, triple etc."
                      }
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"propertyBedrooms"}
                      inputLabelName={"No. of Bedrooms"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"propertyBathrooms"}
                      inputLabelName={"No. of Bathrooms"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupMultiLineText
                      inputName={"propertyBookingDescription"}
                      inputLabelName={"Property booking description"}
                      rows={4}
                      placeholder={
                        "Property booking description - Additional notes for the inspector for context of the inspection"
                      }
                      touched={touched}
                      errors={errors}
                      values={values['propertyBookingDescription'] ? values : { ...values, ['propertyBookingDescription']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupMultiLineText
                      inputName={"propertyAccessNotes"}
                      inputLabelName={"Property access notes"}
                      rows={4}
                      placeholder={
                        "Property access notes - Who will provide access to the property and their contact details"
                      }
                      touched={touched}
                      errors={errors}
                      values={values['propertyAccessNotes'] ? values : { ...values, ['propertyAccessNotes']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                {/* {This is Inspection information} */}

                <hr className="rounded" />
                <div className="row">
                  <div className="col">
                    <p className="formSectionHeader m-0">
                      Inspection information
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupText
                      inputName={"jobID"}
                      inputLabelName={"Job ID (system generated)"}
                      disabled={true}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="col">
                    <InputGroupDropdown
                      inputName={"jobStatus"}
                      inputLabelName={"Job status"}
                      dropdownOptions={jobStatusOptions}
                      dropdownHandler={setJobStatus}
                      setFieldValue={setFieldValue}
                      required={true}
                      value={{ value: "", label: "" }}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <DefaultBlankDropdown
                      inputName={"inspectorUserID"}
                      inputLabelName={"Inspector user ID"}
                      dropdownOptions={inspectorUserIDOptions}
                      dropdownHandler={setInspectorUserID}
                      setFieldValue={setFieldValue}
                      value={inspectorUserID}
                      // value={{value : "", label:""}}
                      required={true}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupText
                      inputName={"officeUserID"}
                      inputLabelName={"Office user ID"}
                      disabled={true}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupRadio
                      inputName={"testJobFlag"}
                      inputLabelName={"Is this a test job?"}
                      handleRadioCheck={handleRadioCheck}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      values={values['testJobFlag'] ? values : { ...values, ['testJobFlag']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupRadio
                      inputName={"pestFlag"}
                      inputLabelName={"Pest inspection required?"}
                      handleRadioCheck={handleRadioCheck}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      values={values['pestFlag'] ? values : { ...values, ['pestFlag']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupRadio
                      inputName={"clientIsOwnerFlag"}
                      inputLabelName={"Is the client the property owner?"}
                      handleRadioCheck={handleRadioCheck}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      values={values['clientIsOwnerFlag'] ? values : { ...values, ['clientIsOwnerFlag']: "" }}

                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupTextWithHint
                      inputName={"originalInspectionJobID"}
                      inputLabelName={"Original inspection Job ID"}
                      hintTitle={"Original Job ID"}
                      hintMessage={
                        "For any reinspections, please provide the original JobID"
                      }
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <InspectGroupDropdown
                        disable={
                          location.pathname === "/editJob" ? true : false
                        }
                        inputName={"inspectionClass"}
                        inputLabelName={"Inspection class"}
                        dropdownOptions={
                          inspectionClass.length !== 0 && inspectionClass
                        }
                        dropdownHandler={setSelectClass}
                        value={{ value: "", label: "" }}
                        setFieldValue={setFieldValue}
                        required={true}
                        touched={touched}
                        errors={errors}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <InspectGroupDropdown
                        disable={
                          inspectionType === null
                            ? location.pathname === "/copyJob"
                              ? false
                              : true
                            : false
                        }
                        inputName={"inspectionType"}
                        inputLabelName={"Inspection type"}
                        dropdownOptions={inspectionType ? inspectionType : [{ value: values['inspectionType'], label: values['inspectionType'] }]}
                        dropdownHandler={setInspectionType}
                        setFieldValue={setFieldValue}
                        // value={inspectionType}
                        // value={inspectionType ? inspectionType[0] : {value: "", label: ""}}
                        required={true}
                        touched={touched}
                        errors={errors}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex align-items-center">
                    <InputGroupText
                      width="w-50"
                      inputName={"jobReferral"}
                      inputLabelName={"Job referral"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />

                    <div className="col">
                      <div className="form-group">
                        
                        <InspectGroupDropdown
                          disable={false}
                          inputName={"jobReferralType"}
                          inputLabelName={"Job referral type"}
                          dropdownOptions={jobReferralType}
                          value={{ value: "", label: "" }}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          values={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <InputGroupText
                      inputName={"clientWorkOrderNumber"}
                      inputLabelName={"Client work order number"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  {/*
                  <div className="col">
                    <InputGroupText
                      inputName={"jobReference"}
                      inputLabelName={"Job reference"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                      */}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label" htmlFor="inspectionDate">
                        Inspection Date
                      </label>

                      <DatePicker
                        name="inspectionDate"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        locale={locale}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setFieldValue("inspectionDate", momentFormate(date));
                        }}
                      />

                      {touched.inspectionDate && errors.inspectionDate && (
                        <FormFeedback>{errors.inspectionDate}</FormFeedback>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <InputGroupGMT
                      inputName={"gmtValue"}
                      inputLabelName={"Timezone (GMT +/-)"}
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <InspectionTimeGroup
                      inputName={"inspectionStartTime"}
                      inputLabelName={"Start time"}
                      touched={touched}
                      errors={errors}
                      form={form}
                      setForm={setForm}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />

                    <div className="timeButtonGroup" id="startTimeButtonGroup">
                      {times.map((time, index) => {
                        return (
                          <SelectTimeButton
                            key={index}
                            inputName={"inspectionStartTime"}
                            timeValue={`${time}`}
                            selectTime={setStartTime}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="col">
                    <InspectionTimeGroup
                      inputName={"inspectionEndTime"}
                      inputLabelName={"End time"}
                      touched={touched}
                      errors={errors}
                      form={form}
                      setForm={setForm}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />

                    <div className="timeButtonGroup" id="startTimeButtonGroup">
                      {times.map((time, index) => {
                        return (
                          <SelectTimeButton
                            key={index}
                            inputName={"inspectionEndTime"}
                            timeValue={`${time}`}
                            selectTime={setEndTime}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <InputGroupFinancial
                      inputName={"inspectionPriceTotal"}
                      inputLabelName={"Total fee (inc GST)"}
                      touched={touched}
                      errors={errors}
                      values={values['inspectionPriceTotal'] ? values : { ...values, ['inspectionPriceTotal']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupFinancial
                      inputName={"inspectionPriceInspectorFee"}
                      inputLabelName={"Inspector fee"}
                      touched={touched}
                      errors={errors}
                      values={values['inspectionPriceInspectorFee'] ? values : { ...values, ['inspectionPriceInspectorFee']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupFinancial
                      inputName={"inspectionPriceTravelFee"}
                      inputLabelName={"Travel fee"}
                      touched={touched}
                      errors={errors}
                      values={values['inspectionPriceTravelFee'] ? values : { ...values, ['inspectionPriceTravelFee']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupFinancial
                      inputName={"inspectionPriceFranchiseFee"}
                      inputLabelName={"Franchise fee"}
                      touched={touched}
                      errors={errors}
                      values={values['inspectionPriceFranchiseFee'] ? values : { ...values, ['inspectionPriceFranchiseFee']: "" }}

                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <InputGroupFinancial
                      inputName={"inspectionPricePestFee"}
                      inputLabelName={"Pest fee"}
                      touched={touched}
                      errors={errors}
                      values={values['inspectionPricePestFee'] ? values : { ...values, ['inspectionPricePestFee']: "" }}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  className={"formSubmitButton float-right"}
                  type="submit"
                >
                  Submit
                </button>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default JobForm;
